import { error } from 'jquery';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PopUpWarning from './PopUpWarning';
import axios from 'axios';
import PopUpNextActivity from './PopUpNextActivity';
import { t } from 'react-i18next';
//icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { saveScoreAPI } from './scoreStoreApi';


const useStyles = makeStyles({

  quizContainer: {
    width: "100%",
    margin: 'auto',
  },
});

const ScrambleGameActivities = (ScrambleGameData) => {
    const [gameData,setGameData]=  useState([])
    const { t, i18n } = useTranslation();
  const classes  = useStyles();
  const history = useHistory();
  const [currentQustionAnswer, setCurrentQustionAnswer] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentPopShow, setCurrentPopShow] = useState(null);
  const [currentInput, setCurrentInput] = useState("");

  const [allAnswers, setAllAnswers] = useState(Array(questions.length).fill(''))
  

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [title,setTitle] = useState("")

  const [scoreLoading, setScoreLoading] = useState(false);
  const [score, setScore] = useState({score: 0, maxScore: 0})
  const [lesson, setLesson] = useState(null)
  const [dataLoading,setDataLoading] = useState(false)
  const [check, setCheck] = useState(null);
  const [flag,setflag] = useState(null)

    //console.log("ScrambleGameData>>",ScrambleGameData);
    //console.log("allanswer<<",allAnswers);
    //console.log("currentinput>>",currentInput);
    //console.log("check",check);
    
    
    useEffect(()=>{
       
        if(ScrambleGameData && ScrambleGameData.ScrambleGameData.Games){
            //console.log("ScrambleGameData.Games>>",ScrambleGameData.ScrambleGameData)
            try{
                    const JsonStringyfyData=ScrambleGameData.ScrambleGameData.Games
                    const parsedData= JSON.parse(JsonStringyfyData)
                    //console.log("parsedData>>",parsedData.activites)
                    setGameData(parsedData.activites || parsedData.activities)
            }
            catch{
                //console.log("error>",error);
                

            }
        }

},[ScrambleGameData])
    //console.log("gamedata>>",gameData);

    const handleInputChange =((event)=>{
          setCurrentInput(event.target.value);
        //console.log("handleInputChange>>",event.target.value);
    })

    //popup functions:
    const handleSubmitAndGetScore = async () => {
      // setCurrentQustionAnswer("")
      setScoreLoading(true);
      setCurrentPopShow("scoreForm");

      const score = scoreCalculation();
      //console.log("Score: ", score);

      setScore(score)
      //console.log("score: ", score);

      await saveScoreAPI(Number(ScrambleGameData.lessonId), score.score, gameData.length, "Games");
      
      setScoreLoading(false);
      // history.push(`/course-creator/score/${lessonId}/Case Studies`, { score });
    }

    const showExitPopup = () => {
      setCurrentPopShow("exitPopUp")
    }
  
    const showSubmitPopUp = () => {
      setCurrentPopShow("submitPopUp")
    }

    const scoreCalculation = () => {
      let score = 0;
      for(let index=0; index < allAnswers.length; index++){
        gameData[index].answer[0].toUpperCase() === allAnswers[index].toUpperCase() && score++;
      }
      return {score: score, totalScore: allAnswers.length}
    }

    useEffect(() => {
      // setQuestions(quizData);
      setAllAnswers(Array(questions.length).fill(''))
    }, [gameData])

    const saveAnswers = () => {
      //console.log("submit All Answers");
      if(currentInput){
        const updatedAnswers = [...allAnswers]
        updatedAnswers[currentQuestionIndex] = currentInput;
        setAllAnswers(() => updatedAnswers);
      }
    }
   
    useEffect(() => {
      setCurrentInput(""); // Reset input when the question index changes
    }, [currentQuestionIndex]);

    useEffect(() => {
      if (allAnswers?.length) {
        setScore((prev) => ({ ...prev, maxScore: allAnswers.length }));
      }
    }, [allAnswers]);


      const formatHint = (word) => {
     if (word.length <= 2) return word; // If the word is too short, return it as-is
     return word[0] + '_'.repeat(word.length - 2) + word[word.length - 1];
   };


   const handleCheckAnswer = (currentQuestionIndex) => {
    setflag(true)
    //console.log("Current index>>", currentQuestionIndex);
  
    const correctAnswer = gameData[currentQuestionIndex]?.answer[0]?.toUpperCase(); // Get the correct answer
    const userInput = currentInput.toUpperCase(); // Convert user input to uppercase
  
    if (correctAnswer === userInput) {
      setCheck(true); // If the answer matches, set check to true
    } else {
      setCheck(false); // If the answer doesn't match, set check to false
    }
  };
  
    return (
      <div className='case_main'>
        {(dataLoading ) ?<div className="spinner_outer"><div className="spinner"></div></div>  :(
        <div className={classes.quizContainer}>
  
        <div className='page_outer '>
         <div className='inner_box'>
          <div className='top_heading'>
          {/* <h2><span>  {title}</span></h2> */}
        </div>
        <div className='box_outer_for_desg'>
        {/* {lesson && <div className='para_case'><p>{lesson}</p></div> } */}
          <div className='page_inner  spl_page_in'>
          <div className='question_list scramble_list'  item xs={12}>
            <h4>Scramble Word Activity</h4>
            <h3>
              Activity {currentQuestionIndex + 1}
            </h3>
            <h6>
              {/* {questions[currentQuestionIndex]?.question} */}
            </h6>
          </div>
          <div className='question_option'>
          <div> 
            <p> Hint: {gameData[currentQuestionIndex]?.answer[0] && formatHint(gameData[currentQuestionIndex]?.answer[0])} </p>
            </div>
            {
             <div className='material_scramble'>
              
             { gameData.length > 0 && currentQuestionIndex < gameData.length ?(
             
             gameData[currentQuestionIndex]?.ScrambleWord?.map( (option, index) => (
                <span className='question_mark' key={index}>
                  
                  <span>{option}</span>
                
                </span>
              ))
            ) :(<p>No Data Available</p>)
            }
            <div className='hint_input'>
            {/* <p>hint : t---q</p> */}
            <input
              type="text"
              value={currentInput}
              onChange={handleInputChange}
            />
             {
              flag && 
              (check ? 
                  <CheckCircleIcon className='checkIcon' /> 
                  :
                   <CancelIcon className='checkCross'  />)
                }
            <div className='uniq_sub_check_btn'><button onClick={()=> {handleCheckAnswer(currentQuestionIndex)}}>
              {"Check"}
              </button></div>
            </div>
            </div>
            
            }
          </div>
          </div>
          </div>
          <Grid className='question_btn_list' item xs={12} container justifyContent="center">
            <Button className='custom_btn'
              color="primary"
              variant="contained"
              style={{ marginLeft: 5 }}
              onClick={() => {
                currentQuestionIndex > 0 
                  ? setCurrentQuestionIndex(currentQuestionIndex - 1)
                  : showExitPopup();
              }}
            >
              {t("Back")}
            </Button>
  

            <Button className='custom_btn'
              color="primary"
              variant="contained"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setflag(false)
                setCheck(false);
                saveAnswers();
                currentQuestionIndex < (gameData.length-1) 
                  ? setCurrentQuestionIndex(currentQuestionIndex + 1)
                  : showSubmitPopUp();
            }}
            > {t(
              currentQuestionIndex < (gameData.length - 1) 
                ? "Next" 
                : "Submit"
            )}
            </Button>

          </Grid>
         
          <div className='after_save_outer'> 
        {
          currentPopShow === "submitPopUp" &&
          <PopUpWarning
           heading={"Submit"}
            subHeading={"After that you can't change your answer"}
             leftButton={"Cancel"} rightButton={"Submit"}
              handleLeftButton={() => {setCurrentPopShow(null)}}
               handleRightButton={async() => {await handleSubmitAndGetScore()}}/>
        }
        {
          currentPopShow === "exitPopUp" &&
          <PopUpWarning
           heading={"Exit"}
            subHeading={"After that you exit form activity"}
             leftButton={"Cancel"} rightButton={"Exit"}
              handleLeftButton={() => {setCurrentPopShow(null)}}
               handleRightButton={() => {handleSubmitAndGetScore()}}/>
        }
                    {
                currentPopShow === "scoreForm" &&
                <PopUpNextActivity 
                  heading={"Score"} 
                  subHeading={`Your Score : ${score.score} out of ${gameData.length}`} 
                  leftButton={`${t("Save & Exit")}`} 
                  rightButton={`${t("Save & Continue")}`} 
                  loading = {scoreLoading}
                />
              }
      </div>
      </div>
      </div>
      </div> 
        )}
      </div> 
    );  

  }

export default ScrambleGameActivities
