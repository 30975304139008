import React from 'react';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const ActivityListPopUp = ({setLessonPlanActivity, popupType, activities, lessonId, setIsActivityPopup, id, weekId, topicId }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const handleClick = (activity) => {
    history.push(`/course-creator/activity/${lessonId}/${activity?.activity_name.replace('/', '-')}?id=${id}&weekId=${weekId}&topicId=${topicId}`);
  };

  return (
    <div className="popup">
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <div></div>
        <h2 style={{color: `${popupType==="leassonPlan"? "#000": "fff"}`}}> {t("activities")} </h2>
        <div
          style={{cursor: 'pointer'}}
          onClick={() => {
            document.body.style.overflow = "auto";
            setIsActivityPopup && setIsActivityPopup(null);
          }}
        >
          {
            popupType==="leassonPlan" &&
            <CloseIcon/>
          }
        </div>
      </div>
      <ul>
        {activities.map((activity, index) => (
          <li
            key={index}
            onClick={() =>{
              document.body.style.overflow = "auto";
              handleClick(activity)
            }}
            style={{
              backgroundColor: activity?.status === 'complete' ? 'green' : '#689CF2',
              color: '#fff',
              cursor: 'pointer',
              padding: '10px',
              margin: '5px 0',
            }}
          >
            {activity.activity_name} {activity.status === 'complete' ? ' - Complete' : ''}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ActivityListPopUp;
