import axios from "axios";
import Const from "../../../helpers/const";


export async function saveScoreAPI (topic_id, score, total_score, activityName) {
  try {
    const response = await axios.post(Const.BASE_URL + 'addscore',
        {
          topic_id, score, total_score, activity_name: activityName
        },
        {
            headers: {
                Authorization: `${localStorage.userToken}`,
                'content-type': 'application/json'
            }
        });
  } catch (error) {
    console.error("Error: ", error.message);
  }
}


export async function updateToken (authUser, words_lenght) {
  try {
    const data = {
      user_id: authUser?.id,
      words_lenght: words_lenght
    };

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Const.BASE_URL + "check_genie_status",
      headers: {
        'Authorization': `Bearer ${localStorage.userToken}`
      },
      data: data
    };

    const response = await axios.request(config);

    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};