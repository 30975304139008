import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Box, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import axios from "axios";
import Const from "../../helpers/const";
import { useTranslation } from "react-i18next";
import Header from "../../components/header";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axiosOptions from "../../helpers/axiosOptions";
import { Fonts, BgColor, Color } from "../../theme";
import { Row, Item } from "@mui-treasury/components/flex";
import { Info } from "@mui-treasury/components/info";
import { useApexInfoStyles } from "@mui-treasury/styles/info/apex";
import ActivityListPopUp from "./ActivityListPopUp";
import PopUpWarning from "./InteractiveActivities/PopUpWarning";

const styles = (theme) => ({
  overlayWrapper: {
    position: "fixed",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});

const useStyles = makeStyles(() => ({
  container: {
    alignSelf: "flex-end",
    marginTop: -150,
    paddingTop: 70,
    paddingBottom: 20,
    width: "auto",
  },
  root: {
    maxWidth: 345,
    marginTop: 10,
    marginRight: 10,
  },
  media: {
    height: 140,
  },
  mainHeading: {
    fontSize: "20px",
    fontFamily: Fonts.Medium,
  },
}));

const cardStyles = makeStyles(() => ({
  root: {
    marginRight: 13,
    marginTop: 13,
    height: "100%",
    transition: "0.3s",
    position: "relative",
    // "&:before": {
    //   transition: "0.2s",
    //   position: "absolute",
    //   width: "100%",
    //   height: "100%",
    //   content: '""',
    //   display: "block",
    //   backgroundColor: "#d9daf1",
    //   borderRadius: "1rem",
    //   zIndex: 0,
    //   bottom: 0,
    // },
    "&:hover": {
      // "&:before": {
      //   bottom: -6,
      // },
      // "& $card": {
      //   boxShadow: "-12px 12px 64px 0 #bcc3d6",
      // },
    },
  },
  card: {
    zIndex: 1,
    position: "relative",
    borderRadius: "10px",
    // boxShadow: "0 6px 20px 0 #dbdbe8",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    backgroundColor: "#fff",
    transition: "0.3s",
    height: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#d4d4d4",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&:hover": {
      // boxShadow: "none !important",
      // border: "0 !important",
    },
  },
  popup: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "rgba(40, 40, 48, 0.51)",
    backdropFilter: "blur(2px)",
    zIndex: 1300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  different_popup: {
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
    padding: "20px",
    maxWidth: "500px",
    width: "90%",
    maxHeight: "90%",
    minHeight: "200px",
    overflow: "auto",
    textAlign: "center",
  },
  logo: {
    width: 48,
    height: 48,
    borderRadius: "0.75rem",
  },
  avatar: {
    fontSize: "0.875rem",
    backgroundColor: "#6d7efc",
  },
  join: {
    color: "#fff",
    borderRadius: "50px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: BgColor.myBlue,
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#689CF2",
    },
  },
  delete: {
    color: "#fff",
    borderRadius: "50px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: BgColor.myRed,
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    marginRight: "10px",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: BgColor.myDarkRed,
    },
  },
  danger: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: Fonts.Regular,
    borderRadius: "50px",
    backgroundColor: "#F48FB1",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#ff266f",
    },
  },
}));

const AllLeassonList = () => {
  const classes = useStyles();
  const { id, weekId, topicId } = useParams();
  const history = useHistory();

  const { t, i18n } = useTranslation();
  const Styles = cardStyles();

  const [loading, setLoading] = useState(false);
  const [allLeason, setAllLeason] = useState([]);
  const [lessonPlanActivity, setLessonPlanActivity] = useState([]);
  const [isShowActivityList, setIsShowActivityList] = useState(false);
  const [activeLessonId, setActiveLessonId] = useState(null);
  const [isActivityPopup, setIsActivityPopup] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isDeleteTopic, setIsDeleteTopic] = useState(false);
  const [topicIdToDelete, setTopicIdToDelete] = useState(null);
  const authUser = useSelector((store) => store.auth.user);

  const checkActivePlan = async () => {
    try {
      const response = await axios.post(
        Const.BASE_URL + "check_genie_status",
        { user_id: authUser?.id },
        axiosOptions(localStorage.userToken)
      );

      if (response) {
        const planData = response?.data?.data;
        const currentDate = new Date();
        const planExpiryDate = new Date(planData?.vaild_upto);

        if (
          response?.data?.status === "false" ||
          planData?.price !== "45" ||
          planData?.words_lenght === 0 ||
          currentDate > planExpiryDate
        ) {
          history.push("/subscription");
        } else {
          setLoading(false);
        }
      }

      setLoading(false);
    } catch (error) {
      let err = error.response ? error.response.data : error;
      console.error("Error in checking active plan:", err);
      history.push("/subscription");
    }
  };

  useEffect(() => {
    checkActivePlan();
  }, [authUser]);

  const getCreatedLeason = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${Const.BASE_URL}get/${id}${weekId}${topicId}/lessonplans`
      );

      if (response.status === 200) {
        setAllLeason(response.data.data);
        toast.success(response.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.error("error : ", error);

      toast.error("Internal Server Error", { autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };



  const handleDeleteTopic = (id) => {
    setTopicIdToDelete(id);
    setIsDeleteTopic(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${Const.BASE_URL}topic/${topicIdToDelete}/delete`
      );
      if (response.status === 200) {
        setAllLeason((prev) =>
          prev.filter((topic) => topic.topic.lesson_id !== topicIdToDelete)
        );
        toast.success("Topic Deleted Successfully", { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("Internal Server Error", { autoClose: 2000 });
    }
    setIsDeleteTopic(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteTopic(false);
  };

  useEffect(() => {
    getCreatedLeason();
  }, []);

  const handleActivity = async (lessonId) => {
    setIsLoading(true);
    setIsActivityPopup(lessonId);
    document.body.style.overflow = "hidden";
    setActiveLessonId((prev) => (prev === lessonId ? null : lessonId));
    setIsShowActivityList(!isShowActivityList);
    setLessonPlanActivity([]);

    try {
      const response = await axios.get(
        `${Const.BASE_URL}get/${lessonId}/lessonplan`
      );

      if (response.status === 200) {
        setLessonPlanActivity(
          response?.data?.lesson_plans[0]?.activities ?? []
        );
        toast.success(response.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.error("Error fetching lesson plan:", error);
      toast.error("Failed to fetch lesson plan", { autoClose: 2000 });
    }
    setIsLoading(false);
  };

  return (
    <div className="main_mui_page lesson_plane_main">
      <Header history={history} />
      <div
        style={{
          height: "200px",
          background: "rgb(136, 155, 255)",
          width: "100%",
        }}
      ></div>
      <div className="mui_in">
        <Container
          maxWidth="xl"
          className={classes.container + " opened-classes-main"}
        >
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Button
              style={{ margin: "10px" }}
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                history.goBack();
              }}
            >
              <span className="MuiButton-label">{t("Back")}</span>
            </Button>
          </Box>
          <Box
            flexWrap="wrap"
            p={3}
            m={0}
            bgcolor="background.paper"
            borderRadius={10}
            display="flex"
          >
            {allLeason?.length > 0 ? (
              allLeason &&
              allLeason
                // .filter(value => value.lesson_plans && value.lesson_plans.length > 0)
                .map((value, index) => (
                  <Grid item xs={12} md={6} lg={4} key={index}>
                    <div className={Styles.root}>
                      <div className={Styles.card}>
                        <Row p={2} gap={2}>
                          <Info
                            position={"middle"}
                            useStyles={useApexInfoStyles}
                            p={0}
                          >
                            <Box
                              fontSize={"18px"}
                              letterSpacing={"0px"}
                              lineHeight={"20px"}
                              fontWeight={"bold"}
                              minHeight={"50px"}
                              fontFamily={Fonts.Medium}
                              color={Color.textMyBlack}
                            >
                              {
                                <div className="content_outer">
                                  <h6>{t("Leason Plan")}</h6>
                                  <p>
                                    {t("Title")}:{" "}
                                    <span>{`${value?.lesson_plans[0]?.lesson_plan?.title}`}</span>
                                  </p>
                                  <div className="activity_title_box"><h5>{t("activities")}</h5> </div>
                                  <div className="activity_box">
                                    {value?.lesson_plans[0]?.activities?.map(
                                      (activity, index) => (
                                        <div key={index}>{activity}</div>
                                      )
                                    )}
                                  </div>
                                </div>
                              }
                            </Box>
                          </Info>
                        </Row>
                        <Box
                          pb={1}
                          px={2}
                          color={"grey.600"}
                          fontSize={"0.875rem"}
                          fontFamily={"Ubuntu"}
                        ></Box>
                        <Row p={2} gap={2} position={"bottom"} className="button-box-outer">
                          <Item position={"center"}>
                            <Button
                              className={Styles.delete}
                              variant={"contained"}
                              onClick={() =>
                                handleDeleteTopic(value?.topic?.lesson_id)
                              }
                            >
                              {t("delete_topic")}
                            </Button>

                            {isDeleteTopic && (
                              <PopUpWarning
                                heading={t("Delete")}
                                subHeading={t("Are you sure you want to delete this topic?")}
                                leftButton={t("Cancel")}
                                rightButton={t("Delete")}
                                handleLeftButton={handleCancelDelete}
                                handleRightButton={handleConfirmDelete}
                              />
                            )}


                            <Button
                              className={Styles.join}
                              variant={"contained"}
                              onClick={() => {
                                history.push(
                                  `/course-creator/topics/singleLessonPlanner/${value?.topic?.lesson_id}?id=${id}&weekId=${weekId}&topicId=${topicId}`
                                );
                              }}
                            >
                              {t("open_topic")}
                            </Button>

                            {/* show popUp of activites list */}

                            <Button
                              onClick={() =>
                                handleActivity(value?.topic?.lesson_id)
                              }
                            >
                              {t("start_activity")}
                            </Button>
                          </Item>
                        </Row>
                      </div>
                    </div>
                  </Grid>
                ))
            ) : (
              <span>No Lesson Plan Found found !</span>
            )}
          </Box>
        </Container>
      </div>

      {isActivityPopup && (
        <div
          onClick={() => {
            document.body.style.overflow = "auto";
            setIsActivityPopup(null);
          }}
          className={Styles.popup}
        >
          <div
            onClick={(event) => event.stopPropagation()}
            className={Styles.different_popup}
          >
            {isLoading ? (
              <div className="spinner-container">
                <div className="spinner2"></div>
              </div>
            ) : (
              <>
                {
                  <div className="active-popup">
                    {
                      <ActivityListPopUp
                        popupType={"leassonPlan"}
                        setLessonPlanActivity={setLessonPlanActivity}
                        activities={lessonPlanActivity}
                        lessonId={isActivityPopup}
                        setIsActivityPopup={setIsActivityPopup}
                        id={id}
                        weekId={weekId}
                        topicId={topicId}
                      />
                    }
                  </div>
                }
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllLeassonList;
