import axios from "axios";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Const from "../../../helpers/const";
import PopUpWarning from "./PopUpWarning";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PopUpNextActivity = ({
  heading,
  subHeading,
  leftButton,
  rightButton,
  handleLeftButton,
  handleRightButton,
  loading = false,
  questions = [],
}) => {
  const { lessonId, activityType } = useParams();
  const [isLastActivity, setIsLastActivity] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const weekId = queryParams.get("weekId");
  const topicId = queryParams.get("topicId");

  const handelSubmitAndExit = async () => {
    // history.goBack();
    history.push(`/course-creator/topics/allLessonPlanner/${id}/${weekId}/${topicId}`);
  };

  const handelSubmitAndContinue = async () => {
    const response = await axios.get(
      `${Const.BASE_URL}get/${lessonId}/lessonplan`
    );
    const activities = response?.data?.lesson_plans[0]?.activities;
    const index = activities.findIndex(
      (activity) => activity.activity_name.replace("/", "-") === activityType
    );
    console.log("activity: ", index);
    if (index === -1 || index === activities.length - 1) {
      console.log("Last Activity");
      setIsLastActivity(true)
      return null;
    }

    history.push(
      `/course-creator/activity/${lessonId}/${activities[
        index + 1
      ]?.activity_name.replace("/", "-")}?id=${id}&weekId=${weekId}&topicId=${topicId}`
    );
  };

  return (
    <div className="pop-up-warning-container">
      {loading ? (
        <div className="spinner"></div>
      ) : (
        <>
          <div>{heading}</div>
          {questions.length > 0 && (
            <div className="pop_heading">
              <h2>Answers</h2>
              <div className="score_list_outer"> 
                {questions?.map((question, index) => (
                  <div key={index} className="score_list">
                    <h3>{`${index + 1}. ${question.question}`}</h3>
                    <p>{`Answer: ${question.answer}`}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div>{subHeading}</div>

          <div>
            <button
              className="custom_btn"
              color="primary"
              variant="contained"
              style={{ marginLeft: 5 }}
              onClick={() => {
                handelSubmitAndExit();
              }}
            >
              {leftButton}
            </button>

            <button
              className="custom_btn"
              color="primary"
              variant="contained"
              style={{ marginLeft: 5 }}
              onClick={async () => {
                await handelSubmitAndContinue();
              }}
            >
              {rightButton}
            </button>
          </div>
        </>
      )}

      {
        isLastActivity &&
        <PopUpWarning
          heading={"Warning"}
          subHeading={"You are on the last activity. There are no more activities in Current Leasson Plan."}
          // leftButton={"Cancel"}
          rightButton={"Exit"}
          handleLeftButton={() => {
            // setCurrentPopShow(null);
          }}
          handleRightButton={() => {
            handelSubmitAndExit()
          }}
        />
      }
    </div>
  );
};

export default PopUpNextActivity;
