import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PopUpWarning from "./PopUpWarning";
import PopUpNextActivity from "./PopUpNextActivity";
import { saveScoreAPI } from "./scoreStoreApi";
import MarkdownRender from "./MarkdownRender";

const useStyles = makeStyles({
  quizContainer: {
    width: "100%",
    margin: "auto",
  },
});

const QuizActivity = ({ quizData, lessonId, type, readingArticle }) => {
  //console.log("quizData>>", quizData);

  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [currentQustionAnswer, setCurrentQustionAnswer] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentPopShow, setCurrentPopShow] = useState(null);

  const [allAnswers, setAllAnswers] = useState(
    Array(questions.length).fill("")
  );

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [content, setContent] = useState(null);

  const [scoreLoading, setScoreLoading] = useState(false);
  const [score, setScore] = useState({ score: 0, maxScore: allAnswers.length });

  useEffect(() => {
    if (quizData && quizData.Quiz) {
      try {
        // Clean the input string by removing the surrounding ```json and ```
        const cleanedJsonString = quizData.Quiz.replace(
          /```json|```/g,
          ""
        ).trim();

        // Parse the cleaned JSON string
        const parsedData = JSON.parse(cleanedJsonString);
        //console.log("parsed data>>", parsedData);
        setContent(parsedData.LessonContent);
        setQuestions(parsedData.QuizActivityquestions);
      } catch (error) {
        //console.log("Error parsing JSON", error);
      }
    } else if (quizData && quizData.Reading) {
      try {
        const cleanedJsonString = quizData.Reading.replace(
          /```json|```/g,
          ""
        ).trim();

        // Parse the cleaned JSON string
        const parsedData = JSON.parse(cleanedJsonString);
        //console.log("parsed data>>", parsedData);
        setContent(parsedData.LessonContent);
        setQuestions(parsedData.QuizActivityquestions);
      } catch (error) {
        //console.log("Error parsing JSON", error);
      }
    }
  }, [quizData]);

  const scoreCalculation = () => {
    let score = 0;
    for (let index = 0; index < allAnswers.length; index++) {
      questions[index].answer === allAnswers[index] && score++;
    }
    return { score: score, totalScore: allAnswers.length };
  };

  const showExitPopup = () => {
    setCurrentPopShow("exitPopUp");
  };
  const saveAnswers = () => {
    //console.log("submit All Answers");
    if (currentQustionAnswer) {
      const updatedAnswers = [...allAnswers];
      updatedAnswers[currentQuestionIndex] = currentQustionAnswer;
      setAllAnswers(() => updatedAnswers);
    }
  };

  const showSubmitPopUp = () => {
    setCurrentPopShow("submitPopUp");

    //console.log("showSubmitPopUp");
  };

  const selectAnswer = (event, currentQuestionIndex, option) => {
    event.preventDefault();
    const updatedAnswers = [...allAnswers];
    updatedAnswers[currentQuestionIndex] = option;
    setAllAnswers(() => updatedAnswers);
  };

  //popup functions:
  const handleSubmitAndGetScore = async () => {
    setScoreLoading(true);

    setCurrentPopShow("scoreForm");
    const score = await scoreCalculation();
    setScore(score);
    setScoreLoading(false);
    //console.log('hit score api: ', lessonId, " -> ", score)
    await saveScoreAPI(Number(lessonId), score.score, questions.length, type==="Reading"? "Reading":"Quiz");
    // history.push(`/course-creator/score/${lessonId}/Quiz`, { score });
  };

  useEffect(() => {
    setCurrentQustionAnswer("");
  }, [currentQuestionIndex]);

  useEffect(() => {
    // setQuestions(quizData);
    setAllAnswers(Array(quizData.length).fill(""));
  }, [quizData]);
  
  return (
    <div>
      <div className={classes.quizContainer}>
        <div className="page_outer page_outer_quiz">
          <div className="inner_box">
            <div className="top_heading">
              <h2>
                <span>Quiz Questions</span>
              </h2>
            </div>
            <div className="page_inner">
              <div className="quiz_inner">
                {/* {content &&
                  content?.map((con) => {
                    return <div key={con}>{con}</div>; // Add 'key' if you're rendering a list
                  })} */}
                  { 
                    readingArticle ? <MarkdownRender text={readingArticle} classAdd={"reading-article"} /> 
                    : content &&
                      content?.map((con) => {
                        return <div key={con}>{con}</div>;
                      })
                  }
              </div>
              <div className="question_list" item xs={12}>
                <h3>Question {currentQuestionIndex + 1}</h3>
                <h6>{questions[currentQuestionIndex]?.question}</h6>
              </div>
              <div className="question_option">
                {questions[currentQuestionIndex]?.options?.map(
                  (option, index) => (
                    <div className="question_mark" key={index}>
                      <input
                        type="radio"
                        id={`option-${index}`}
                        name="options"
                        onChange={() => {
                          setCurrentQustionAnswer(option);
                        }}
                        checked={
                          currentQustionAnswer
                            ? currentQustionAnswer === option
                            : allAnswers[currentQuestionIndex] === option
                        }
                      />
                      <label
                        htmlFor={`option-${index}`}
                      >{` ${String.fromCharCode(
                        65 + index
                      )}. ${option}`}</label>
                    </div>
                  )
                )}
              </div>
              <Grid
                className="question_btn_list"
                item
                xs={12}
                container
                justifyContent="center"
              >
                <Button
                  className="custom_btn"
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    currentQuestionIndex > 0
                      ? setCurrentQuestionIndex(currentQuestionIndex - 1)
                      : showExitPopup();
                  }}
                >
                  {t("Back")}
                </Button>
                {/* 
          <Button className='custom_btn'
            color="primary"
            variant="contained"
            style={{ marginLeft: 5 }}
            onClick={() => {
              saveAnswers();
            }}
          >
            {t("Submit")}
          </Button> */}

                <Button
                  className="custom_btn"
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    saveAnswers();
                    currentQuestionIndex < questions.length - 1
                      ? setCurrentQuestionIndex(currentQuestionIndex + 1)
                      : showSubmitPopUp();
                  }}
                >
                  {t(
                    currentQuestionIndex < questions.length - 1
                      ? "Next"
                      : "Submit"
                  )}
                </Button>

                {/* <Button className='custom_btn'
            color="primary"
            variant="contained"
            style={{ marginLeft: 5 }}
            onClick={() => {
              saveAnswers()
              showExitPopup()
            }}
          >
            {t("Save & Exit")}
          </Button> */}
              </Grid>
            </div>
            <div className={`after_save_outer ${currentPopShow === "scoreForm" && "final_score_popup"}`}>
              {currentPopShow === "submitPopUp" && (
                <PopUpWarning
                  heading={"Submit"}
                  subHeading={"After that you can't change your answer"}
                  leftButton={"Cancel"}
                  rightButton={"Submit"}
                  handleLeftButton={() => {
                    setCurrentPopShow(null);
                  }}
                  handleRightButton={async () => {
                    await handleSubmitAndGetScore();
                  }}
                />
              )}

              {currentPopShow === "exitPopUp" && (
                <PopUpWarning
                  heading={"Exit"}
                  subHeading={"After that you exit form activity"}
                  leftButton={"Cancel"}
                  rightButton={"Exit"}
                  handleLeftButton={() => {
                    setCurrentPopShow(null);
                  }}
                  handleRightButton={() => {
                    handleSubmitAndGetScore();
                  }}
                />
              )}
              {currentPopShow === "scoreForm" && (
                <PopUpNextActivity
                  heading={"Score"}
                  subHeading={`Your Score : ${score.score} out of ${questions.length}`}
                  questions={questions}
                  leftButton={`${t("Save & Exit")}`}
                  rightButton={`${t("Save & Continue")}`}
                  loading={scoreLoading}
                />
              )}
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default QuizActivity;
