import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import BotAvatar from "../../../assets/images/Frame 436.png";
import UserAvatar from "../../../assets/images/Frame 437.jpg";

import MarkdownRender from "./MarkdownRender";
import PopUpWarning from "./PopUpWarning";
import { useRef } from "react";
import PopUpNextActivity from "./PopUpNextActivity";
import { saveScoreAPI, updateToken } from "./scoreStoreApi";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ReactMic } from "react-mic";
import { FaMicrophoneLines } from "react-icons/fa6";
import { IoIosMicOff } from "react-icons/io";
import { FaMicrophoneSlash } from "react-icons/fa";

import { createModel } from "vosk-browser";

const RolePlayAcitivity = ({ rolePlayTopic, lessonId }) => {
  //console.log("rolepalystopic", rolePlayTopic);
  const { t } = useTranslation();
  const authUser = useSelector((store) => store.auth.user);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const weekId = queryParams.get("weekId");
  const topicId = queryParams.get("topicId");
  const [topicList, setTopicList] = useState(null);

  const [selectedRolePlayTopic, setSelectedRolePlayTopic] = useState(null);
  const [currentPopShow, setCurrentPopShow] = useState(null);

  const [roleList, setRoleList] = useState(null);
  const [roleSelected, setRoleSelected] = useState(null);

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [boatReplyPending, setBoatReplyPending] = useState(false);
  const [botResponseCount, setBotResponseCount] = useState(0);
  const [scoreLoading, setScoreLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");

  const [recognizer, setRecognizer] = useState(null);
  const [transcript, setTranscript] = useState("Click Start to record...");
  const [isListening, setIsListening] = useState(false);
  const [model, setModel] = useState(null);


  // useEffect(() => {
  //   loadVoskModel();
  // }, []);


//   const loadVoskModel = async () => {
//     setTranscript("Loading Vosk model...");
//     try {
//         const modelPath = process.env.PUBLIC_URL + "/vosk-model-small-en-us-0.15/";
//         const loadedModel = await createModel(modelPath);

//         setModel(loadedModel);
//         const recognizerInstance = await loadedModel.createRecognizer();
//         setRecognizer(recognizerInstance);  // Ensure recognizer is set before proceeding
//         setTranscript("Vosk loaded! Click Start.");
//     } catch (error) {
//         setTranscript("Error loading model: " + error.message);
//     }
// };

// const startListening = async () => {
//   if (!recognizer) {
//     console.error("Vosk recognizer is not ready yet!");
//     setTranscript("Error: Vosk is not loaded.");
//     return;
// }

//     setIsListening(true);
//     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//     const audioContext = new AudioContext();
//     const source = audioContext.createMediaStreamSource(stream);

//     recognizer.on("result", (event) => {
//         setTranscript(event.result.text);
//     });

//     recognizer.start(source);
// };


  // // const inputRef = useRef(null);
  // const [recording, setRecording] = useState(false);
  // const [audioBlob, setAudioBlob] = useState(null);

  const [score, setScore] = useState({ score: 0, maxScore: 100 });

  // // Start or stop recording
  // const toggleRecording = () => {
  //   setRecording((prev) => !prev);
  // };

  // // Called when recording stops
  // const onStop = (recordedBlob) => {
  //   console.log("Recorded Blob: ", recordedBlob);
  //   setAudioBlob(recordedBlob.blob);

  //       // Create a URL for the recorded audio so we can play it
  //       const audioFileUrl = URL.createObjectURL(recordedBlob.blob);
  //       setAudioUrl(audioFileUrl);
  //   uploadAudio(recordedBlob.blob); // Send to OpenAI or server
  //     // Convert and send audio to OpenAI for transcription
  //     // transcribeAudio(recordedBlob.blob);
  // };

  // // Convert and Send Audio to OpenAI
  // const uploadAudio = async (blob) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = async () => {
  //     const base64Audio = reader.result.split(",")[1]; // Extract Base64
  //     console.log("Base64 Audio:", base64Audio);

  //     // Here, you can send base64Audio to OpenAI API
  //     // Example: sendToOpenAI(base64Audio);
  //      // Send to OpenAI API
  //      const formData = new FormData();
  //      formData.append("file", blob, "audio.wav");
  //      formData.append("model", "whisper-1");
  //      formData.append("language", "en"); 

  //      try {
  //       const response = await fetch("https://irevu.openai.azure.com/openai/deployments/irevu4o0/audio/transcriptions?api-version=2024-02-15-preview", {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer 7327443ae85e44419192e4fb0544d702`,
  //         },
  //         body: formData,
  //       });

  //       const data = await response.json();
  //       console.log("Transcription:", data.text);

  //       // Update the input field with transcribed text
  //       setInputMessage(data.text);
  //     } catch (error) {
  //       console.error("Error transcribing audio:", error);
  //     }
    
  //   };
  // };




  //console.log("scoreout", score);

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const extractJSON = (str) => {
    try {
      const jsonString = str.replace(/```json|```/g, "").trim();
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null; // Or handle appropriately
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (rolePlayTopic && rolePlayTopic["Role-Plays"]) {
        const parsedData = await extractJSON(rolePlayTopic["Role-Plays"]);
        const extractedParsedData = parsedData?.RolePlayTopics;

        //console.log("parsed data>>", extractedParsedData);
        setTopicList(extractedParsedData);
      } else {
        // Handle case where rolePlayTopic is not valid or doesn't have "Role-Plays"
        console.warn(
          "rolePlayTopic is invalid or doesn't contain 'Role-Plays'"
        );
      }
    };

    fetchData();
  }, [rolePlayTopic]);
  // Another useEffect to log the topicList whenever it changes
  useEffect(() => {
    //console.log("topic list>>", topicList);
  }, [topicList]);

  const handelScrollToLastMessage = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const botDebateMessageReply = async (inputMessage) => {
    // Determine the assistant role based on the selected role
    const assistantRole = roleList.filter((role) => role !== roleSelected);
    const userRole = roleSelected; // Assume roleSelected is 'student' or 'teacher'

    const response = await axios.post(
      "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
      {
        messages: [
          {
            role: "system",
            content: `You are an AI ${JSON.stringify(
              assistantRole
            )} trained to engage in  participating in a role-play scenario.stay in topic ${JSON.stringify(
              selectedRolePlayTopic
            )} as much as possible  and the previous conversation between the user and the bot is here  previous debate conversation: ${JSON.stringify(
              messages
            )}.
            User's message is: ${JSON.stringify(
              inputMessage
            )}.Your response should always  with the ${JSON.stringify(
              userRole
            )} point and according to ${JSON.stringify(
              inputMessage
            )} while being concise and well-reasoned.  The roles are as follows: 
            - **User:** You are a [${JSON.stringify(userRole)}]. 
            - **Assistant:** You are a [${JSON.stringify(assistantRole)}]. 
            The conversation will follow a scenario based on the provided roles and context. Respond naturally as if you were in this situation. Keep the conversation on-topic dont go off-context keep clear, and use a friendly, professional tone. 
            For this session, the scenario is about: "${JSON.stringify(
              selectedRolePlayTopic
            )}".`,
          },
          {
            role: "system",
            content: `The conversation context is as follows: "${JSON.stringify(
              messages
            )}". Respond according to the roles and scenario given. The user is acting as a [${userRole}] and the assistant is acting as a [${assistantRole}]. Continue the conversation accordingly.`,
          },
          // Example conversation specific to student and teacher
          {
            role: "user",
            content: `i will act as a ${JSON.stringify(
              userRole
            )} conversation with ${JSON.stringify(
              assistantRole
            )} as i am in sitautaion and play role of ${JSON.stringify(
              userRole
            )} .`,
          },
          {
            role: "assistant",
            content: `i will act as${JSON.stringify(
              assistantRole
            )}. and will talk with  ${JSON.stringify(
              userRole
            )} give reply of user each user message ${JSON.stringify(
              inputMessage
            )} as i m in sitaution `,
          },
          // {
          //   "role": "user",
          //   "content": "I'm struggling with math. Can you explain how to solve quadratic equations?"
          // },
          // {
          //   "role": "assistant",
          //   "content": "Sure! Quadratic equations can be solved using the quadratic formula. Would you like me to walk you through an example?"
          // }
        ],
        max_tokens: 4000,
        temperature: 0.1,
        frequency_penalty: 0,
        presence_penalty: -1.5,
        stop: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": "7327443ae85e44419192e4fb0544d702",
        },
      }
    );

    //console.log("response>>>>>>>>>>>>>>>>>>>>>>>>", response.data.choices[0].message);
    const word_token = response.data.usage.total_tokens
    await updateToken(authUser, word_token)
    return response.data.choices[0].message.content;
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== "") {
      setMessages([...messages, { sender: "user", text: inputMessage }]);
      setBoatReplyPending(true);
      if (botResponseCount < 10) {
        const botReply = await botDebateMessageReply(inputMessage);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: botReply },
        ]);
        setBotResponseCount((prevCount) => prevCount + 1);
      } else {
        const thankYouMessage = "Conversession Is Limited To Only 10 Chat";
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: thankYouMessage },
        ]);
      }

      setInputMessage("");
      setBoatReplyPending(false);
      setInputMessage("");
      inputRef.current.focus();
    }
  };

  const handelSubmit = async () => {
    //console.log("sumbit");
    setScoreLoading(true);
    setCurrentPopShow("scoreForm");

    const score = JSON.parse(await getRolePlayScore());
    //console.log("score", score);

    setScore(score);
    //console.log("score: ", score);
    await saveScoreAPI(Number(lessonId), score.score, score.maxScore, "Role-Plays");

    setScoreLoading(false);
  };

  const handleSubmitAndExit = async () => {
    // const score = scoreCalculation();
    // history.goBack();
    history.push(`/course-creator/topics/allLessonPlanner/${id}/${weekId}/${topicId}`);

  };

  const showExitPopup = () => {
    setCurrentPopShow("exitPopUp");
  };

  const showSubmitPopUp = () => {
    setCurrentPopShow("submitPopUp");
  };

  const handleKeyDown = (e) => {
    handelScrollToLastMessage();
    if (e.key === "Enter" && !boatReplyPending) {
      handleSendMessage(); // Trigger send message when Enter is pressed
    }
  };

  useEffect(() => {
    handelScrollToLastMessage();
  }, [messages]);

  useEffect(() => {
    setTopicList(rolePlayTopic?.topics);
  }, [rolePlayTopic]);

  const getRolePlayScore = async () => {
    // Determine the assistant role based on the selected role
    const assistantRole = roleList.filter((role) => role !== roleSelected);
    const userRole = roleSelected; // Assume roleSelected is 'student' or 'teacher'

    const response = await axios.post(
      "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
      {
        messages: [
          {
            role: "system",
            content: `Your task is to evaluate and score the user's performance in a role play activity based on their message and the assistant's response. 
              Provide a score between 0 and 100, similar to an IELTS exam. The score should be based on: Coherence and Cohesion, Task Achievement, Lexical Resource, Grammatical Range and Accuracy, and Creativity and Engagement.
              Always assess how well the user stays in character, meets the role play objectives, and how effectively they interact with the assistant’s responses.
              Consider the user’s role and the assistant's role while evaluating the conversation.`,
          },
          {
            role: "system",
            content: `Role Play Scenario: 
              User Role: ${JSON.stringify(userRole)}. 
              Assistant Role: ${JSON.stringify(assistantRole)}. 
              Role Play Topic: ${JSON.stringify(rolePlayTopic)}. 
              The conversation is as follows: ${JSON.stringify(messages)}`,
          },

          {
            role: "system",
            content: `Evaluate the user's role-play performance, considering their role as a ${userRole} and the assistant's role as a ${assistantRole}. 
              Provide a score based on IELTS-style assessment: Coherence and Cohesion, Task Achievement, Lexical Resource, Grammatical Range and Accuracy, and Creativity and Engagement.
              Output a score out of 100.
  
              Ensure the response is always in JSON format without any other text and suggestions.
              Example output format:
              {
                score: 85 (Number),
                maxScore: 100 (Number)
              }`,
          },
        ],
        max_tokens: 500,
        temperature: 0.3,
        frequency_penalty: 0.5,
        presence_penalty: 0.4,
        stop: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": "7327443ae85e44419192e4fb0544d702",
        },
      }
    );

    const word_token = response.data.usage.total_tokens
    await updateToken(authUser, word_token)
    return response.data.choices[0].message.content;
  };

  const handleNewChat = () => {
    setMessages([]);
    setInputMessage("");
    setBoatReplyPending(false);
    setBoatReplyPending(false);
  };

  return (
    <div className="page_outer role_pay_outer">
      {selectedRolePlayTopic === null ? (
        <>
          <h2>
            <span>Role Play</span>
          </h2>
          <div className="inner_box">
            <button
              className='back-btn'
              color="primary"
              variant="contained"
              style={{ marginLeft: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => {
                // history.goBack();
                history.push(`/course-creator/topics/allLessonPlanner/${id}/${weekId}/${topicId}`);

              }}
            >
              Back
            </button>
            <div className="chat_topic">
              <p style={{ textAlign: "left" }}>Please select the topic:</p>
              {topicList?.map((topic, index) => (
                <button
                  className="topic_label"
                  key={index}
                  style={{
                    marginTop: "15px", cursor: "pointer", padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    backgroundColor: "#f9f9f9",
                    fontSize: "16px",
                    textAlign: "left",
                    width: "100%",
                    transition: "background-color 0.3s",
                  }}
                  onClick={() => {
                    setRoleList(topic?.participants ?? []);
                    setSelectedRolePlayTopic(topic.topic);
                  }}
                >
                  {index + 1}. {topic?.topic}
                </button>
              ))}
            </div>
          </div>
        </>
      ) : roleSelected === null ? (
        <>
          <h2>
            <span>Role Play</span>
          </h2>
          <div className="inner_box">
            <button
              className='back-btn'
              color="primary"
              variant="contained"
              style={{ marginLeft: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => {
                setSelectedRolePlayTopic(null);
              }}
            >
              Back
            </button>
            <div className="chat_topic role_type">
              <p style={{ textAlign: "left" }}>Please select a role:</p>

              {roleList?.map((role, index) => (
                <button
                  className="topic_label"
                  onClick={() => setRoleSelected(role)}
                  style={{
                    marginTop: "15px",
                    cursor: "pointer",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    backgroundColor: "#f9f9f9",
                    fontSize: "16px",
                    textAlign: "left",
                    width: "100%",
                    transition: "background-color 0.3s",
                  }}
                >
                  {index + 1}. {role}
                </button>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="inner_box">
            <div className="top_heading">
              <h2>
                <span>Role Plays</span>
              </h2>
              <h3>Topic :- {selectedRolePlayTopic}</h3>
              <h4>User Role :- {roleSelected}</h4>
            </div>
            <div className="chat_main">
              <div className="btn_actions">
                <button onClick={handleNewChat}>New Chat</button>
                {/* <button>Recent Chats</button>
                <button>Rephrase text</button>
                <button>Fix this code</button>
                <button>Sample Copy for</button> */}
              </div>
              <div className="chat_side">
                <div className="chat_lists">
                  <div className="chat-messages">
                    {messages.map((message, index) => (
                      <div
                        key={index}
                        className={`message ${message.sender === "bot" ? "user" : "bot"
                          }`}
                      >
                        <div className="avatar">
                          {message.sender === "bot" ? (
                            <img src={BotAvatar} alt="boat" loading="lazy" />
                          ) : (
                            <img src={UserAvatar} alt="boat" loading="lazy" />
                          )}
                        </div>
                        <MarkdownRender text={message.text} />
                        {messages.length - 1 === index && (
                          <div id="refDiv" ref={messagesEndRef} />
                        )}
                      </div>
                    ))}
                    {/* {
                      boatReplyPending && <div>
                        Bot reply pending
                      </div>
                    } */}
                    <div>
                      {boatReplyPending && <div className="spinner"></div>}
                    </div>
                  </div>
                </div>

                {/* <button onClick={toggleRecording} disabled={boatReplyPending} className="mic-button">
        {recording ? <FaMicrophoneLines /> : <FaMicrophoneSlash /> }
      </button> */}

      {/* ReactMic for Recording */}
      {/* <ReactMic
        record={recording}
        onStop={onStop}
        mimeType="audio/wav"
        className="audio-wave"
      />
      {audioUrl && (
        <div>
          <audio controls>
            <source src={audioUrl} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
          <a href={audioUrl} download="recorded-audio.wav">
            📥 Download Audio
          </a>
        </div>
      )} */}

        {/* <div>
            <h1>Vosk Speech-to-Text (React + WASM)</h1>
            <button onClick={startListening} disabled={!recognizer}>
                {isListening ? "Listening..." : "Start Recording"}
            </button>
            <p>{transcript}</p>
        </div> */}
                <div className="chat-input">

               

                  <input
                    type="text"
                    ref={inputRef}
                    disabled={boatReplyPending}
                    value={boatReplyPending ? "" : inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder="Type a new message here"
                    onKeyDown={handleKeyDown}
                  />
                   

                  <button
                    className="btn"
                    onClick={boatReplyPending === false && handleSendMessage}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.4653 11.5875L31.7413 16.6607C35.1691 18.2029 37.8667 19.4165 39.6968 20.5659C41.5043 21.7011 42.9168 23.047 42.9168 25C42.9168 26.953 41.5043 28.299 39.6968 29.4342C37.8667 30.5835 35.1691 31.7972 31.7414 33.3393L20.4653 38.4125C18.3703 39.3551 16.7405 40.0884 15.4678 40.5643C14.2225 41.03 13.1095 41.3358 12.1199 41.2282C9.71954 40.9672 7.65405 39.3212 7.15415 36.9973C6.94228 36.0124 7.23854 34.9106 7.66419 33.7661C8.10491 32.581 8.80538 31.0594 9.69776 29.1209L9.72182 29.0686C10.4193 27.5535 10.6877 26.9557 10.8246 26.3593C11.0306 25.4621 11.0306 24.5379 10.8246 23.6407C10.6877 23.0443 10.4193 22.4465 9.72182 20.9314L9.69776 20.8792C8.80538 18.9407 8.10491 17.4191 7.66419 16.234C7.23854 15.0894 6.94228 13.9876 7.15415 13.0027C7.65405 10.6789 9.71954 9.03287 12.1199 8.77185C13.1095 8.66423 14.2225 8.97006 15.4678 9.43574C16.7405 9.91163 18.3703 10.6449 20.4653 11.5875ZM14.5922 11.7774C13.3686 11.3198 12.7435 11.2188 12.3902 11.2572C10.9101 11.4181 9.84116 12.3992 9.59824 13.5285C9.55304 13.7386 9.5846 14.2257 10.0074 15.3626C10.4112 16.4483 11.0712 17.8841 11.9928 19.886C12.0129 19.9299 12.0329 19.9732 12.0526 20.0161C12.671 21.3589 13.0594 22.2024 13.2612 23.0812C13.5518 24.3466 13.5518 25.6534 13.2612 26.9188C13.0594 27.7977 12.671 28.6412 12.0526 29.984C12.0329 30.0268 12.0129 30.0701 11.9928 30.114C11.0712 32.116 10.4112 33.5517 10.0074 34.6375C9.5846 35.7744 9.55304 36.2614 9.59824 36.4716C9.84116 37.6008 10.9101 38.5819 12.3902 38.7428C12.7435 38.7812 13.3686 38.6802 14.5922 38.2227C15.7794 37.7787 17.3351 37.0795 19.4847 36.1123L30.6145 31.1049C34.165 29.5075 36.702 28.3628 38.3671 27.3171C40.0727 26.2459 40.4168 25.5501 40.4168 25C40.4168 24.45 40.0727 23.7541 38.3671 22.683C36.702 21.6372 34.165 20.4925 30.6145 18.8951L19.4847 13.8877C17.3351 12.9206 15.7794 12.2213 14.5922 11.7774Z"
                        fill="#02040F"
                      />
                    </svg>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Grid
              className="question_btn_list"
              item
              xs={12}
              container
              justifyContent="center"
            >
              <Button
                className="custom_btn"
                color="primary"
                variant="contained"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  showExitPopup();
                }}
              >
                {t("Back")}
              </Button>

              <Button
                className="custom_btn"
                color="primary"
                variant="contained"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  // saveAnswers();
                  showSubmitPopUp();
                }}
              >
                {t("Submit")}
              </Button>
              {/* 
                    <Button className='custom_btn'
                      color="primary"
                      variant="contained"
                      style={{ marginLeft: 5 }}
                      onClick={() => {
                        // saveAnswers();
                        showSubmitPopUp();
                    }}
                    >
                      {t("Save & Continue")}
                    </Button>

                    <Button className='custom_btn'
                      color="primary"
                      variant="contained"
                      style={{ marginLeft: 5 }}
                      onClick={() => {
                        // saveAnswers()
                        showExitPopup()
                      }}
                    >
                      {t("Save & Exit")}
                    </Button> */}
            </Grid>
          </div>
          {currentPopShow === "submitPopUp" && (
            <PopUpWarning
              heading={"Submit"}
              subHeading={"After that you can't change your answer"}
              leftButton={"Cancel"}
              rightButton={"Submit"}
              handleLeftButton={() => {
                setCurrentPopShow(null);
              }}
              handleRightButton={async () => {
                await handelSubmit();
              }}
            />
          )}

          {currentPopShow === "exitPopUp" && (
            <PopUpWarning
              heading={"Exit"}
              subHeading={"After that you exit form activity"}
              leftButton={"Cancel"}
              rightButton={"Exit"}
              handleLeftButton={() => {
                setCurrentPopShow(null);
              }}
              handleRightButton={async () => {
                await handleSubmitAndExit();
              }}
            />
          )}
          {currentPopShow === "scoreForm" && (
            <PopUpNextActivity
              heading={"Score"}
              subHeading={`Your Score : ${score.score} out of ${score.maxScore}`}
              leftButton={`${t("Save & Exit")}`}
              rightButton={`${t("Save & Continue")}`}
              loading={scoreLoading}
            />
          )}
        </>
      )}
    </div>
  );
};

export default RolePlayAcitivity;
