import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import PopUpWarning from "./PopUpWarning";
import PopUpNextActivity from "./PopUpNextActivity";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const RealWorldProject = ({ RealActivity }) => {
  const [allTask, setALLTask] = useState([]);
  const [currentPopShow, setCurrentPopShow] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const weekId = queryParams.get("weekId");
  const topicId = queryParams.get("topicId");
  //console.log("RealActivity>>",RealActivity);
  //console.log("currentpop",currentPopShow);

  // useEffect(()=>{
  //             if(RealActivity && RealActivity.HomeWork){
  //              const jsonstring = RealActivity.HomeWork
  //              const parsedData = JSON.stringify(jsonstring)
  //              //console.log("parsedData>",parsedData);
  //             }
  // },[RealActivity])

  const parseHomework = (text) => {
    const tasks = text?.split(/## \*\*(.*?)\*\*\n/).filter(Boolean); // Split tasks
    const homeworkArray = [];

    for (let i = 1; i < tasks.length; i += 2) {
      const taskTitle = tasks[i].replace(/#/g, "").trim(); // Remove "#" symbols
      const taskInstructions = tasks[i + 1]
        .split(/\n(?=\d+\.\s)/) // Split by numbers followed by periods (e.g., "1. ", "2. ")
        .filter((instruction) => instruction.trim() !== "") // Remove empty lines
        .map((instruction) => {
          // Remove redundant stars, hashes, and extra "Step-by-Step Guide"
          const cleanedInstruction = instruction
            .replace(/(\*\*|#)/g, "") // Remove "**" and "#"
            .replace(/Step-by-Step Guide\s*/gi, "") // Remove redundant "Step-by-Step Guide"
            .trim();
          return cleanedInstruction;
        });

      // Add task with instructions only if it doesn't already exist
      homeworkArray.push({
        task: taskTitle,
        instructions: [...new Set(taskInstructions)], // Ensure unique instructions
      });
    }

    return homeworkArray;
  };

  useEffect(() => {
    const generateData = async () => {
      // Changed function name to generateData and declared it as async
      if (RealActivity && RealActivity["Real World Projects"]) {
        try {
          const RealActivityTasks = await parseHomework(
            RealActivity["Real World Projects"]
          );
          //console.log("RealActivityTasks>>", RealActivityTasks);
          setALLTask(RealActivityTasks);
        } catch (error) {
          console.error("Error parsing homework:", error); // Added error handling
        }
      }
    };

    generateData();
  }, [RealActivity]);

  // Example of how to log the tasks
  const showExitPopup = () => {
    //console.log("helllo");

    setCurrentPopShow("exitPopUp");
  };

  const handleSubmitAndExit = async () => {
    //console.log("goback");

    // const score = scoreCalculation();
    // history.goBack()
    history.push(
      `/course-creator/topics/allLessonPlanner/${id}/${weekId}/${topicId}`
    );
  };
  const showSubmitPopUp = () => {
    setCurrentPopShow("scoreForm");
  };
  //console.log("all task >>",allTask);

  return (
    <div className="Real_main">
      {allTask.map((subTask, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <h5>{subTask.task}</h5>
          {subTask.instructions.map((item, instructionIndex) => (
            <div
              className="Real_third"
              key={instructionIndex}
              style={{ marginLeft: "20px", marginBottom: "10px" }}
            >
              {item.split("\n").map((line, lineIndex) => (
                <p key={lineIndex} style={{ margin: 0 }}>
                  {line.trim()}
                </p>
              ))}
            </div>
          ))}
        </div>
      ))}
      <Grid
        className="question_btn_list"
        item
        // xs={12}
        container
        justifyContent="center"
      >
        <Button
          className="custom_btn"
          color="primary"
          variant="contained"
          style={{ marginLeft: 5 }}
          onClick={() => {
            showExitPopup();
          }}
        >
          {t("Back")}
        </Button>
        <Button
          className="custom_btn"
          color="primary"
          variant="contained"
          style={{ marginLeft: 5 }}
          onClick={() => {
            showSubmitPopUp();
          }}
        >
          {t("Submit")}
        </Button>
      </Grid>

      {currentPopShow === "exitPopUp" && (
        <PopUpWarning
          heading={"Exit"}
          subHeading={"After that you exit form activity"}
          leftButton={"Cancel"}
          rightButton={"Exit"}
          handleLeftButton={() => {
            setCurrentPopShow(null);
          }}
          handleRightButton={async () => {
            await handleSubmitAndExit();
          }}
        />
      )}

      {currentPopShow === "scoreForm" && (
        <PopUpNextActivity
          heading={"Real World Projects"}
          // subHeading={`Your Score : ${score.score} out of ${score.maxScore}`}
          leftButton={`${t("Save & Exit")}`}
          rightButton={`${t("Save & Continue")}`}
          // loading = {scoreLoading}
        />
      )}
    </div>
  );
};

export default RealWorldProject;
