import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import PopUpWarning from "./PopUpWarning";
import PopUpNextActivity from "./PopUpNextActivity";
import { saveScoreAPI } from "./scoreStoreApi";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MarkdownRender from "./MarkdownRender";

const GapFillerActivity = ({ gapFill, lessonId, readingArticle }) => {
  
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const weekId = queryParams.get("weekId");
  const topicId = queryParams.get("topicId");
  const { t, i18n } = useTranslation();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(
    Array(gapFill?.question?.length ?? 0).fill("")
  );
  const [checkanswers, setcheckAnswers] = useState(
    Array(gapFill?.question?.length ?? 0).fill("")
  );
  const [correctAnswers, setCorrectAnswer] = useState([]);

  const [isSubmit, setIsSubmit] = useState(false);
  const [scoreCard, setScoreCard] = useState({
    score: 0,
    totalScore: questions.length,
  });
  const [currentPopShow, setCurrentPopShow] = useState(null);
  const [Content, setContent] = useState(null);
  const [scoreLoading, setScoreLoading] = useState(false);
  const [gapSuffleanswer, setGapSuffleanswer] = useState([]);
  const [availableAnswers, setAvailableAnswers] = useState([]);
  

  function shuffleArray(array) {
    let shuffledArray = [...array]; // Create a copy of the original array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      // Generate a random index
      const randomIndex = Math.floor(Math.random() * (i + 1));
      // Swap the elements at the current index and the random index
      [shuffledArray[i], shuffledArray[randomIndex]] = [
        shuffledArray[randomIndex],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  useEffect(() => {
    if (gapFill && gapFill.length > 0 && gapFill[0]["Gap Fill"]) {
      try {
        let gapFillJsonString = gapFill[0]["Gap Fill"];
        gapFillJsonString = gapFillJsonString
          .replace(/^```json\s*/i, "")
          .replace(/\s*```$/i, "");
        const parsedGapData = JSON.parse(gapFillJsonString);

        setContent(parsedGapData.LessonContent);
        // You can extract questions here if needed
        const questionsArray = parsedGapData.questions;
        const answerArray = parsedGapData.answers;
        const suffledanswer = shuffleArray(answerArray);
        setGapSuffleanswer(suffledanswer);
        setAvailableAnswers(suffledanswer);

        setQuestions(questionsArray);
        setCorrectAnswer(answerArray);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [gapFill]);

  const handleChange = (index, value) => {
    
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);

    const updatedAvailableAnswers = [...availableAnswers];
    const indexToRemove = updatedAvailableAnswers.indexOf(value);
    
    if (indexToRemove !== -1) {
      updatedAvailableAnswers.splice(indexToRemove, 1);
    }
    setAvailableAnswers(updatedAvailableAnswers);
  };

  const handleRemoveAnswer = (questionIndex) => {
    const answerToRemove = answers[questionIndex];
    const newSelectedAnswers = [ ...answers ];
    newSelectedAnswers[questionIndex] = "";
    setAnswers(newSelectedAnswers);

    // Add the removed answer back to the available list at the end
    setAvailableAnswers((prev) => [...prev, answerToRemove]);
  };

  const scoreCalculation = () => {
    let score = 0;
    const correctWrongAnswer = answers.map((answer) => false);

    for (let index = 0; index < correctAnswers.length; index++) {
      if (
        answers[index]?.trim()?.toLowerCase() ===
        correctAnswers[index]?.trim()?.toLowerCase()
      ) {
        score++;
        correctWrongAnswer[index] = true;
      }
    }
    setcheckAnswers(correctWrongAnswer);
    return { score: score, totalScore: correctAnswers.length };
  };

  const handelShowExistPopup = () => {
    if (isSubmit) {
      // history.goBack();
    history.push(`/course-creator/topics/allLessonPlanner/${id}/${weekId}/${topicId}`);

      return;
    }
    setCurrentPopShow("exitPopUp");
  };

  const handelSaveAnswer = () => {
    setCurrentPopShow("submitPopUp");
  };

  //popUp function
  const handleSubmitAndGetScore = async () => {
    setScoreLoading(true);
    setCurrentPopShow("scoreForm");

    // setCurrentPopShow(null);
    const score = await scoreCalculation();

    await saveScoreAPI(
      Number(lessonId),
      score.score,
      score.totalScore,
      "Gap Fill"
    );

    setScoreCard(score);
    setScoreLoading(false);
    setIsSubmit(true);
  };

  const handleSubmitAndExit = () => {
    const score = scoreCalculation();
    // history.goBack();
    history.push(`/course-creator/topics/allLessonPlanner/${id}/${weekId}/${topicId}`);

    
  };

  useEffect(() => {
    // setQuestions(gapFill?.question ?? [])
    setAnswers(Array(gapFill?.question?.length ?? 0).fill(""));
    // setCorrectAnswer(gapFill?.answer)
  }, [gapFill]);

  return (
    <div className="gap_fill_main">
      <div className="page_outer">
        <div className="inner_box cust_inner_box cust_inner_box_gap">
          <div className="top_heading">
            <h2>
              <span>Gap Fill Activities</span>
            </h2>
          </div>
          {/* {Content && <div className="top_title_cust">{Content}</div>} */}
          {readingArticle && <MarkdownRender text={readingArticle} classAdd={"reading-article"} />}
          
          <div className="page_inner">
            {/* <p className="keyboard_cust">
              Keywods : -{" "}
              {gapSuffleanswer &&
                gapSuffleanswer.map((answers) => (
                  <span>{answers}, &nbsp;</span>
                ))}
            </p> */}
            {questions.map((question, index) => (
              <div
                className="question_list"
                container
                key={index}
                alignItems="center"
                spacing={2}
                mb={2}
              >
                <div className="list_tick" item xs={12} sm={8}>
                  <div className="list_side">
                    <span component="span">
                      {" "}
                      {`${index + 1}. ${question.split("______")[0].replace(/_/g, '')} `}{" "}
                    </span>
                    &nbsp;
                    {/* <input
                      type="text"
                      value={answers[index]}
                      onChange={(e) => handleChange(index, e.target.value)}
                      disabled={isSubmit}
                      placeholder=""
                    /> */}
                    {answers[index] ? (
                      <span className="fil_divider">
                        <span style={{color: "black", marginTop: "1px", marginBottom: "1px"}} >{answers[index]}</span>
                      </span>
                    ) : (
                      <select
                        onChange={(e) => handleChange(index, e.target.value)}
                        defaultValue=""
                        disabled={isSubmit}
                      >
                        <option value="" disabled>
                          Select an answer
                        </option>
                        {availableAnswers.map((answer, idx) => (
                          <option key={idx} value={answer}>
                            {answer}
                          </option>
                        ))}
                      </select>
                    )}
                    &nbsp;
                    <span component="span">{`${question?.split("______")[1].replace(/_/g, '') ??
                      ""} `}</span>
                  </div>
                  {
                    !isSubmit && answers[index] && <button
                      className="remove-btn"
                      disabled={isSubmit}
                      color="primary"
                      variant="contained"
                      onClick={() => handleRemoveAnswer(index)}
                    >
                      Unselect Answer
                    </button>
                  }
                  {isSubmit && (
                    <div>
                      <span>{correctAnswers[index]}</span>{" "}
                      {/* Display correct answer */}
                      <span>
                        {answers[index]?.toLowerCase().trim() ===
                        correctAnswers[index]?.toLowerCase().trim() ? (
                          <CheckCircleIcon />
                        ) : (
                          <CancelIcon />
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}

            <Grid
              className="question_btn_list"
              item
              xs={12}
              container
              justifyContent="center"
            >
              <Button
                className="custom_btn"
                color="primary"
                variant="contained"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  handelShowExistPopup();
                }}
              >
                {t("Back")}
              </Button>

              <Button
                className="custom_btn"
                color="primary"
                variant="contained"
                style={{ marginLeft: 5 }}
                disabled={isSubmit}
                onClick={() => {
                  handelSaveAnswer();
                }}
              >
                {t("Submit")}
              </Button>

              {/* <Button className='custom_btn'
            color="primary"
            variant="contained"
            style={{ marginLeft: 5 }}
            disabled={isSubmit}
            onClick={() => {
              handelSaveAnswer();
          }}
          >
            {t("Save & Continue")}
          </Button>

          <Button className='custom_btn'
            color="primary"
            variant="contained"
            style={{ marginLeft: 5 }}
            onClick={() => {
              handelShowExistPopup()
            }}
          >
            {t("Save & Exit")}
          </Button> */}
            </Grid>
            <div className="after_save_outer">
              {currentPopShow === "submitPopUp" && (
                <PopUpWarning
                  heading={"Submit"}
                  subHeading={"After that you can't change your answer"}
                  leftButton={"Cancel"}
                  rightButton={"Submit"}
                  handleLeftButton={() => {
                    setCurrentPopShow(null);
                  }}
                  handleRightButton={async () => {
                    await handleSubmitAndGetScore();
                  }}
                />
              )}
              {currentPopShow === "exitPopUp" && (
                <PopUpWarning
                  heading={"Exit"}
                  subHeading={"After that you exit form activity"}
                  leftButton={"Cancel"}
                  rightButton={"Exit"}
                  handleLeftButton={() => {
                    setCurrentPopShow(null);
                  }}
                  handleRightButton={async () => {
                    await handleSubmitAndExit();
                  }}
                />
              )}
            </div>

            {currentPopShow === "scoreForm" && (
              <PopUpNextActivity
                heading={"Score"}
                subHeading={`Your Score : ${scoreCard.score} out of ${scoreCard.totalScore}`}
                leftButton={`${t("Save & Exit")}`}
                rightButton={`${t("Save & Continue")}`}
                loading={scoreLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GapFillerActivity;
