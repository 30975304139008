import React, { useState, useEffect } from "react";
import { Form, Formik, Field } from "formik";
// import FormHelperText from '@material-ui/core/FormHelperText';

import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Input,
  FormControlLabel,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Box,
  Grid,
  FormLabel,
  FormGroup,
  LinearProgress,
  FormHelperText,
} from "@material-ui/core";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import jsPDF from "jspdf";
import PptxGenJS from "pptxgenjs";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Const from "../../helpers/const";
import { toast } from "react-toastify";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ActivityListPopUp from "./ActivityListPopUp";
import { type } from "jquery";
import { updateToken } from "./InteractiveActivities/scoreStoreApi";

const useStyles = makeStyles({
  // Form Card AI Component
  Container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  formikWrapper: {
    marginLeft: "0",
  },
  "& .Mui-selected": {
    textAlign: "left",
  },

  // Keywords Input styles
  chipInputContainer: {
    marginBottom: "10px",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    padding: "5px",
    minHeight: "36px",
  },
  chip: {
    margin: "2px",
  },
  input: {
    border: "none",
    outline: "none",
  },
  // Response Styles
  WrapperResponse: {
    display: "flex",
    textAlign: "left !important",
    alignItems: "center",
    flexDirection: "column",
  },
  ButtonGroup: {
    width: "90%",
    justifyContent: "space-around",
    display: "flex",
    flexDirection: "row",
  },
  button: {
    // margin: theme.spacing(0.5),
    backgroundColor: "#2196F3", // Blue color
    color: "#FFFFFF", // White text
    borderRadius: 20,
    width: "100%",
  },
  popUpParent: {
    position: "relative",
  },
  activityPopUp: {
    position: "absolute",
    bottom: "2.8rem",
    background: "#303f9f",
    padding: "0.8rem",
    borderRadius: "0.25rem",
    // border: "2px solid #303f9f",
    boxShadow: "5px 5px 20px #6b6b6b",
  },
});

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  borderRadius: 10,
  p: 2,
};

// Initial Values Formik
const initialValues = {
  subject: "",
  topic: "",
  level: "",
  lessonPlan: "",
  standard: "",
  university: "",
  age: "13",
  skill: "",
  activity: [],
  lesson_plan_length: "60",
  keywords: [],
  lesson_aims: [],
  esl: false,
};

// Validation Schema Yup
const validationSchema = Yup.object({
  // topic: Yup.string().required("Topic is required"),
  subtopic: Yup.string().required("Subtopic is required."),
});

const universityLevelDescriptions = {
  Foundation: "Include introductory concepts and foundational knowledge.",
  Intermediate: "Expand on foundational knowledge with intermediate topics.",
  Proficient: "Focus on advanced topics and practical applications.",
  Advanced: "Incorporate research elements and in-depth analysis.",
  Professional:
    "Emphasize professional skills and advanced theoretical knowledge.",
  Expert: "Target expert-level understanding and specialized research.",
};

// FUNCTION Custom  Chip Input using Material UI (can be made into a seperate component)
const ChipInput = ({ label, value, onChange, onKeyDown }) => {
  const classes = useStyles();

  // chip delete function
  const handleDelete = (chipToDelete) => () => {
    onChange(value.filter((chip) => chip !== chipToDelete));
  };

  return (
    <div>
      <TextField
        fullWidth
        label={label}
        variant="outlined"
        className={classes.input}
        onKeyDown={(e) => {
          if (e.key === "Enter" && e.target.value.trim()) {
            e.preventDefault();
            onChange([...value, e.target.value.trim()]);
            e.target.value = "";
          }
        }}
      />
      <div className={classes.chipContainer}>
        {value.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={handleDelete(chip)}
            className={classes.chip}
          />
        ))}
      </div>
    </div>
  );
};

const activities = [
  "Gap Fill",
  "Definition Match",
  "Reading",
  "Quiz",
  "Gist Questions",
  "Comprehension questions",
  "Writing",
  "Games",
  "Discussion/Debate",
  "Case Studies",
  "Real World Projects",
  "Role-Plays",
  "HomeWork",
];
const standardDescriptions = {
  "IB PYP": "Align activities with PYP objectives and learning outcomes.",
  "IB MYP": "Ensure alignment with MYP curriculum framework and criteria.",
  "IB DP": "Integrate DP subject guides and assessment criteria.",
  "IB CP": "Follow CP core and career-related studies guidelines.",
  "Cambridge IGCSE": "Incorporate IGCSE syllabus and assessment objectives.",
  "Cambridge A-Level":
    "Align with A-Level curriculum and examination requirements.",
  "Edexcel International GCSE":
    "Use Edexcel IGCSE syllabus and learning outcomes.",
  "Edexcel International A-Level":
    "Follow Edexcel A-Level curriculum guidelines.",
  "Edexcel International Advanced Level":
    "Integrate advanced level objectives and assessment.",
  AP: "Ensure alignment with AP course framework and exam preparation.",
  "CEFR A1": "Design activities for basic user competence (A1).",
  "CEFR A2": "Focus on elementary user skills (A2).",
  "CEFR B1": "Develop activities for independent user level (B1).",
  "CEFR B2": "Ensure tasks support upper intermediate proficiency (B2).",
  "CEFR C1": "Aim for advanced user capabilities (C1).",
  "CEFR C2": "Design for proficient user fluency (C2).",
  "Reggio Emilia":
    "Integrate Reggio Emilia principles and child-centric learning.",
  Montessori: "Incorporate Montessori materials and self-directed activity.",
};

const CourseLesson = () => {
  let content = "";
  const { id, weekId, topicId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const authUser = useSelector((store) => store.auth.user);

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lessonPlanResponse, setLessonPlanResponse] = useState(content);
  const [pdfData, setPdfData] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const steps = ["Generate Course Plan", "Response"];
  const [openModal, setOpenModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [correction, setCorrection] = useState("");
  const [courseDetail, setCourseDetail] = useState([]);
  const [isShowActivityList, setIsShowActivityList] = useState(false);
  const [activitySelected, setActivitySelected] = useState([]);
  const [leasonPlainId, setLeasonPlainId] = useState(null);
  const [activitySelect, setActivitySelect] = useState(false);

  const [selectedActivities, setSelectedActivities] = useState([]);
  const [error, seterror] = useState(null);

  const [countReadingWord, setCountReadingWord] = useState(null);

  // FUNCTION Handle Cancel
  const handleCancel = () => {
    setActiveStep(0);
    setLessonPlanResponse("");
    setisLoading(false);
  };

  const getCourses = async () => {
    try {
      const response = await axios.get(
        Const.BASE_URL + "data/user/" + authUser.id
      );
      setCourseDetail(
        response?.data?.data?.filter((course) => course.id == id)[0]
      );
    } catch (error) {
      console.error("Error getting courses:", error);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  const universityDescriptions = {
    Foundation: "Include introductory concepts and foundational knowledge.",
    Intermediate: "Expand on foundational knowledge with intermediate topics.",
    Proficient: "Focus on advanced topics and practical applications.",
    Advanced: "Incorporate research elements and in-depth analysis.",
    Professional:
      "Emphasize professional skills and advanced theoretical knowledge.",
    Expert: "Target expert-level understanding and specialized research.",
  };

  const standardDescriptions = {
    "IB PYP": "Align activities with PYP objectives and learning outcomes.",
    "IB MYP": "Ensure alignment with MYP curriculum framework and criteria.",
    "IB DP": "Integrate DP subject guides and assessment criteria.",
    "IB CP": "Follow CP core and career-related studies guidelines.",
    "Cambridge IGCSE": "Incorporate IGCSE syllabus and assessment objectives.",
    "Cambridge A-Level":
      "Align with A-Level curriculum and examination requirements.",
    "Edexcel International GCSE":
      "Use Edexcel IGCSE syllabus and learning outcomes.",
    "Edexcel International A-Level":
      "Follow Edexcel A-Level curriculum guidelines.",
    "Edexcel International Advanced Level":
      "Integrate advanced level objectives and assessment.",
    AP: "Ensure alignment with AP course framework and exam preparation.",
    "CEFR A1": "Design activities for basic user competence (A1).",
    "CEFR A2": "Focus on elementary user skills (A2).",
    "CEFR B1": "Develop activities for independent user level (B1).",
    "CEFR B2": "Ensure tasks support upper intermediate proficiency (B2).",
    "CEFR C1": "Aim for advanced user capabilities (C1).",
    "CEFR C2": "Design for proficient user fluency (C2).",
    "Reggio Emilia":
      "Integrate Reggio Emilia principles and child-centric learning.",
    Montessori: "Incorporate Montessori materials and self-directed activity.",
  };

  const extractSection = (text, header) => {
    const regex = new RegExp(
      `## \\*\\*${header}\\*\\*\\s*([\\s\\S]*?)(?=## \\*\\*|$)`
    );
    const match = text.match(regex);
    return match ? match[1].trim() : null;
  };

  const extractLinksWithContext = (text) => {
    const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
    let match;
    const linksWithContext = [];
    let lastIndex = 0;
    let cleanedText = "";

    while ((match = linkRegex.exec(text)) !== null) {
      const [fullMatch, displayText, url] = match;
      const start = match.index;
      const end = match.index + fullMatch.length;

      // Add text before the link
      cleanedText += text.slice(lastIndex, start);
      lastIndex = end;

      linksWithContext.push({
        displayText,
        url,
        originalMatch: fullMatch,
        position: cleanedText.length, // Track where this link starts in the cleaned text
      });

      // Add placeholder for the link in cleaned text
      cleanedText += `{LINK_PLACEHOLDER_${linksWithContext.length - 1}}`;
    }

    // Add remaining text after the last link
    cleanedText += text.slice(lastIndex);

    return { linksWithContext, cleanedText };
  };

  const replaceLinksInPlace = async (cleanedText, linksWithContext) => {
    const statusMap = {};

    // Verify links
    for (const { url } of linksWithContext) {
      try {
        const sanitizedUrl = url.trim();
        const response = await fetch(sanitizedUrl, { method: "GET" });

        statusMap[url] = response.ok ? "Valid" : "Invalid";
      } catch (error) {
        console.error(`Error fetching URL: ${url}`, error);
        statusMap[url] = "Invalid";
      }
    }

    // Replace placeholders with valid links or remove invalid ones
    let updatedText = cleanedText;
    linksWithContext.forEach(({ url, displayText, originalMatch }, index) => {
      const placeholder = `{LINK_PLACEHOLDER_${index}}`;
      if (statusMap[url] === "Valid") {
        const validLinkMarkdown = `[${displayText}](${url})`;
        updatedText = updatedText.replace(placeholder, validLinkMarkdown);
      } else {
        updatedText = updatedText.replace(placeholder, ""); // Remove the placeholder
      }
    });

    return updatedText;
  };

  // FUNCTION Generate Lesson PLan
  const handleSubmit = async (values) => {
    setActivitySelected(values?.activity);

    // if (values.subtopic === undefined || values.subtopic === null ){
    //   seterror("required sub topic ")
    //   return
    // }

    setisLoading(true);
    setProgressValue();
    const keywordsString = values.keywords.toString();
    const lessonAimsString = values.lesson_aims.toString();
    let activitiesString = values.activity.toString();

    const selectedActivities = activitiesString
      .split(",")
      .map((activity) => activity.trim());

    async function generateGapFillActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      return `1.	Create 10 unique gap-fill sentences based on the topic: ${
        courseDetail.topic
      }, subTopic ${
        values.subtopic
      }. Ensure that no two sentences share the same answer. Each sentence should have a unique answer.
              2.	Ensure all sentences are in the language associated with '${
                values.subtopic
              }'.
              3.	Randomize the order of sentences to maintain consistency with the lesson's linguistic focus.
              4.	Align with the selected international standard: ${
                courseDetail.curriculumStandard
                  ? courseDetail.curriculumStandard
                  : "N/A"
              }.
              5.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.universityLevel ||
                values.level ||
                values.university ||
                "N/A"} and age ${courseDetail.ageGroup || values.age}.
               In addition to the questions, create a lesson plan with structured content and examples that reference all question-answer use language and standerd above mention.Ensure that each questions answer is integrated into the lesson as a  reference point for comprehension, guiding learners to understand the core ideas.
               6. Generate 10 random keywords related to the topic and these keywords should be answers of genrated question but in random order for the activities to be generated ${
                 courseDetail.topic
               } and 
Formatting Guidelines
              Please Ensure that provide me output in valid json format with one is question and answer, only no need to extra text into it and flow the output formate stickly: 
              example output formate:
              {
            'Topic': ${courseDetail.topic},
            'subTopic' : ${values.subtopic},
                'LessonContent':
                  "Mia found her cat, Whiskers, hiding behind the couch. After pulling him out, she made her tea, which she always drank for breakfast. She sat by the window, enjoying the quiet morning with Whiskers curled up on her lap",
                "questions": ["The cat is hiding ______ the couch.", "She always drinks tea ______ breakfast.", ...],
                "answers": ["behind", "for", ...]
              }
          `;
    }

    async function leasonSaveToBackend(
      botReply,
      text,
      values,
      combinedResponsesArrayString,
      LastresponseData2
    ) {
      // const topic = text?.split('\n')[0].replace('#', '').trim();

      const topic = text
        .split("\n")[0]
        .replace("#", "")
        .replace("**", "")
        .trim();
      const aim = extractSection(text, "Aim");
      const introduction = extractSection(text, "Introduction");
      const AllActivities = extractSection(text, "Activities");
      const keywords = extractSection(text, "Keywords");

      try {
        const topicName = location.state?.topicName;
        const response = await axios.post(
          Const.BASE_URL + `add/${id}/lessonplan`,
          {
            lessonPlanId: `${id}${weekId}${topicId}`,
            topic: topicName,
            title: topic ?? "",
            aim: aim ?? "",
            introduction: introduction ?? "",
            keywords: keywords ?? "",
            content: botReply,
            questions: combinedResponsesArrayString,
            activities: values?.activity,
            reading_article: LastresponseData2,
          }
        );

        if (
          response &&
          response.data &&
          response.data.lesson_plan &&
          response?.data?.lesson_plan?.topic_id
        ) {
          setLeasonPlainId(response?.data?.lesson_plan?.topic_id);
        }
        if (
          response &&
          response.data &&
          response.data.lesson_plans[0] &&
          response?.data?.lesson_plans[0]?.id
        ) {
          setLeasonPlainId(response?.data?.lesson_plans[0]?.id);
        }

        if (response.status === 201) {
          toast.success(response?.data?.message);
        }
      } catch (error) {
        console.error("Error in save lesson in db: ", error.message);
      }
    }

    async function generateDefinitionMatchActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      return `1.	Develop 10 unique definition matching sentences based on the topic: ${
        courseDetail.topic
      }, subTopic ${values.subtopic}.
  2.	Ensure all sentences are in the language associated with '${
    values.subtopic
  }'.
  3.	Randomize the order of sentences.
  4.	Align with the selected international standard: ${
    courseDetail.standard ? courseDetail.standard : "N/A"
  }.
  5.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
    courseDetail.universityLevel ||
    "N/A"} and age ${courseDetail.ageGroup}. 
    6.    Ensure the gap-fill sentences should have following keywords as their answers in random order: ${keywordsString}
Formatting Guidelines  
  Please Ensure that provide me output in valid and correct json format with question: {"question", answer} only, no need to give extra text into it like suggesition and heading:
  example output formate: 
  {
      'Topic': ${courseDetail.topic},
      'subTopic' : ${values.subtopic},
     "DefinationMatchQuestions": [
      {
        "question": "A place where you can borrow books",
        "option" : ["House","School","Library","College"]
        "answer": "Library"
      },
      {
        "question": "A room in the house where food is prepared",
        "option" : ["House","Kitchen","Hotel","GusetHouse"]
        "answer": "Kitchen"
      },
      ...
    ]
  }    
  `;
    }

    async function generateQuizActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      return `1.	Create a quiz with 6 unique multiple-choice questions based on the topic: ${
        courseDetail.topic
      }, subTopic ${values.subtopic}.
  2.	Ensure all questions are in the language associated with '${
    values.subtopic
  }'.
  3.	Align with the selected international standard: ${
    courseDetail.standard ? courseDetail.standard : "N/A"
  }.
  4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
    courseDetail.universityLevel ||
    "N/A"} and age ${courseDetail.ageGroup}
     In addition to the questions, create a lesson plan with structured content and examples that reference all question-answer use language and standerd above mention.Ensure that each questions answer is integrated into the lesson as a  reference point for comprehension, guiding learners to understand the core ideas.
  Please Ensure that provide me output in valid json format with question: {"question" , "options", answer} and LessonContent only no need to extra text into it like suggesition and heading:
  example output formate: 
  {
      'LessonContent':[
    "In the lesson, the story unfolds with a powerful message and a lasting impression, evoking feelings of positivity and joy. This aligns with the word joyful, a synonym for "happy", as both express uplifting emotions. Similarly, just as the key events and figures in the story emerge quickly over time, the word glance—meaning to look quickly—metaphorically fits the way the narrative presents important moments in a brief, fleeting manner."
    ],
    "QuizActivityquestions": [
      {
        "question": "What is the synonym of "happy"?",
        "options": ["Sad", "Joyful", "Angry", "Tired"],
        "answer": "Joyful"
      },
      {
        "question": "Which word means "to look quickly"?",
        "options": ["Glance", "Stare", "Gaze", "Peek"],
        "answer": "Glance"
      },
      ...
    ]
  }
  `;
    }

    async function generateReadingActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      const wordCounts = {
        Beginner: 60,
        PreIntermediate: 100,
        Intermediate: 150,
        UpperIntermediate: 200,
        Advanced: 600,
      };
      const level =
        courseDetail.educationalLevel in wordCounts
          ? courseDetail.educationalLevel
          : values.level in wordCounts
          ? values.level
          : "PreIntermediate";
      let adjustedWordCount = wordCounts[level];
      if (
        countReadingWord &&
        countReadingWord >= 50 &&
        countReadingWord <= 500
      ) {
        adjustedWordCount = countReadingWord;
      } else {
        // Clamp adjustedWordCount between 50 and 500
        if (adjustedWordCount < 50) {
          adjustedWordCount = 50;
        } else if (adjustedWordCount > 500) {
          adjustedWordCount = 500;
        }
      }

      const response = `1. Create a reading article paragraph on the topic: ${
        courseDetail.topic
      }, subTopic ${values.subtopic}.
          2. Use the following keywords: ${
            keywordsString ? keywordsString : "generate appropriate keywords"
          }.
          3. Ensure it is suitable for ${courseDetail.educationalLevel ||
            courseDetail.universityLevel ||
            "N/A"} level and ${courseDetail.ageGroup || values.age} age.
          4. Word count: Generate reading artcial aprxox aproximate(LessonContent): ${adjustedWordCount} ± (10% or 10 words, whichever is lower) to maintain flexibility while staying close to the target word count please consider/count LessonContent words only.
          5. Align with the selected international standard: ${
            courseDetail.standard ? courseDetail.standard : "N/A"
          }.
          ${
            selectedActivities.length === 1
              ? `6. Generate three question based on the topic.
                In addition to the questions, create a lesson plan with structured content and examples that reference all question-answer use language and standerd above mention.Ensure that each questions answer is integrated into the lesson as a  reference point for reading article paragraph, guiding learners to understand the core ideas.
                Please Ensure that provide me output in valid json format with question: {"question" , "options", answer} and LessonContent only no need to extra text into it like suggesition and heading:
                example output formate: 
                {
                    'LessonContent':[
                  "In the lesson, the story unfolds with a powerful message and a lasting impression, evoking feelings of positivity and joy. This aligns with the word joyful, a synonym for "happy", as both express uplifting emotions. Similarly, just as the key events and figures in the story emerge quickly over time, the word glance—meaning to look quickly—metaphorically fits the way the narrative presents important moments in a brief, fleeting manner."
                  ],
                  "QuizActivityquestions": [
                    {
                      "question": "What is the synonym of "happy"?",
                      "options": ["Sad", "Joyful", "Angry", "Tired"],
                      "answer": "Joyful"
                    },
                    {
                      "question": "Which word means "to look quickly"?",
                      "options": ["Glance", "Stare", "Gaze", "Peek"],
                      "answer": "Glance"
                    },
                    ...
                  ]
                }


            7. Use language appropriate for ESL learners`
              : `
            6. Use language appropriate for ESL learners
            `
          }
          `;

      return response;
    }

    async function generateGistQuestionsActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      return `1.	Formulate 6 unique gist questions to assess learners' understanding of the article.
  2.	Ensure all questions are in the language associated with topic: ${
    courseDetail.topic
  }, subTopic ${values.subtopic}.'.
  3.	Align with the selected international standard: ${
    courseDetail.standard ? courseDetail.standard : "N/A"
  }.
  4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
    courseDetail.universityLevel ||
    "N/A"} and age ${courseDetail.ageGroup}.

   In addition to the questions, create a lesson plan with structured content and examples that reference all question-answer use language and standerd above mention.Ensure that each questions answer is integrated into the lesson as a  reference point for comprehension, guiding learners to understand the core ideas.

  Please Ensure that provide me output in valid json format with question: {"question", "answer"} and LessonContent only,.no need to give extra text into it like suggesition and heading:
  example output formate: 
  {
     'Topic': ${courseDetail.topic},
     'subTopic' : ${values.subtopic},
    'LessonContent':[
    "Once upon a time, there was a tale centered around a powerful message—the central theme or primary focus presented in the article. This story unfolded over time, with each event fitting into a specific timeline or period during which these important moments took place. Key figures emerged, each one influencing the story’s path, making it richer and deeper. Through every twist and turn, the author’s words helped bring this theme to life, creating a lasting impression on all who read it."
    ],
    "GistQuestions": [
      {
        "question": "What is the main idea of the article?",
        "answer": "The central theme or primary focus presented in the article."
      },
      {
        "question": "When did the events or developments in the article occur?",
        "answer": "The timeline or period during which the events took place."
      },
      ...
    ]
  }    
  `;
    }

    async function generateComprehensionQuestionsActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      // Function to generate comprehensive questions based on the topic
      function generateQuestions(
        topic,
        level,
        keywords,
        lessonAims,
        esl,
        age,
        standard
      ) {
        const question = `What is the significance of ${topic}?`;
        const answer = `The significance of ${topic} lies in its impact on ${lessonAims}, demonstrating its importance in ${standard ||
          "various contexts"}.`;
        return { question, answer };
      }
      const comprehensiveQuestions = [];
      // Generate 8 comprehensive questions
      for (let i = 0; i < 8; i++) {
        const question = generateQuestions(
          values.topic,
          values.level,
          keywordsString,
          lessonAimsString ? lessonAimsString : "generate appropriate aims",
          values.esl,
          values.age,
          values.standard ? `**Standard:** ${courseDetail.standard}` : ""
        );
        comprehensiveQuestions.push(question);
      }

      return `1.	Create 8 unique comprehension questions and thier answer based on the topic: ${
        courseDetail.topic
      }, subTopic ${values.subtopic}..
        2.	Ensure all questions are in the language associated with '${
          values.subtopic
        }'.
        3.	Align with the selected international standard: ${
          courseDetail.standard ? courseDetail.standard : "N/A"
        }.
        4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
          courseDetail.universityLevel ||
          "N/A"} and age ${courseDetail.ageGroup}.;
        In addition to the questions, create a lesson plan  with structured content and examples that reference all question-answer use language and standerd above mention.Ensure that each questions answer is integrated into the lesson as a  reference point for comprehension, guiding learners to understand the core ideas.
        Please Ensure that provide me valid output format in json with question: {"question", "answer"} and LessonContent only,.no need to give extra text into it like suggesition and heading:
  example output formate: 
  {
      'Topic': ${courseDetail.topic},
     'subTopic' : ${values.subtopic},
    'LessonContent':
    "Once upon a time, there was a tale centered around a powerful message—the central theme or primary focus presented in the article. This story unfolded over time, with each event fitting into a specific timeline or period during which these important moments took place. Key figures emerged, each one influencing the story’s path, making it richer and deeper. Through every twist and turn, the author’s words helped bring this theme to life, creating a lasting impression on all who read it."
    ,
    "Comprehension questions": [
      {
        "question": "What is the main idea of the article?",
        "answer": "The central theme or primary focus presented in the article."
      },
      {
        "question": "When did the events or developments in the article occur?",
        "answer": "The timeline or period during which the events took place."
      },
      ...
    ]
  }    
        `;
    }

    async function generateWritingActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      return `1.	Create 2 writing activities tailored to the learners' level based on the topic: ${
        courseDetail.topic
      }, subTopic ${values.subtopic}..
        2.	Ensure all activities are in the language associated with '${
          values.subtopic
        }'.
        3.	Align with the selected international standard: ${
          courseDetail.standard ? courseDetail.standard : "N/A"
        }.
        4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
          courseDetail.universityLevel ||
          "N/A"} and age ${courseDetail.ageGroup}.
        
        Please Ensure that provide me output in valid json format with activites: {"activityName", "instruction"} only, no need to give extra text into it like suggesition and heading:
        example output formate: 
        {
             'Topic': ${courseDetail.topic},
            'subTopic' : ${values.subtopic},
          "activites": [
            {
              "activityName": "Describe Your Favorite Place",
              "instruction": ["Write a paragraph describing your favorite place.", "Include details about what you see, hear, smell, and feel when you are there."]
            },
            {
              "activityName": "Write About a Memorable Experience",
              "instruction": ["Write a short story about a memorable experience in your life.", "Make sure to include who was involved, what happened, where it took place, and how you felt during and after the event."]
            },
          ]
        }
    `;
    }

    async function generateGamesActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      return `Creating 4-5 Scrumble words, based on the topic: ${
        courseDetail.topic
      }, subTopic ${
        values.subtopic
      }.All scramble word must be realted to the topic.
      
       for ${courseDetail.educationalLevel} level, ${
        keywordsString
          ? " following key words " + keywordsString
          : " following the generated keywords "
      } ${
        lessonAimsString
          ? " and following aim " + lessonAimsString
          : " and following the generated aim "
      } ${
        values.esl
          ? `Using language appropriate for ESL learners, and ensure it matches the academic level ${courseDetail.educationalLevel} and age  ${courseDetail.ageGroup} .\n`
          : ""
      }\n
         Provide me answer also for all Scramble Word
          Please Ensure that provide me output in valid json format with activites: {"ScrambleWord", "answer"} only, no need to give extra text into it like suggesition and heading:
        example output formate: 
        {
           'Topic': ${courseDetail.topic},
          'subTopic' : ${values.subtopic},
          "activites": [
            {
              "ScrambleWord": ["M","N","I","C","E","A"],
              "answer": ["MACHINE"]
            },
            {
              "activityName": "Write About a Memorable Experience",
              "instruction": ["Write a short story about a memorable experience in your life.", "Make sure to include who was involved, what happened, where it took place, and how you felt during and after the event."]
            },
          ]
        }
        `;
    }

    async function generateDiscussionDebateActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      return `
          1.	Formulate 8 unique discussion/debate questions to extend learning beyond the lesson.
          2.	Ensure all questions are in the language associated with topic: ${
            courseDetail.topic
          }, subTopic ${values.subtopic}.'
          3.	Align with the selected international standard: ${
            courseDetail.standard ? courseDetail.standard : "N/A"
          }.
          4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
            courseDetail.universityLevel ||
            "N/A"} and age ${courseDetail.ageGroup}
            
          Please Ensure that provide me  output in valid json format with question: {"discussionDebateTopics"} only, no need to give extra text into it like suggesition and heading:
          example output formate: 
          {
             'Topic': ${courseDetail.topic},
            'subTopic' : ${values.subtopic},
            "discussionDebateTopics": [
              "What are the benefits of learning a new language at an older age?",
              "How does technology influence our daily lives positively and negatively?",
              "Should governments invest more in renewable energy sources? Why or why not?",
              . . .
            ]
          }
        `;
    }

    async function generateCaseStudiesActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      const wordCounts = {
        Beginner: 60,
        PreIntermediate: 100,
        Intermediate: 150,
        UpperIntermediate: 200,
        Advanced: 600,
      };
      const level =
        courseDetail.educationalLevel in wordCounts
          ? courseDetail.educationalLevel
          : values.level in wordCounts
          ? values.level
          : "PreIntermediate";
      let adjustedWordCount = wordCounts[level];
      if (adjustedWordCount < 50) {
        adjustedWordCount = 50;
      } else if (adjustedWordCount > 500) {
        adjustedWordCount = 500;
      }

      return `1. Develop a case study of at least ${adjustedWordCount} words based on the topic: ${
        courseDetail.topic
      }, subTopic ${values.subtopic}.
        2. Ensure the case study is in the language associated with '${
          values.subtopic
        }'.
        3. Align with the selected international standard: ${
          courseDetail.standard ? courseDetail.standard : "N/A"
        }.
        4. Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
          courseDetail.universityLevel ||
          "N/A"} and age ${courseDetail.age}`;
    }

    async function generateRealWorldProjectsActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      return `1.Create a step-by-step guide for 2 real-world projects related to the topic: ${
        courseDetail.topic
      }, subTopic ${values.subtopic}.
  2.	Ensure all instructions are in the language associated with '${
    values.subtopic
  }'.
  3.	Align with the selected international standard: ${
    courseDetail.standard ? courseDetail.standard : "N/A"
  }.
  4.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
    courseDetail.universityLevel ||
    "N/A"} and age ${courseDetail.ageGroup}
  `;
    }
    async function generateRolePlaysActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      return `1.	Develop 4 unique Role-plays activities topic.
        2.	Ensure all role plays are in the language associated with topic: ${
          courseDetail.topic
        }, subTopic ${values.subtopic}.'.
        3.	Each activity must involve 2 participants or objects.
        4.	Align with the selected international standard: ${
          courseDetail.standard ? courseDetail.standard : "N/A"
        }.
        5.	Use language appropriate for ESL learners, matching the academic level ${courseDetail.educationalLevel ||
          courseDetail.universityLevel ||
          "N/A"} and age ${courseDetail.ageGroup}.
  
      Please Ensure that provide me output in valid json format with rolePay: {"topics", "participants"} only, no need to give extra text into it like suggesition and heading:
          example output formate: 
          {
             'Topic': ${courseDetail.topic},
            'subTopic' : ${values.subtopic},
            "RolePlayTopics": [
              {
                "topic": "Ordering Food at a Restaurant",
                "partipants: ["Customer", "Waiter"]
              },
              {
                "topic": "Booking a Hotel Room",
                "partipants: ["Guest", "Receptionist"]
              },
              . . .
            ]
          }
  "`;
      // Example:

      // Role Play 1: Finding a Product
      // •	Characters: Shopper (Student 1) and Store Assistant (Student 2)
      // •	Shopper: "Excuse me, could you help me find the cereal aisle?"
      // •	Store Assistant: "Sure, the cereal is in aisle 5, next to the breakfast foods."
      // •	Shopper: "Thank you. Do you carry gluten-free options as well?"
      // •	Store Assistant: "Yes, we have a variety of gluten-free cereals. They're clearly labeled on the lower shelf.
    }

    async function generateHomeworkActivity(
      values,
      keywordsString,
      lessonAimsString
    ) {
      return `1.	Design a homework task to reinforce learning based on the topic: ${
        courseDetail.topic
      }, subTopic ${values.subtopic}.
  2.	Ensure the task is in the language associated with '${values.subtopic}'.
  3.	Align with the selected international standard: ${
    courseDetail.standard ? courseDetail.standard : "N/A"
  }.
  4.	Use language appropriate for ESL learners, matching the academic level ${values.educationalLevel ||
    courseDetail.universityLevel ||
    "N/A"} and age ${courseDetail.ageGroup}`;
    }

    async function generateActivityQuery(
      activity,
      values,
      FirstresponseData,
      keywordsString,
      lessonAimsString,
      isLast,
      i
    ) {
      let activityQuery = `
            As part of your learning experience, I will mandatorily show only the ${activity} name in bold style at the beginning, and add some space above ${activity} name. 
          - I will cover the skills of ${values.skill}.\n
          - I must not show any notes.
          - I must not add introduction of the topic.
        
          - I will show the activity in the same language that is used by topic: ${
            courseDetail.topic
          }, subTopic ${values.subtopic}.
          ${
            universityDescriptions[
              courseDetail.universityLevel
                ? courseDetail.universityLevel
                : values.university
            ]
              ? `- ${
                  universityDescriptions[
                    courseDetail.universityLevel
                      ? courseDetail.universityLevel
                      : values.university
                  ]
                }\n`
              : ""
          }
          ${
            standardDescriptions[values.standard]
              ? `- ${standardDescriptions[values.standard]}\n`
              : ""
          }
          - ${
            values.esl
              ? `I will use language appropriate for ESL learners, and ensure it matches the academic level ${courseDetail.educationalLevel} and age ${courseDetail.ageGroup}.\n`
              : "I will make sure the language must be according to age and level, like for beginners easy and simple English and increase the level according to their age and level."
          }
      Formatting Guidelines
  •	Main Headers: Use #  .
  •	Subheaders: Use ##  .
  •	Bold: Use **bold** for all headings and subheadings.
  •	Lists: Use bullet points for lists and numbered lists where appropriate.
  •	Spacing: Ensure clear spacing between sections for readability.
  •	Notes: Do not add any notes or topic introductions.
  •	Add a seperate ----- at the end of response.
        - I must: `;

      // Add activity-specific details
      if (activity == "Gap Fill") {
        activityQuery += await generateGapFillActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity === "Definition Match") {
        activityQuery += await generateDefinitionMatchActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity == "Quiz") {
        activityQuery += await generateQuizActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity == "Reading") {
        activityQuery += await generateReadingActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity == "Gist Questions") {
        activityQuery += await generateGistQuestionsActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity == "Comprehension Questions") {
        activityQuery += await generateComprehensionQuestionsActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity == "Writing") {
        activityQuery += await generateWritingActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity == "Games") {
        activityQuery += await generateGamesActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity == "Discussion/Debate") {
        activityQuery += await generateDiscussionDebateActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity == "Case Studies") {
        activityQuery += await generateCaseStudiesActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity == "Real World Projects") {
        activityQuery += await generateRealWorldProjectsActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity == "Role-Plays") {
        activityQuery += await generateRolePlaysActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      } else if (activity == "HomeWork") {
        activityQuery += await generateHomeworkActivity(
          values,
          keywordsString,
          lessonAimsString
        );
      }

      activityQuery += `
        \n\n`;
      return { activityQuery, "activity-type": activity };
    }

    async function generateFirstQuery(values) {
      let activityQuery = `As part of your learning experience, I will create a comprehensive lesson on ${
        courseDetail?.courseTitle
      }  of ${courseDetail.topic}
        entirely in the language of the topic: ${
          courseDetail.topic
        }, subTopic ${values.subtopic}.
           This lesson will cover:
  - Clearly stating the
  ${courseDetail.courseTitle ? `${courseDetail.courseTitle},` : ""} 
  ${courseDetail.ageGroup ? `age ${courseDetail.ageGroup}, ` : ""} 
  ${
    courseDetail.standard
      ? `international standard (${courseDetail.standard}), `
      : ""
  } 
  ${
    courseDetail.universityLevel
      ? `University (${courseDetail.universityLevel}), `
      : ""
  } 
  ${
    courseDetail.educationalLevel
      ? `and level ${courseDetail.educationalLevel}, `
      : ""
  }
   
  of our lesson at the beginning all above must.
    
  - Including a clear aim to guide our learning.
          suitable for the age group of ${
            courseDetail.ageGroup
          } and academic level ${courseDetail.educationalLevel}.
        - Generate 10 keywords (in random order) related to the topic ${
          values.subtopic
        }.
        - **Note - The generated response must only contain keywords , aim and in the beginning title ${
          values.subtopic
        }, age ${courseDetail.ageGroup} and level ${
        courseDetail.educationalLevel
      }.
        Formatting Guidelines
  •	Main Headers: Use #  .
  •	Subheaders: Use ##  .
  •	Bold: Use **bold** for all headings and subheadings.
  •	Lists: Use bullet points for lists and numbered lists where appropriate.
  •	Spacing: Ensure clear spacing between sections for readability.
  •	Notes: Do not add any notes or topic introductions.
  \n\n\n\n`;

      return activityQuery;
    }

    // async function generateLastQuery(values, combinedResponses) {
    //   let activityQuery = `
    //     1. Provide answers to all questions and exercises  clearly  and make sure no questions(question should present with answer) are left unanswered from  this ${combinedResponses} but extract questions from this for answering, not creating new ones,
    //     2. Ensure answers are in the language associated with topic: ${courseDetail.topic},subTopic ${values.subtopic}.
    //     3. Do not repeat any questions; just provide the answers.
    //     4. Organize the content into distinct sections based on the following categories, if present in the question list. If certain sections are not applicable, leave them out:
    //           Gap Fill
    //           Definition Match
    //           Reading
    //           Quiz
    //           Gist Question
    //           Comprehensive Question
    //           Game
    //           Case Studies
    //           For each section, ensure:

    //           It is clearly labeled with its respective heading.
    //           Questions or content within each section are relevant to the heading.
    //           The layout is neat, logical, and easy to follow.
    //     5. Remove the heading for the main section to streamline the content presentation and improve clarity and use bold charter only for heading.
    //     6. Ensure response should be in markdown format.
        
    //   Summary 
    //   - Summarize the key points of the lesson on '${values.subtopic}' in 50 words or less.

    //     Reflection on Lesson Plan
    //     • Summarize the key points taught in the lesson.
    //     • Provide a few bullet points on the reflection of the lesson plan and what has been taught.
    //     • Ensure the reflection is in the language associated with '${values.subtopic}'.
        
    //     Multimedia References
    //     • Provide a maximum of 2-3 age-appropriate multimedia valid and working references (websites, videos, and audio resources) related to the topic '${values.subtopic}'.
    //     • Ensure all references are in the language associated with '${values.subtopic}' and suitable for the target age group.
    //     • Verify the functionality of each link to ensure it leads to active content.
    //     • Exclude any links that result in a "404 Not Found" error 
      
    //     Formatting Guidelines
    //     • Main Headers: Use # .
    //     • Subheaders: Use ## .
    //     • Bold: Use **bold** for all headings and subheadings.
    //     • Lists: Use bullet points for lists and numbered lists where appropriate.
    //     • Spacing: Ensure clear spacing between sections for readability.
    //     • Notes: Do not add any notes or topic introductions.
        
    //     \n\n\n\n\n`;
    //   return activityQuery;
    // }

    async function generateLastQuery(values, combinedResponses) {
      let activityQuery = `
        Multimedia References
          Provide a maximum of 2-3 age-appropriate multimedia valid and working references (websites, videos, and audio resources) related to the topic '${values.subtopic}'.
          Ensure all references are in the language associated with '${values.subtopic}' and suitable for the target age group.
          Verify the functionality of each link to ensure it leads to active content.
          Exclude any links that result in a "404 Not Found" error.
          give also title as Multimedia References and use bold for heading.


          Formatting Guidelines
            • Main Headers: Use # .
            • Subheaders: Use ## .
            • Bold: Use **bold** for all headings and subheadings.
            • Lists: Use bullet points for lists and numbered lists where appropriate.
            • Spacing: Ensure clear spacing between sections for readability.
            • Notes: Do not add any notes or topic introductions.
        
        \n\n\n\n\n`;
      return activityQuery;
    }

    async function generateLastQuery2(values, combinedResponses) {
      let activityQuery = `
      Write a concise reading article (aproximate 200 words) related to the topic ${courseDetail.topic} and subtopic ${values.subtopic}.

        Guidelines:

        The article must implicitly address all the questions and exercises provided in ${combinedResponses} without directly stating the questions.
        Ensure the article is written in the language associated with ${courseDetail.topic} and ${values.subtopic}.
        Organize the content logically, making it easy for readers to extract answers.
        Use age-appropriate language and maintain a tone suitable for the target audience.
        Avoid explicitly numbering questions or giving direct answers; instead, integrate the content seamlessly into the article.
        Keep the length under 200 words, ensuring clarity and relevance and give heading to the artical relatable in bold.
        return in markdown formate please
        Formatting Guidelines:

        Use bold for section titles if needed.
        Ensure proper paragraph spacing for readability.
        Avoid including notes, introductions, or explanations outside the article's main content.
      
      \n\n\n\n\n`;
      return activityQuery;
    }
    try {
      const firstQuery = await generateFirstQuery(values);

      const Firstresponse = await axios.post(
        "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
        {
          messages: [
            {
              role: "system",
              content: firstQuery,
            },
          ],
          max_tokens: 2000,
          temperature: 0.5,
          frequency_penalty: 0,
          presence_penalty: 2.0,
          stop: null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": "7327443ae85e44419192e4fb0544d702",
          },
        }
      );

      const word_token = Firstresponse.data.usage.total_tokens;
      await updateToken(authUser, word_token);
      const FirstresponseData = Firstresponse.data.choices[0].message.content;

      let combinedResponses = "";
      let combinedResponsesSet = new Set();
      let combinedResponsesArray = [];
      let combinedResponsesArrayString = "";

      // let combinedResponsesMap = [];

      const activityQueries = [];
      const activityResponses = [];

      for (let i = 0; i < selectedActivities.length; i++) {
        const activity = selectedActivities[i];
        const isLast = i === selectedActivities.length - 1;
        const activityQuery = await generateActivityQuery(
          activity,
          values,
          FirstresponseData,
          keywordsString,
          lessonAimsString,
          isLast,
          i
        );
        activityQueries.push(activityQuery);
      }

      for (let i = 0; i < activityQueries.length; i++) {
        const response = await axios.post(
          "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
          {
            messages: [
              {
                role: "system",
                content: activityQueries[i].activityQuery,
              },
            ],
            max_tokens: 3000,
            temperature: 0.5,
            frequency_penalty: 0,
            presence_penalty: 2.0,
            stop: null,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": "7327443ae85e44419192e4fb0544d702",
            },
          }
        );
        const word_token = response.data.usage.total_tokens;
        await updateToken(authUser, word_token);
        const responseContent = response.data.choices[0].message.content;
        const activityType = activityQueries[i]["activity-type"];

        combinedResponsesArray.push({
          [activityType]: responseContent,
        });

        // const activityResponse = {
        //   [activityQueries[i]['activity-type']]: response.data.choices[0].message.content
        // };

        // Push the object into the combinedResponses array
        // combinedResponsesMap.push(activityResponse);

        activityResponses.push(response.data.choices[0].message.content);
        combinedResponsesSet.add(response.data.choices[0].message.content);

        // Use activity-type as key and response content as value
        // combinedResponsesMap.set(activityQueries[i]['activity-type'], response.data.choices[0].message.content);
      }

      combinedResponses = [...combinedResponsesSet].join("<br/><br/>");

      combinedResponsesArrayString = combinedResponsesArray;

      // If you need to convert combinedResponsesMap to a specific format, you can do it here
      // combinedResponses = Array.from(combinedResponsesMap, ([key, value]) => `${key} -  ${value}`).join("<br/><br/>");

      const LastQuery2 = await generateLastQuery2(values, combinedResponses);

      const Lastresponse2 = await axios.post(
        "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
        {
          messages: [
            {
              role: "system",
              content: LastQuery2,
            },
          ],
          temperature: 0.5,
          frequency_penalty: 0,
          presence_penalty: 2.0,
          stop: null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": "7327443ae85e44419192e4fb0544d702",
          },
        }
      );
      const word_token2 = Lastresponse2.data.usage.total_tokens;
      await updateToken(authUser, word_token2);
      const LastresponseData2 = Lastresponse2.data.choices[0].message.content;

      const LastQuery = await generateLastQuery(values, combinedResponses);

      const Lastresponse = await axios.post(
        "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
        {
          messages: [
            {
              role: "system",
              content: LastQuery,
            },
          ],
          temperature: 0.5,
          frequency_penalty: 0,
          presence_penalty: 2.0,
          stop: null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": "7327443ae85e44419192e4fb0544d702",
          },
        }
      );

      const word_token_last = Lastresponse.data.usage.total_tokens;
      await updateToken(authUser, word_token_last);
      const LastresponseData = Lastresponse.data.choices[0].message.content;

      // Combine all responses with the LastresponseData at the end

      const allText =
        FirstresponseData +
        "\n\n\n\n" +
        LastresponseData2 +
        "\n\n\n\n" +
        LastresponseData;

      // Await the formatChatGPTResponse function here
      const botReply = await formatChatGPTResponse(allText);
      const Questions = await formatChatGPTResponseQuest(combinedResponses);

      //save to backend
      await leasonSaveToBackend(
        botReply,
        Firstresponse.data.choices[0].message.content,
        values,
        combinedResponsesArrayString,
        LastresponseData2
      );

      setLessonPlanResponse(botReply);
      setPdfData(allText);
      handleNext();
    } catch (error) {
      console.error("Error communicating with OpenAI:", error);
    } finally {
      setisLoading(false);
    }
  };

  // FUNCTION to convert newLine lags to break tags
  const validateLink = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        // Check for common 404 messages in the response data

        const invalidMessages = [
          "The requested URL was not found on this server.",
          "404 Not Found",
          "Page not found",
          "We couldn't find the page you were looking for",
        ];
        for (const message of invalidMessages) {
          if (response.data.includes(message)) {
            return false;
          }
        }
        return true;
      }
    } catch (error) {
      // Handle errors such as network issues, server errors, etc.
      return false;
    }
  };

  const formatChatGPTResponse = (response) => {
    // Preserve the original language of the response
    let formattedResponse = response;
    // Replace Markdown-style headers with HTML headers
    formattedResponse = formattedResponse.replace(
      /^## (.*?)$/gm,
      "<h3>$1</h3>"
    );
    formattedResponse = formattedResponse.replace(/^# (.*?)$/gm, "<h2>$1</h2>");
    formattedResponse = formattedResponse.replace(
      /^### (.*?)$/gm,
      "<h2>$1</h2>"
    );
    // Replace lists (lines starting with '-') to list items
    formattedResponse = formattedResponse.replace(/^- (.*?)$/gm, "<li>$1</li>");
    // Replace newLine tags with <br> for line breaks
    formattedResponse = formattedResponse.replace(/\n/g, "<br>");

    // Replace https links with clickable <a> tags
    formattedResponse = formattedResponse.replace(
      /(https?:\/\/\S+)/gi,
      '<a href="$1" target="_blank">$1</a>'
    );
    // Remove any remaining HTML tags and stars (*)
    // formattedResponse = formattedResponse.replace(/<\/?[^>]+(>|$)/g, "");
    formattedResponse = formattedResponse.replace(/\*/g, "");
    // formattedResponse = `<div lang="${language}">${formattedResponse}</div>`;
    return formattedResponse;
  };

  const formatChatGPTResponseQuest = (response) => {
    // Preserve the original language of the response
    let formattedResponse = response;
    // Replace Markdown-style headers with HTML headers
    formattedResponse = formattedResponse.replace(
      /^## (.*?)$/gm,
      "<h3>$1</h3>"
    );
    formattedResponse = formattedResponse.replace(/^# (.*?)$/gm, "<h2>$1</h2>");
    formattedResponse = formattedResponse.replace(
      /^### (.*?)$/gm,
      "<h2>$1</h2>"
    );
    // Replace lists (lines starting with '-') to list items
    formattedResponse = formattedResponse.replace(/^- (.*?)$/gm, "<li>$1</li>");
    // Replace newLine tags with <br> for line breaks
    formattedResponse = formattedResponse.replace(/\n/g, "<br>");
    // Replace https links with clickable <a> tags
    formattedResponse = formattedResponse.replace(
      /(https?:\/\/\S+)/gi,
      '<a href="$1" target="_blank">$1</a>'
    );
    // Remove any remaining HTML tags and stars (*)
    // formattedResponse = formattedResponse.replace(/<\/?[^>]+(>|$)/g, "");
    formattedResponse = formattedResponse.replace(/\*/g, "");
    // formattedResponse = `<div lang="${language}">${formattedResponse}</div>`;
    return formattedResponse;
  };

  //................................Correctly working from line 1001 to 1114 with bullets & Numbers till 17 jul.................................

  const parseAndSplitContent = (content, maxLength = 500) => {
    const sections = content
      .split(/(?=<h[1-3]>)/)
      .map((section) => section.trim())
      .filter((section) => section.length > 0);
    const splitSections = [];

    sections.forEach((section) => {
      if (section.length > maxLength) {
        const parts = section.match(
          new RegExp(`.{1,${maxLength}}(\\s|\\.|,|;|\\?)`, "g")
        );
        splitSections.push(...parts);
      } else {
        splitSections.push(section);
      }
    });
    return splitSections;
  };

  const convertHtmlToRichText = (html) => {
    const richText = [];
    const div = document.createElement("div");
    div.innerHTML = html;

    div.childNodes.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const tagName = node.tagName.toLowerCase();
        const text = node.innerText;

        switch (tagName) {
          case "h1":
            richText.push({ text, options: { fontSize: 32, bold: true } });
            break;
          case "h2":
            richText.push({ text, options: { fontSize: 28, bold: true } });
            break;
          case "h3":
            richText.push({ text, options: { fontSize: 24, bold: true } });
            break;
          case "li":
            richText.push({ text, options: { bullet: true, fontSize: 18 } });
            break;
          case "ol":
            // Handle ordered lists (numbered items)
            node.childNodes.forEach((itemNode, index) => {
              if (
                itemNode.nodeType === Node.ELEMENT_NODE &&
                itemNode.tagName.toLowerCase() === "li"
              ) {
                richText.push({
                  text: `${index + 1}. ${itemNode.innerText}`,
                  options: { fontSize: 18 },
                });
              }
            });
            break;
          case "br":
            richText.push({ text: "\n", options: { fontSize: 18 } });
            break;
          default:
            richText.push({ text, options: { fontSize: 18 } });
            break;
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent.trim();
        if (textContent.length > 0) {
          richText.push({ text: textContent, options: { fontSize: 18 } });
        }
      }
    });
    return richText;
  };

  const generatePPTX = () => {
    const pptx = new PptxGenJS();
    const slideWidthInches = 10;
    const slideHeightInches = 7.5;
    const maxFontSize = 24;
    const minFontSize = 10;
    const maxLinesPerSlide = 15;

    // Set slide dimensions and master slide to ensure no default margins
    pptx.defineSlideMaster({
      title: "Master Slide",
      width: slideWidthInches,
      height: slideHeightInches,
      margin: { top: 0, left: 0, right: 0, bottom: 0 }, // Ensure no default margins
    });

    let slide = pptx.addSlide();
    let currentText = "";
    let lineCount = 0;

    const finalizeSlide = () => {
      if (currentText.trim().length > 0) {
        slide.addText(currentText, {
          x: 0, // Start at the very left of the slide
          y: 0, // Start at the very top of the slide
          w: slideWidthInches, // Full width of the slide
          h: slideHeightInches, // Full height of the slide
          align: "left",
          valign: "top", // Vertical alignment at the top
          fontSize: Math.max(minFontSize, maxFontSize - lineCount * 1.5),
          margin: { left: 0, top: 0 }, // Ensure no extra margins
        });
        currentText = "";
        lineCount = 0;
        slide = pptx.addSlide(); // Add a new slide
      }
    };

    const sections = parseAndSplitContent(lessonPlanResponse);

    sections.forEach((section, index) => {
      const richText = convertHtmlToRichText(section);

      richText.forEach((textObj) => {
        const text = textObj.text;
        const lines = text.split("\n");
        const options = textObj.options || {};

        lines.forEach((line) => {
          const trimmedLine = line.trim();
          if (trimmedLine.length === 0 || trimmedLine === "---") {
            return; // Skip empty lines or "---"
          }

          if (trimmedLine.startsWith("#") && currentText.trim().length > 0) {
            // Start a new slide for each heading if the current slide has content
            finalizeSlide();
          }

          if (
            lineCount >= maxLinesPerSlide ||
            currentText.length + line.length > 1000
          ) {
            finalizeSlide();
          }

          // Add bullet or number based on options
          if (options.bullet) {
            currentText += `• ${trimmedLine}\n`;
          } else {
            currentText += trimmedLine + "\n";
          }
          lineCount++;
        });
      });
    });
    finalizeSlide(); // Ensure the last slide is finalized
    pptx.writeFile({ fileName: "CourseLessonPlan.pptx" });
  };

  // FUNCTION to copy text to clipBoard

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(pdfData)
      .then(() => {
        setIsCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  // FUNCTION Goto next step in Stepper
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // FUNCTION Set Progress
  const setProgressValue = () => {
    setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);
  };

  const { t, i18n } = useTranslation();
  const correctPlan = async () => {
    setModalLoading(true);
    const response = await axios.post(
      "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
      {
        messages: [
          {
            role: "system",
            content: `You are about to make changes to the lesson plan. Here is the original lesson plan: ${lessonPlanResponse}`,
          },
          {
            role: "user",
            content: `${correction} \nDon't alter the structure of lesson plan and make changes in that existing structure. Please write the corrected lesson plan after the word *****.`,
          },
        ],
        // max_tokens: 650,
        temperature: 0.5,
        frequency_penalty: 0,
        presence_penalty: 2.0,
        stop: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": "7327443ae85e44419192e4fb0544d702",
        },
      }
    );

    const word_token = response.data.usage.total_tokens;
    await updateToken(authUser, word_token);
    setLessonPlanResponse(
      response.data.choices[0].message.content.split("*****")[1]
    );
    setModalLoading(false);
    setCorrection("");
    setOpenModal(false);
  };

  const handleClickActivity = (activity) => {
    setSelectedActivities((prevSelected) =>
      prevSelected.includes(activity)
        ? prevSelected.filter((item) => item !== activity)
        : [...prevSelected, activity]
    );
    setActivitySelect(!activitySelect);
  };

  const handleReadingInput = (e) => {
    setCountReadingWord(e.target.value);
  };

  return (
    <>
      <Box className={classes.Container}>
        <div className="page_outer course_lesson">
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Button
              style={{ margin: "10px" }}
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                history.goBack();
              }}
            >
              <span className="MuiButton-label">{t("Back")}</span>
            </Button>
          </Box>
          <div className="donoo">
            <div className="inner_box">
              {content === "" && (
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                      <Step
                        key={label}
                        {...stepProps}
                        onClick={() => {
                          setActiveStep(0);
                          setLessonPlanResponse("");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <StepLabel {...labelProps}>{t(label)}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              )}
            </div>

            <div className="inner_box">
              <div className={classes.formikWrapper}>
                {lessonPlanResponse == "" ? (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form style={{ fontWeight: "bold" }}>
                        <Grid container spacing={5}>
                          {/* <Grid item xs={6} sm={6} md={6} lg={5}>
                      <Field name="topic">
                        {({ field, meta }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label={t("topic")}
                            variant="outlined"
                            error={meta.touched && meta.error !== undefined}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid> */}
                          <Grid item xs={10}>
                            {/* university */}
                            <Field name="subtopic">
                              {({ field, meta }) => (
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  error={meta.touched && Boolean(meta.error)}
                                >
                                  <InputLabel>{t("subtopic")}</InputLabel>
                                  <Select
                                    className={classes.select}
                                    {...field}
                                    label={t("subtopic")}
                                    error={
                                      meta.touched && meta.error !== undefined
                                    }
                                    helperText={meta.touched && meta.error}
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {courseDetail?.detailedCourseBreakdown?.weeks[
                                      weekId
                                    ].hours[topicId].subtopics.map(
                                      (val, index) => (
                                        <MenuItem
                                          key={index}
                                          value={val.subtopic}
                                        >
                                          {val.subtopic}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                  {meta.error && (
                                    <FormHelperText>
                                      {meta.error}
                                    </FormHelperText>
                                  )}
                                  {/* {error && <p color='red'>{error}</p>} */}
                                  {selectedActivities.includes("Reading") && (
                                    <FormControl>
                                      <InputLabel>{t("Count_Word")}</InputLabel>
                                      <Input
                                        value={countReadingWord}
                                        type="number"
                                        onChange={(e) => {
                                          handleReadingInput(e);
                                        }}
                                      />
                                    </FormControl>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field name="activity">
                              {({ field, meta, form }) => (
                                <FormControl variant="outlined" fullWidth>
                                  <div className="top_heading">
                                    <h2>
                                      <span>
                                        {t("interactive_activity_labels")}
                                      </span>
                                    </h2>
                                  </div>

                                  <FormGroup>
                                    <Grid
                                      className="grid_list"
                                      container
                                      spacing={2}
                                    >
                                      {activities.map((activity) => (
                                        <Grid
                                          className="check_box_outer"
                                          item
                                          xs={6}
                                          key={activity}
                                        >
                                          <FormControlLabel
                                            onClick={() =>
                                              handleClickActivity(activity)
                                            }
                                            className={
                                              selectedActivities.includes(
                                                activity
                                              )
                                                ? "check_box_from select"
                                                : "check_box_from"
                                            }
                                            key={activity}
                                            control={
                                              <Checkbox
                                                className="check_btn"
                                                checked={field.value.includes(
                                                  activity
                                                )}
                                                onChange={() => {
                                                  const newSelected = field.value.includes(
                                                    activity
                                                  )
                                                    ? field.value.filter(
                                                        (val) =>
                                                          val !== activity
                                                      )
                                                    : [
                                                        ...field.value,
                                                        activity,
                                                      ];
                                                  form.setFieldValue(
                                                    field.name,
                                                    newSelected
                                                  );
                                                }}
                                                name={field.name}
                                              />
                                            }
                                            label={
                                              <div
                                                className="check_box_list "
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {t(activity)}
                                                {/* {field.value.includes(activity) && (
                                            <Chip label={t(activity)} className={classes.chip} style={{ marginLeft: 8 }} />
                                          )} */}
                                              </div>
                                            }
                                          />
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </FormGroup>
                                </FormControl>
                              )}
                            </Field>
                          </Grid>
                        </Grid>
                        {/* Submit Button */}
                        <div
                          className="question_btn_list"
                          style={{ width: "80%", marginTop: 10 }}
                        >
                          {isLoading ? (
                            <>
                              <Box sx={{ width: "100%" }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={progress}
                                />
                              </Box>
                              <br />
                              <Button
                                color="secondary"
                                variant="contained"
                                onClick={handleCancel}
                              >
                                {t("cancel")}
                              </Button>
                            </>
                          ) : (
                            <div className="btn_wrp">
                              <div className={classes.ButtonGroup}>
                                <Button
                                  className="custom_btn lesson_btn"
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => history.goBack()}
                                >
                                  {t("cancel")}
                                </Button>
                                <Button
                                  className="custom_btn lesson_btn"
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                >
                                  {t("generate_plan")}
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <div className={classes.WrapperResponse}>
                    <p
                      style={{
                        textAlign: "left !important",
                        fontFamily: "Calibri, Arial, sans-serif",
                      }}
                      id="content"
                      dangerouslySetInnerHTML={{ __html: lessonPlanResponse }}
                      // dangerouslySetInnerHTML={{ __html: updatedLessonPlanResponse }}
                    ></p>
                    <div className={classes.ButtonGroup}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setOpenModal(true)}
                      >
                        {" "}
                        {t("correct")}
                      </Button>

                      <Button
                        color="primary"
                        variant="contained"
                        onClick={copyToClipboard}
                      >
                        {isCopied ? "Copied" : <ContentCopyIcon />}
                      </Button>
                      {/* <Button
                  color="primary"
                  variant="contained"
                  onClick={handleOpen}
                >
                  {t("edit_lesson_plan")}
                </Button> */}

                      <Button
                        color="primary"
                        variant="contained"
                        onClick={generatePPTX}
                      >
                        {t("generate_pptx")}
                      </Button>

                      <div className={classes.popUpParent}>
                        {/* show popUp of activites list */}
                        {isShowActivityList && (
                          <div className={classes.activityPopUp}>
                            <ActivityListPopUp
                              activities={activitySelected.map((item) => {
                                return {
                                  activity_name: item,
                                  status: "pending",
                                };
                              })}
                              lessonId={leasonPlainId}
                              id={id}
                              weekId={weekId}
                              topicId={topicId}
                            />
                          </div>
                        )}
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            setIsShowActivityList(!isShowActivityList)
                          }
                        >
                          {t("start_activity")}
                        </Button>
                      </div>

                      {content === "" && (
                        <>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setActiveStep(0);
                              setLessonPlanResponse("");
                            }}
                          >
                            {t("generate_new_lesson_plan")}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyles}>
            {modalLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {t("correct_lesson_plan")}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  label={t("description")}
                  variant="outlined"
                  className={classes.input}
                  style={{ marginTop: 15 }}
                  value={correction}
                  onChange={(e) => {
                    if (e.target.value.trim()) {
                      setCorrection(e.target.value);
                    }
                  }}
                />
                <div className="d-flex justify-content-end mt-4 align-items-center">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setOpenModal(false)}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      if (correction) {
                        correctPlan();
                      }
                    }}
                  >
                    {t("submit")}
                  </Button>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default CourseLesson;
