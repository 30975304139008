import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import Const from "../../helpers/const";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Input,
  FormControlLabel,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Box,
  Grid,
  FormLabel,
  FormGroup,
  LinearProgress,
} from "@material-ui/core";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import jsPDF from "jspdf";
import PptxGenJS from "pptxgenjs";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  // Form Card AI Component
  Container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  formikWrapper: {
    marginLeft: "10%",
  },
  "& .Mui-selected": {
    textAlign: "left",
  },

  // Keywords Input styles
  chipInputContainer: {
    marginBottom: "10px",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    padding: "5px",
    minHeight: "36px",
  },
  chip: {
    margin: "2px",
  },
  input: {
    border: "none",
    outline: "none",
  },
  // Response Styles
  WrapperResponse: {
    display: "flex",
    textAlign: "left !important",
    alignItems: "center",
    flexDirection: "column",
  },
  ButtonGroup: {
    width: "90%",
    justifyContent: "space-around",
    display: "flex",
    flexDirection: "row",
  },
  button: {
    // margin: theme.spacing(0.5),
    backgroundColor: "#2196F3", // Blue color
    color: "#FFFFFF", // White text
    borderRadius: 20,
    width: "100%",
  },
});

// Initial Values Formik
const initialValues = {
  subject: "",
  topic: "",
  level: "",
  lessonPlan: "",
  standard: "",
  university: "",
  age: "13",
  skill: "",
  activity: [],
  lesson_plan_length: "60",
  keywords: [],
  lesson_aims: [],
  esl: false,
};
// Validation Schema Yup
const validationSchema = Yup.object({
  subject: Yup.string().required("Subject is required"),
  topic: Yup.string().required("Topic is required"),
});

const updateWordLength = async (authUser, words_lenght) => {
  try {
    // Prepare the data
    const data = {
      user_id: authUser?.id,
      words_lenght: words_lenght,
    };

    // Configure the request
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: Const.BASE_URL + "check_genie_status",
      headers: {
        Authorization: `Bearer ${localStorage.userToken}`,
      },
      data: data,
    };

    // Make the request
    const response = await axios.request(config);

    // Return or log the response

    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const CourseAI = ({ closeOpenCourse }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const authUser = useSelector((store) => store.auth.user);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lessonPlanResponse, setLessonPlanResponse] = useState("");
  const [pdfData, setPdfData] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const steps = ["Generate AI Course Detail", "Response"];

  // FUNCTION Handle Cancel
  const handleCancel = () => {
    setActiveStep(0);
    setLessonPlanResponse("");
    setisLoading(false);
  };

  const generateLessonPlanQuery = (courseOutline) => {
    const { courseTitle, detailedCourseBreakdown } = courseOutline;

    let lessonPlanQuery = `Generate a detailed lesson plan for the course titled "${courseTitle}" and showing total hours of course. The lesson plan should cover the following topics and subtopics with detail explaination according to weeks and hours :\n\n`;

    detailedCourseBreakdown.weeks.forEach((week) => {
      lessonPlanQuery += `Week ${week.week}:\n`;
      week.hours.forEach((hour) => {
        lessonPlanQuery += `  ${hour.hourRange} - Topic: ${hour.topic}\n`;
        hour.subtopics.forEach((subtopic) => {
          lessonPlanQuery += `Subtopic: ${subtopic.subtopic} - Description: ${subtopic.description}\n`;
        });
      });
      lessonPlanQuery += `\n`;
    });
    return lessonPlanQuery;
  };

  const handleSubmit = async (values) => {
    setisLoading(true);
    setProgressValue();

    try {
      const firstQuery = await generateFirstQuery(values);
      const Firstresponse = await axios.post(
        "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
        {
          messages: [
            { role: "system", content: firstQuery },
            {
              role: "user",
              content: `Subject:${values.subject},Topic:${values.topic},Age Group:${values.age_group},Educational Level:${values.educational_level},University Level:${values.university},Curriculum Standards:${values.standard},Course Duration:${values.course_duration},Hours per Week:${values.hours_per_week}`,
            },
          ],
          max_tokens: 4000,
          temperature: 0.5,
          frequency_penalty: 0,
          presence_penalty: 2.0,
          stop: null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": "7327443ae85e44419192e4fb0544d702",
          },
        }
      );
      const word_token = Firstresponse.data.usage.total_tokens;
      updateWordLength(authUser, word_token);
      const FirstresponseData = Firstresponse.data.choices[0].message.content;
      let courseOutline;
      try {
        courseOutline = JSON.parse(FirstresponseData);
        const response = await axios.post(Const.BASE_URL + "courses", {
          user_id: authUser.id,
          data: courseOutline,
        });

        alert("course added successfully !");
        closeOpenCourse();
      } catch (e) {
        console.error("Failed to parse response as JSON:", e);
        courseOutline = null;
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    } finally {
      setisLoading(false);
    }
  };

  const generateFirstQuery = async (values) => {
    return `
    I will Generate a detailed course Outline for the Subject and topic provided by the user considering all other inputs provided by the user. Ensure the response is in JSON format without including '''json. For example:
    {
        "courseTitle": "${values.subject}",
        "topic": "${values.topic}",
        "ageGroup": "${values.age_group}",
        "educationalLevel": "${values.educational_level}",
        "universityLevel": "${values.university}",
        "curriculumStandard": "${values.standard}",
        "courseDuration": "${values.course_duration}",
        "hoursPerWeek": ${values.hours_per_week},
        "detailedCourseBreakdown": {
            "totalHours": 24,
            "weeks": [
                {
                    "week": 1,
                    "hours": [
                        {
                            "hourRange": "1-2",
                            "topic": "${values.topic}",
                            "subtopics": [
                                {
                                    "subtopic": "Subtopic1",
                                    "description": "Description of Subtopic1."
                                },
                                {
                                    "subtopic": "Subtopic2",
                                    "description": "Description of Subtopic2."
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    }
    2. Ensure generated topics are in the language associated with '${values.topic}'.
    `;
  };

  const setProgressValue = () => {
    setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);
  };

  const formatChatGPTResponse = (response) => {
    // Preserve the original language of the response
    let formattedResponse = response;
    formattedResponse = formattedResponse.replace(
      /^## (.*?)$/gm,
      "<h3>$1</h3>"
    );
    formattedResponse = formattedResponse.replace(/^# (.*?)$/gm, "<h2>$1</h2>");
    formattedResponse = formattedResponse.replace(
      /^### (.*?)$/gm,
      "<h2>$1</h2>"
    );
    formattedResponse = formattedResponse.replace(/^- (.*?)$/gm, "<li>$1</li>");
    formattedResponse = formattedResponse.replace(/\n/g, "<br>");
    formattedResponse = formattedResponse.replace(
      /(https?:\/\/\S+)/gi,
      '<a href="$1" target="_blank">$1</a>'
    );
    formattedResponse = formattedResponse.replace(/\*/g, "");
    // formattedResponse = `<div lang="${language}">${formattedResponse}</div>`;
    return formattedResponse;
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(pdfData)
      .then(() => {
        setIsCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  return (
    <>
      <Box className={classes.Container}>
        <div>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step
                  key={label}
                  {...stepProps}
                  onClick={() => {
                    setActiveStep(0);
                    setLessonPlanResponse("");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <StepLabel {...labelProps}>{t(label)}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <div className={classes.formikWrapper}>
          {lessonPlanResponse == "" ? (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form style={{ fontWeight: "bold" }}>
                  <Grid container spacing={5}>
                    {/* Subject */}
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      <Field name="subject">
                        {({ field, meta }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label={t("subject")}
                            variant="outlined"
                            error={meta.touched && meta.error !== undefined}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      <Field name="topic">
                        {({ field, meta }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label={t("topic")}
                            variant="outlined"
                            error={meta.touched && meta.error !== undefined}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5}>
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      {/* Demographic Information */}
                      <Field name="age_group">
                        {({ field, meta }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label={t("age")}
                            variant="outlined"
                            type="number"
                            inputProps={{ min: "1" }}
                            error={meta.touched && meta.error !== undefined}
                            helperText={meta.touched && meta.error}
                          />
                          // <FormControl variant="outlined" fullWidth>
                          //     <InputLabel>{t("Age Group")}</InputLabel>
                          //     <Select
                          //         className={classes.select}
                          //         {...field}
                          //         label={t("Age Group")}
                          //         error={meta.touched && meta.error !== undefined}
                          //         helperText={meta.touched && meta.error}
                          //     >
                          //         <MenuItem value=""><em>None</em></MenuItem>
                          //         <MenuItem value="5-7 years">5-7 years</MenuItem>
                          //         <MenuItem value="8-10 years">8-10 years</MenuItem>
                          //         <MenuItem value="11-13 years">11-13 years</MenuItem>
                          //         <MenuItem value="14-16 years">14-16 years</MenuItem>
                          //         <MenuItem value="17-19 years">17-19 years</MenuItem>
                          //         <MenuItem value="Adult"> Adult</MenuItem>
                          //     </Select>
                          // </FormControl>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      <Field name="educational_level">
                        {({ field, form, meta }) => (
                          <FormControl
                            variant="outlined"
                            fullWidth
                            error={meta.touched && !!meta.error}
                          >
                            <InputLabel>{t("Educational Level")}</InputLabel>
                            <Select
                              className={classes.select}
                              {...field}
                              label={t("Educational Level")}
                            >
                              <MenuItem value="">
                                {" "}
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="Beginner">
                                {" "}
                                {t("beginner")}
                              </MenuItem>
                              <MenuItem value="Pre-Intermediate">
                                {" "}
                                {t("pre_intermediate")}
                              </MenuItem>
                              <MenuItem value="Intermediate">
                                {t("intermediate")}
                              </MenuItem>
                              <MenuItem value="UpperIntermediate">
                                {t("upper_intermediate")}
                              </MenuItem>
                              <MenuItem value="Advanced">
                                {" "}
                                {t("advanced")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      {/* university */}
                      <Field name="university">
                        {({ field, meta }) => (
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel>
                              {t("University Level (Optional)")}
                            </InputLabel>
                            <Select
                              className={classes.select}
                              {...field}
                              label={t("University Level (Optional)")}
                              error={meta.touched && meta.error !== undefined}
                              helperText={meta.touched && meta.error}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="Foundation">
                                Foundation Level (Freshman/First Year)
                              </MenuItem>
                              <MenuItem value="Intermediate">
                                Intermediate Level (Sophomore/Second Year)
                              </MenuItem>
                              <MenuItem value="Proficient">
                                Proficient Level (Junior/Third Year)
                              </MenuItem>
                              <MenuItem value="Advanced">
                                Advanced Level (Senior/Fourth Year)
                              </MenuItem>
                              <MenuItem value="Professional">
                                Professional Level (Post-Graduation)
                              </MenuItem>
                              <MenuItem value="Expert">
                                Expert Level (Advanced Professional/Graduate
                                Studies)
                              </MenuItem>
                              {/* <MenuItem value=""><em>None</em></MenuItem>
                                                            <MenuItem value="College Freshman">College Freshman</MenuItem>
                                                            <MenuItem value="College Sophomore">College Sophomore</MenuItem>
                                                            <MenuItem value="College Junior">College Junior</MenuItem>
                                                            <MenuItem value="College Senior">College Senior</MenuItem>
                                                            <MenuItem value="Graduate (Master's)">Graduate (Master's)</MenuItem>
                                                            <MenuItem value="Postgraduate (PhD)">Postgraduate (PhD)</MenuItem> */}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      <Field name="standard">
                        {({ field, form, meta }) => (
                          <FormControl
                            variant="outlined"
                            fullWidth
                            error={meta.touched && !!meta.error}
                          >
                            <InputLabel>
                              {t("Select International Standard (Optional)")}
                            </InputLabel>
                            <Select
                              className={classes.select}
                              {...field}
                              label={t(
                                "Select International Standard (Optional)"
                              )}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem
                                disabled
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                IB Program Lesson Plans
                              </MenuItem>
                              <MenuItem value="IB PYP">
                                PYP Lesson Plan
                              </MenuItem>
                              <MenuItem value="IB MYP">
                                MYP Lesson Plan
                              </MenuItem>
                              <MenuItem value="IB DP">DP Lesson Plan</MenuItem>
                              <MenuItem value="IB CP">CP Lesson Plan</MenuItem>

                              <MenuItem
                                disabled
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                Cambridge Assessment Lesson Plans
                              </MenuItem>
                              <MenuItem value="Cambridge IGCSE">
                                IGCSE Lesson Plan
                              </MenuItem>
                              <MenuItem value="Cambridge A-Level">
                                A-Level Lesson Plan
                              </MenuItem>

                              <MenuItem
                                disabled
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                Edexcel International Lesson Plans
                              </MenuItem>
                              <MenuItem value="Edexcel International GCSE">
                                International GCSE Lesson Plan
                              </MenuItem>
                              <MenuItem value="Edexcel International A-Level">
                                International A-Level Lesson Plan
                              </MenuItem>
                              <MenuItem value="Edexcel International Advanced Level">
                                International Advanced Level Lesson Plan
                              </MenuItem>

                              <MenuItem
                                disabled
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                AP Course Lesson Plans
                              </MenuItem>
                              <MenuItem value="AP">
                                AP Lesson Plan Based on the Curricular Framework
                              </MenuItem>

                              <MenuItem
                                disabled
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                CEFR-Based Language Lesson Plans
                              </MenuItem>
                              <MenuItem value="CEFR A1">
                                Language Lesson Plan for CEFR Level A1
                              </MenuItem>
                              <MenuItem value="CEFR A2">
                                Language Lesson Plan for CEFR Level A2
                              </MenuItem>
                              <MenuItem value="CEFR B1">
                                Language Lesson Plan for CEFR Level B1
                              </MenuItem>
                              <MenuItem value="CEFR B2">
                                Language Lesson Plan for CEFR Level B2
                              </MenuItem>
                              <MenuItem value="CEFR C1">
                                Language Lesson Plan for CEFR Level C1
                              </MenuItem>
                              <MenuItem value="CEFR C2">
                                Language Lesson Plan for CEFR Level C2
                              </MenuItem>

                              <MenuItem
                                disabled
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                Early Childhood Education Lesson Plans
                              </MenuItem>
                              <MenuItem value="Reggio Emilia">
                                Reggio Emilia-Inspired Early Education Lesson
                                Plan
                              </MenuItem>
                              <MenuItem value="Montessori">
                                Montessori-Based Early Education Lesson Plan
                              </MenuItem>
                              {/* <MenuItem value=""><em>None</em></MenuItem>
                                                            <MenuItem value="International Baccalaureate (IB)">International Baccalaureate (IB)</MenuItem>
                                                            <MenuItem value="Cambridge International Examinations (CIE)">Cambridge International Examinations (CIE)</MenuItem>
                                                            <MenuItem value="Common Core State Standards (US)">Common Core State Standards (US)</MenuItem>
                                                            <MenuItem value="National Curriculum (UK)">National Curriculum (UK)</MenuItem>
                                                            <MenuItem value="Australian Curriculum">Australian Curriculum</MenuItem>
                                                            <MenuItem value='Others'>Others</MenuItem> */}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5}>
                    {/* Subject */}
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      <Field name="course_duration">
                        {({ field, meta }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label={t("Course Duration(Number of weeks/months)")}
                            variant="outlined"
                            error={meta.touched && meta.error !== undefined}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                      <Field name="hours_per_week">
                        {({ field, meta }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label={t("Hours per Week (Number of hours)")}
                            variant="outlined"
                            error={meta.touched && meta.error !== undefined}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  {/* Submit Button */}
                  <div style={{ width: "80%", marginTop: 10 }}>
                    {isLoading ? (
                      <>
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                          />
                        </Box>
                        <br />
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={handleCancel}
                        >
                          {" "}
                          {t("cancel")}{" "}
                        </Button>
                      </>
                    ) : (
                      <div className={classes.ButtonGroup}>
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={closeOpenCourse}
                        >
                          {t("cancel")}
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          {t("generate_plan")}
                        </Button>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className={classes.WrapperResponse}>
              <p
                style={{
                  textAlign: "left !important",
                  fontFamily: "Calibri, Arial, sans-serif",
                }}
                id="content"
                dangerouslySetInnerHTML={{ __html: lessonPlanResponse }}
              ></p>
              <div className={classes.ButtonGroup}>
                {/* <Button
                                color="primary"
                                variant="contained"
                                onClick={() => setOpenModal(true)}
                                >
                                {t("correct")}
                                </Button> */}

                <Button
                  color="primary"
                  variant="contained"
                  onClick={copyToClipboard}
                >
                  {isCopied ? "Copied" : <ContentCopyIcon />}
                </Button>
                {/* <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={handleOpen}
                                    >
                                    {t("edit_lesson_plan")}
                                    </Button> */}

                {/* <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={generatePPTX}
                                    >
                                    {t("generate_pptx")}
                                    </Button> */}

                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setActiveStep(0);
                    setLessonPlanResponse("");
                  }}
                >
                  {t("generate_new_lesson_plan")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

export default CourseAI;
