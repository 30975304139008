import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Button,
  Box,
  makeStyles,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import PptxGenJS from "pptxgenjs";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Container from "@material-ui/core/Container";
import axios from "axios";
import Const from "../../helpers/const";
import { useTranslation } from "react-i18next";
import Header from "../../components/header";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axiosOptions from "../../helpers/axiosOptions";
import { Fonts, BgColor } from "../../theme";

import ActivityListPopUp from "./ActivityListPopUp";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { updateToken } from "./InteractiveActivities/scoreStoreApi";

const styles = (theme) => ({
  overlayWrapper: {
    position: "fixed",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});

const useStyles = makeStyles({
  // Form Card AI Component
  Container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  formikWrapper: {
    marginLeft: "10%",
  },
  "& .Mui-selected": {
    textAlign: "left",
  },

  // Keywords Input styles
  chipInputContainer: {
    marginBottom: "10px",
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    padding: "5px",
    minHeight: "36px",
  },
  chip: {
    margin: "2px",
  },
  input: {
    border: "none",
    outline: "none",
  },
  // Response Styles
  WrapperResponse: {
    display: "flex",
    textAlign: "left !important",
    alignItems: "center",
    flexDirection: "column",
  },
  ButtonGroup: {
    width: "90%",
    justifyContent: "space-around",
    display: "flex",
    flexDirection: "row",
  },
  button: {
    // margin: theme.spacing(0.5),
    backgroundColor: "#2196F3", // Blue color
    color: "#FFFFFF", // White text
    borderRadius: 20,
    width: "100%",
  },
  popUpParent: {
    position: "relative",
  },
  activityPopUp: {
    position: "absolute",
    bottom: "2.8rem",
    background: "#303f9f",
    padding: "0.8rem",
    borderRadius: "0.25rem",
    // border: "2px solid #303f9f",
    boxShadow: "5px 5px 20px #6b6b6b",
  },
});

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  borderRadius: 10,
  p: 2,
};

const cardStyles = makeStyles(() => ({
  root: {
    marginRight: 13,
    marginTop: 13,
    height: "100%",
    transition: "0.3s",
    position: "relative",
    // "&:before": {
    //   transition: "0.2s",
    //   position: "absolute",
    //   width: "100%",
    //   height: "100%",
    //   content: '""',
    //   display: "block",
    //   backgroundColor: "#d9daf1",
    //   borderRadius: "1rem",
    //   zIndex: 0,
    //   bottom: 0,
    // },
    "&:hover": {
      // "&:before": {
      //   bottom: -6,
      // },
      // "& $card": {
      //   boxShadow: "-12px 12px 64px 0 #bcc3d6",
      // },
    },
  },
  card: {
    zIndex: 1,
    position: "relative",
    borderRadius: "10px",
    // boxShadow: "0 6px 20px 0 #dbdbe8",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    backgroundColor: "#fff",
    transition: "0.3s",
    height: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#d4d4d4",

    "&:hover": {
      // boxShadow: "none !important",
      // border: "0 !important",
    },
  },
  logo: {
    width: 48,
    height: 48,
    borderRadius: "0.75rem",
  },
  avatar: {
    fontSize: "0.875rem",
    backgroundColor: "#6d7efc",
  },
  join: {
    color: "#fff",
    borderRadius: "50px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: BgColor.myBlue,
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#689CF2",
    },
  },
  delete: {
    color: "#fff",
    borderRadius: "50px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: BgColor.myRed,
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    marginRight: "10px",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: BgColor.myDarkRed,
    },
  },
  danger: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: Fonts.Regular,
    borderRadius: "50px",
    backgroundColor: "#F48FB1",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#ff266f",
    },
  },
}));

const SingleLessonPlan = () => {
  const classes = useStyles();
  const { lessonId } = useParams();
  const history = useHistory();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const weekId = queryParams.get("weekId");
  const topicId = queryParams.get("topicId");

  const { t, i18n } = useTranslation();
  const Styles = cardStyles();

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [pdfData, setPdfData] = useState("");
  const [lessonPlanActivity, setLessonPlanActivity] = useState([]);
  const [singleLesson, setSingleLeason] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const authUser = useSelector((store) => store.auth.user);
  const [selectedContent, setSelectedContent] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [lessonPlanResponse, setLessonPlanResponse] = useState("");
  const [correction, setCorrection] = useState("");
  const [isShowActivityList, setIsShowActivityList] = useState(false);
  const [activities, setActivities] = useState([]);

  const checkActivePlan = async () => {
    try {
      const response = await axios.post(
        Const.BASE_URL + "check_genie_status",
        { user_id: authUser?.id },
        axiosOptions(localStorage.userToken)
      );

      if (response) {
        const planData = response?.data?.data;
        const currentDate = new Date();
        const planExpiryDate = new Date(planData?.vaild_upto);

        if (
          response?.data?.status === "false" ||
          planData?.price !== "45" ||
          planData?.words_lenght === 0 ||
          currentDate > planExpiryDate
        ) {
          history.push("/subscription");
        } else {
          setLoading(false);
        }
      }

      setLoading(false);
    } catch (error) {
      let err = error.response ? error.response.data : error;
      console.error("Error in checking active plan:", err);
      history.push("/subscription");
    }
  };

  useEffect(() => {
    checkActivePlan();
  }, [authUser]);

  const activitiesText = activities
    ?.map((activity) => `<p><h3>${activity.activity_name}<h3></p>`)
    .join("");

  // Locate the target heading and inject activities before it
  const updatedLessonPlanResponse = singleLesson?.content?.replace(
    "### Keywords", // Heading to locate
    `${activitiesText}<h3>Keywords</h3>` // Inject formatted activities before the heading
  );

  const getSingleCreatedLeason = async () => {
    try {
      setLoading(true);
      // Todo: Update url pending
      const response = await axios.get(
        `${Const.BASE_URL}get/${lessonId}/lessonplan`
      );

      if (response.status === 200) {
        setSingleLeason(response?.data?.lesson_plans[0]);
        setActivities(response?.data?.lesson_plans[0].activities);
        setPdfData(response?.data?.lesson_plans[0]?.content);
        setLessonPlanActivity(
          response?.data?.lesson_plans[0]?.activities ?? []
        );
        toast.success(response.data.message, { autoClose: 2000 });
      }
    } catch (error) {
      console.error("error : ", error);

      toast.error("Internal Server Error", { autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };

  const correctPlan = async () => {
    setModalLoading(true);
    const response = await axios.post(
      "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
      {
        messages: [
          {
            role: "system",
            content: `You are about to make changes to the lesson plan. Here is the original lesson plan: ${lessonPlanResponse}`,
          },
          {
            role: "user",
            content: `${correction} \nDon't alter the structure of lesson plan and make changes in that existing structure. Please write the corrected lesson plan after the word *****.`,
          },
        ],
        // max_tokens: 650,
        temperature: 0.5,
        frequency_penalty: 0,
        presence_penalty: 2.0,
        stop: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": "7327443ae85e44419192e4fb0544d702",
        },
      }
    );

    const word_token = response.data.usage.total_tokens;
    await updateToken(authUser, word_token);
    setLessonPlanResponse(
      response.data.choices[0].message.content.split("*****")[1]
    );
    setModalLoading(false);
    setCorrection("");
    setOpenModal(false);
  };

  const convertHtmlToRichText = (html) => {
    const richText = [];
    const div = document.createElement("div");
    div.innerHTML = html;

    div.childNodes.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const tagName = node.tagName.toLowerCase();
        const text = node.innerText;

        switch (tagName) {
          case "h1":
            richText.push({ text, options: { fontSize: 32, bold: true } });
            break;
          case "h2":
            richText.push({ text, options: { fontSize: 28, bold: true } });
            break;
          case "h3":
            richText.push({ text, options: { fontSize: 24, bold: true } });
            break;
          case "li":
            richText.push({ text, options: { bullet: true, fontSize: 18 } });
            break;
          case "ol":
            // Handle ordered lists (numbered items)
            node.childNodes.forEach((itemNode, index) => {
              if (
                itemNode.nodeType === Node.ELEMENT_NODE &&
                itemNode.tagName.toLowerCase() === "li"
              ) {
                richText.push({
                  text: `${index + 1}. ${itemNode.innerText}`,
                  options: { fontSize: 18 },
                });
              }
            });
            break;
          case "br":
            richText.push({ text: "\n", options: { fontSize: 18 } });
            break;
          default:
            richText.push({ text, options: { fontSize: 18 } });
            break;
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent.trim();
        if (textContent.length > 0) {
          richText.push({ text: textContent, options: { fontSize: 18 } });
        }
      }
    });

    return richText;
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(pdfData)
      .then(() => {
        setIsCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  const generatePPTX = () => {
    const pptx = new PptxGenJS();
    const slideWidthInches = 10;
    const slideHeightInches = 7.5;
    const maxFontSize = 24;
    const minFontSize = 10;
    const maxLinesPerSlide = 15;

    // Set slide dimensions and master slide to ensure no default margins
    pptx.defineSlideMaster({
      title: "Master Slide",
      width: slideWidthInches,
      height: slideHeightInches,
      margin: { top: 0, left: 0, right: 0, bottom: 0 }, // Ensure no default margins
    });

    let slide = pptx.addSlide();
    let currentText = "";
    let lineCount = 0;

    const finalizeSlide = () => {
      if (currentText.trim().length > 0) {
        slide.addText(currentText, {
          x: 0, // Start at the very left of the slide
          y: 0, // Start at the very top of the slide
          w: slideWidthInches, // Full width of the slide
          h: slideHeightInches, // Full height of the slide
          align: "left",
          valign: "top", // Vertical alignment at the top
          fontSize: Math.max(minFontSize, maxFontSize - lineCount * 1.5),
          margin: { left: 0, top: 0 }, // Ensure no extra margins
        });
        currentText = "";
        lineCount = 0;
        slide = pptx.addSlide(); // Add a new slide
      }
    };
    const parseAndSplitContent = (content, maxLength = 500) => {
      const sections = content
        .split(/(?=<h[1-3]>)/)
        .map((section) => section.trim())
        .filter((section) => section.length > 0);
      const splitSections = [];

      sections.forEach((section) => {
        if (section.length > maxLength) {
          const parts = section.match(
            new RegExp(`.{1,${maxLength}}(\\s|\\.|,|;|\\?)`, "g")
          );
          splitSections.push(...parts);
        } else {
          splitSections.push(section);
        }
      });

      return splitSections;
    };

    const sections = parseAndSplitContent(pdfData);

    sections.forEach((section, index) => {
      const richText = convertHtmlToRichText(section);

      richText.forEach((textObj) => {
        const text = textObj.text;
        const lines = text.split("\n");
        const options = textObj.options || {};

        lines.forEach((line) => {
          const trimmedLine = line.trim();
          if (trimmedLine.length === 0 || trimmedLine === "---") {
            return; // Skip empty lines or "---"
          }

          if (trimmedLine.startsWith("#") && currentText.trim().length > 0) {
            // Start a new slide for each heading if the current slide has content
            finalizeSlide();
          }

          if (
            lineCount >= maxLinesPerSlide ||
            currentText.length + line.length > 1000
          ) {
            finalizeSlide();
          }

          // Add bullet or number based on options
          if (options.bullet) {
            currentText += `• ${trimmedLine}\n`;
          } else {
            currentText += trimmedLine + "\n";
          }
          lineCount++;
        });
      });
    });
    finalizeSlide(); // Ensure the last slide is finalized
    pptx.writeFile({ fileName: "LessonPlan.pptx" });
  };

  useEffect(() => {
    getSingleCreatedLeason();
  }, []);

  return (
    <div>
      <Header history={history} />
      <div
        style={{
          height: "200px",
          background: "rgb(136, 155, 255)",
          width: "100%",
        }}
      ></div>
      <Container
        maxWidth="xl"
        className={classes.container + " opened-classes-main"}
      >
        <div className="page_outer lesson_planner_outer">
          <div className="inner_box">
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Button
                style={{ margin: "10px" }}
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                  history.goBack();
                }}
              >
                <span className="MuiButton-label">{t("Back")}</span>
              </Button>
            </Box>
            {loading && <div>Loading...</div>}

            <div className={classes.WrapperResponse}>
              <p
                style={{
                  textAlign: "left !important",
                  fontFamily: "Calibri, Arial, sans-serif",
                }}
                id="content"
                dangerouslySetInnerHTML={{ __html: singleLesson?.content }}
                // dangerouslySetInnerHTML={{ __html: updatedLessonPlanResponse }}
              ></p>
              <div className="bottom_btn">
                <div className={classes.ButtonGroup}>
                  <Button
                    className="custom_btn"
                    color="primary"
                    variant="contained"
                    onClick={() => setOpenModal(true)}
                  >
                    {t("correct")}
                  </Button>

                  <Button
                    className="custom_btn"
                    color="primary"
                    variant="contained"
                    onClick={copyToClipboard}
                  >
                    {isCopied ? "Copied" : <ContentCopyIcon />}
                  </Button>
                  {/* <Button
                color="primary"
                variant="contained"
                onClick={handleOpen}
              >
                {t("edit_lesson_plan")}
              </Button> */}

                  <div className={classes.popUpParent}>
                    {/* show popUp of activites list */}
                    {isShowActivityList && (
                      <div className={classes.activityPopUp}>
                        <ActivityListPopUp
                          activities={lessonPlanActivity}
                          lessonId={lessonId}
                          id={id}
                          weekId={weekId}
                          topicId={topicId}
                        />
                      </div>
                    )}
                    <Button
                      className="custom_btn"
                      color="primary"
                      variant="contained"
                      onClick={() => setIsShowActivityList(!isShowActivityList)}
                    >
                      {t("start_activity")}
                    </Button>
                  </div>

                  <Button
                    className="custom_btn"
                    color="primary"
                    variant="contained"
                    onClick={generatePPTX}
                  >
                    {t("generate_pptx")}
                  </Button>

                  {/* <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setActiveStep(0);
                  setLessonPlanResponse("");
                }}
              >
                {t("generate_new_lesson_plan")}
              </Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyles}>
          {modalLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {t("correct_lesson_plan")}
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                label={t("description")}
                variant="outlined"
                className={classes.input}
                style={{ marginTop: 15 }}
                value={correction}
                onChange={(e) => {
                  if (e.target.value.trim()) {
                    setCorrection(e.target.value);
                  }
                }}
              />
              <div className="question_btn_list">
                <Button
                  className="custom_btn"
                  color="primary"
                  variant="contained"
                  onClick={() => setOpenModal(false)}
                >
                  {t("cancel")}
                </Button>
                <Button
                  className="custom_btn"
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    if (correction) {
                      correctPlan();
                    }
                  }}
                >
                  {t("submit")}
                </Button>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default SingleLessonPlan;
