import React, { useEffect, useState } from "react";
// import FormCardAI from "./FormCardAI";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Header from "../../components/header";
import Typography from "@mui/material/Typography";
import { logo } from "../../assets";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Const from "../../helpers/const";
import axiosOptions from "../../helpers/axiosOptions";
import { useSelector } from "react-redux";
import CourseAI from "./CourseAI";
import CourseLesson from "./CourseLesson";
import { Fonts, BgColor, Color } from "../../theme";
import { Column, Row, Item } from "@mui-treasury/components/flex";
import { useApexInfoStyles } from "@mui-treasury/styles/info/apex";
import { Info, InfoSubtitle, InfoTitle } from "@mui-treasury/components/info";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import CourseTopic from "./CourseTopic";
import PopUpWarning from "./InteractiveActivities/PopUpWarning";

const styles = (theme) => ({
  overlayWrapper: {
    position: "fixed",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});

const useStyles = makeStyles(() => ({
  container: {
    alignSelf: "flex-end",
    marginTop: -150,
    paddingTop: 70,
    paddingBottom: 20,
    width: "auto",
  },
  root: {
    maxWidth: 431,
    marginTop: 10,
    marginRight: 10,
  },
  media: {
    height: 300,
  },
  mainHeading: {
    fontSize: "20px",
    fontFamily: Fonts.Medium,
  },
}));

const cardStyles = makeStyles(() => ({
  root: {
    marginRight: 0,
    marginTop: 20,
    height: "100%",
    transition: "0.3s",
    position: "relative",
    // "&:before": {
    //   transition: "0.2s",
    //   position: "absolute",
    //   width: "100%",
    //   height: "100%",
    //   content: '""',
    //   display: "block",
    //   backgroundColor: "#d9daf1",
    //   borderRadius: "1rem",
    //   zIndex: 0,
    //   bottom: 0,
    // },
    "&:hover": {
      // "&:before": {
      //   bottom: -6,
      // },
      // "& $card": {
      //   boxShadow: "-12px 12px 64px 0 #bcc3d6",
      // },
    },
  },
  card: {
    zIndex: 1,
    position: "relative",
    borderRadius: "12px",
    // boxShadow: "0 6px 20px 0 #dbdbe8",
    // boxShadow:
    //   "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    backgroundColor: "#fff",
    transition: "0.3s",
    height: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#3F51B5",
    padding: "20px 20px",

    "&:hover": {
      // boxShadow: "none !important",
      // border: "0 !important",
    },
  },
  logo: {
    width: 48,
    height: 48,
    borderRadius: "0.75rem",
  },
  avatar: {
    fontSize: "0.875rem",
    backgroundColor: "#6d7efc",
  },
  join: {
    color: "#fff",
    borderRadius: "85px",
    fontSize: "16px",
    padding: "0 25px",
    lineHeight: "44px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: "#3F51B5",
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#689CF2",
    },
  },
  delete: {
    color: "#fff",
    borderRadius: "85px",
    fontSize: "16px",
    padding: "0 25px",
    lineHeight: "44px",
    // backgroundColor: "#a4c4f9",
    backgroundColor: "#FF0004",
    // background: "linear-gradient(to top, #638ef0, #82e7fe)",
    marginRight: "10px",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: BgColor.myDarkRed,
    },
  },
  danger: {
    color: "#fff",
    fontSize: "14px",
    fontFamily: Fonts.Regular,
    borderRadius: "50px",
    backgroundColor: "#F48FB1",
    "& > *": {
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: "#ff266f",
    },
  },
}));

function CourseCreator(props) {
  const classes = useStyles();
  const Styles = cardStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    openLessonPlan: false,
    addingNewLessonPlan: false,
    addingNewAIPlan: false,
    addingNewResource: false,
    payCard: false,
    receiptCard: false,
  });
  const [openCoursePlan, setOpenCoursePlan] = useState(false);
  const [openCourse, setOpenCourse] = useState(false);
  const [openLeasonGenerator, setOpenLeasonGenerator] = useState(false);
  const [loading, setLoading] = useState(true);
  const authUser = useSelector((store) => store.auth.user);
  console.log("autuser>>",authUser);
  

  const [courses, setCourses] = useState([]);
  const [isCourseDelete, setIsCourseDelete] = useState("");
  const [courseIdToDelete, setCourseIdToDelete] = useState(null);
  const [openAllCourse, setOpenAllCourse] = useState(false);

  useEffect(() => {
    checkActivePlan();
  }, [authUser]);

  const closeOpenCourse = () => {
    setOpenCoursePlan(false);
  };

  const open = () => {
    openCourse
      ? setOpenLeasonGenerator(!openLeasonGenerator)
      : setOpenCoursePlan(true);
  };
  const backButtonHandler = () => {
    if (openCourse && openAllCourse) {
      setOpenAllCourse(false);
    } else if (openCourse) {
      setOpenCourse(false);
    }
  };

  const checkActivePlan = async () => {
    try {
      const response = await axios.post(
        Const.BASE_URL + "check_genie_status",
        { user_id: authUser?.id },
        axiosOptions(localStorage.userToken)
      );

      if (response) {
        const planData = response?.data?.data;
        const currentDate = new Date();
        const planExpiryDate = new Date(planData?.vaild_upto);

        if (
          response?.data?.status === "false" ||
          planData?.price !== "45" ||
          planData?.words_lenght === 0 ||
          currentDate > planExpiryDate
        ) {
          history.push("/subscription");
        } else {
          setLoading(false);
        }
      }

      setLoading(false);
    } catch (error) {
      let err = error.response ? error.response.data : error;
      history.push("/subscription");
    }
  };

  const getCourses = async () => {
    try {
      const response = await axios.get(
        Const.BASE_URL + "data/user/" + authUser.id
      );

      setCourses(response?.data.data);
    } catch (error) {
      console.error("Error getting courses:", error);
    }
  };

  useEffect(() => {
    getCourses();
  }, [openCoursePlan]);



  const handleDeleteCourse = (courseId) => {
    setCourseIdToDelete(courseId);
    setIsCourseDelete(true); 
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${Const.BASE_URL}course/${courseIdToDelete}/delete`
      );
      if (response.status === 200) {
        toast.success("Course Deleted Successfully", { autoClose: 2000 });

        setCourses((prev) => prev.filter((course) => course.id !== courseIdToDelete));
      }
    } catch (error) {
      toast.error("Internal Server Error", { autoClose: 2000 });
    }
    setIsCourseDelete(false);
  };

  const handleCancelDelete = () => {
    setIsCourseDelete(false); 
  };


  return (
    <>
      <Header history={history} />
      <div
        style={{
          height: "200px",
          background: "rgb(136, 155, 255)",
          width: "100%",
        }}
      ></div>
      <Container
        maxWidth="xl"
        className={
          classes.container + " opened-classes-main ourse_creator_outer"
        }
      >
        {!loading && (
          <Box
            className="ourse_creator_inner"
            flexWrap="wrap"
            p={3}
            m={0}
            bgcolor="background.paper"
            borderRadius={10}
          >
            {!openCoursePlan ? (
              <>
                <Box
                  className="top_sec_outer"
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  {openCourse && !openLeasonGenerator && (
                    <Button
                      className="custom_btn_style"
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={backButtonHandler}
                    >
                      <span className="MuiButton-label">
                        {openCourse && !openLeasonGenerator && t("Back")}
                      </span>
                    </Button>
                  )}
                  {!openAllCourse && (
                    <h2 className="page_label MuiTypography-root LessonPlan-mainHeading-245 MuiTypography-body1">
                      {openCourse ? t("Topics") : t("My Courses")}
                    </h2>
                  )}
                  {!openAllCourse && (
                    <Button
                      className="custom_btn_style"
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={open}
                    >
                      <span className="MuiButton-label">
                        {openCourse
                          ? openLeasonGenerator
                            ? t("Back")
                            : t("Generate Leason")
                          : t("Add Course")}
                      </span>
                    </Button>
                  )}
                </Box>

                <Box
                  className="col_sec_outer"
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  paddingTop={0}
                  bgcolor="background.paper"
                >
                  {courses.length > 0 ? (
                    courses &&
                    courses?.map((value, index) => (
                      <Grid
                        className="card_row_outer"
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        key={index}
                      >
                        <div className={Styles.root}>
                          <div className={Styles.card}>
                            <Row p={2} gap={2} className="card_row">
                              <Info
                                className="card_info"
                                position={"middle"}
                                useStyles={useApexInfoStyles}
                                p={0}
                              >
                                <Box
                                  className="card_box"
                                  fontSize={"18px"}
                                  letterSpacing={"0px"}
                                  lineHeight={"20px"}
                                  fontWeight={"bold"}
                                  minHeight={"50px"}
                                  fontFamily={Fonts.Medium}
                                  color={Color.textMyBlack}
                                >
                                  <h2 className="course_headeing"><span>{t("Subject")}</span>
                                    :-<span className="subject_title">{value.courseTitle}</span>
                                  </h2>
                                 
                                  <p className="sub_title">
                                    <span className="sub_heading"></span>
                                    {t("Topic")}:- <span className="value_course">{value.topic}</span>
                                  </p>
                                  <p className="sub_title">
                                    <span className="sub_heading">{t("Course Duration")}</span>
                                    :-
                                    <span className="value_course">{value.courseDuration
                                      .toLowerCase()
                                      .includes("week")
                                      ? value.courseDuration
                                      : `${value.courseDuration} WEEKS`}</span>
                                  </p>
                                  <p className="sub_title">
                                    <span className="sub_heading">{t("Total Hours")}</span>
                                    :-{" "} <span className="value_course"> {value.detailedCourseBreakdown.totalHours}</span>

                                  </p>
                                  <p className="sub_title">
                                    <span className="sub_heading"> {t("averate_score")}</span>
                                    :-{" "} <span className="value_course">{value.average}</span>
                                  </p>
                                </Box>
                              </Info>
                            </Row>
                            <Box
                              className="card_box"
                              pb={1}
                              px={2}
                              color={"grey.600"}
                              fontSize={"0.875rem"}
                              fontFamily={"Ubuntu"}
                            ></Box>
                            <Row
                              p={2}
                              gap={2}
                              position={"bottom"}
                              className="card_row"
                            >
                              <Item className="card_info" position={"center"}>
                                <Button
                                  className={Styles.delete}
                                  variant={"contained"}
                                  onClick={() => handleDeleteCourse(value.id)}
                                >
                                  {t("Delete Course")}
                                </Button>

                                {isCourseDelete && (
                                  <PopUpWarning
                                    heading={t("Delete")}
                                    subHeading={t("Are you sure you want to delete this course?")}
                                    leftButton={t("Cancel")}
                                    rightButton={t("Delete")}
                                    handleLeftButton={handleCancelDelete}
                                    handleRightButton={handleConfirmDelete}
                                  />
                                )}

                                <Button
                                  className={Styles.join}
                                  variant={"contained"}
                                  onClick={() =>
                                    history.push(
                                      `/course-creator/topics/${value.id}`
                                    )
                                  }
                                >
                                  {t("open_course")}
                                </Button>
                              </Item>
                            </Row>
                          </div>
                        </div>
                      </Grid>
                    ))
                  ) : (
                    <p>No Course found !</p>
                  )}
                </Box>
              </>
            ) : (
              <CourseAI closeOpenCourse={closeOpenCourse} />
            )}
          </Box>
        )}
        {/* <CourseLesson id={19} closeOpenCourse={setOpenAllCourse} courseDetail={courseDetail} openAllCourse={openAllCourse}  /> */}
      </Container>
    </>
  );
}

export default CourseCreator;
