import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PopUpWarning from "./PopUpWarning";
import axios from "axios";
import PopUpNextActivity from "./PopUpNextActivity";
import { saveScoreAPI, updateToken } from "./scoreStoreApi";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  quizContainer: {
    width: "100%",
    margin: "auto",
  },
});

const CaseStudyActivity = ({ CaseStudy, lessonId }) => {
  const { t, i18n } = useTranslation();
  const authUser = useSelector((store) => store.auth.user);
  const classes = useStyles();
  const history = useHistory();
  const [currentQustionAnswer, setCurrentQustionAnswer] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentPopShow, setCurrentPopShow] = useState(null);

  const [allAnswers, setAllAnswers] = useState(
    Array(questions.length).fill("")
  );

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [title, setTitle] = useState("");

  const [scoreLoading, setScoreLoading] = useState(false);
  const [score, setScore] = useState({ score: 0, maxScore: 3 });
  const [lesson, setLesson] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (CaseStudy && CaseStudy[0]) {
      const JsonCaseData = JSON.stringify(CaseStudy);
      // Access the text from the first object and extract the case study title
      const caseStudyText = CaseStudy[0]["Case Studies"];
      const caseStudyTitle = extractCaseStudy(caseStudyText);
      setTitle(caseStudyTitle);
    }
  }, [CaseStudy]);

  const extractCaseStudy = (text) => {
    const match = text?.match(/\*\*(.*?)\*\*/);
    return match ? match[1].trim() : "No match found.";
  };
  const GenrateQAStudyCase = async (CaseStudy) => {
    // inputMessage, messages

    const response = await axios.post(
      "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
      {
        messages: [
          {
            role: "system",
            content: `You are an AI assistant trained to genrate Atmost three Question Answer along wuth options from case-Study passage:${JSON.stringify(
              CaseStudy
            )}. Stay on topic of Case studies:${JSON.stringify(
              CaseStudy
            )} as much as possible.Base your response on the given context, the case studies. Provide clear, concise, and well-reasoned arguments. If there is no clear Case studies, indicate that more information is needed. If there is no clear answer, indicate that more information is needed.
            In addition to the questions, create a lesson plan with structured content and examples that reference all question-answer use language and standerd above mention.Ensure that each questions answer is integrated into the lesson as a  reference point for comprehension, guiding learners to understand the core ideas.
            Please ensure that you provide output in JSON format with the question: {"question", "options", "answer"} and LessonContent only, no extra text like suggestions or headings.
          This is only Example output format:  
  {
           'LessonContent':
    "Once upon a time, there was a tale centered around a powerful message—the central theme or primary focus presented in the article. This story unfolded over time, with each event fitting into a specific timeline or period during which these important moments took place. Key figures emerged, each one influencing the story’s path, making it richer and deeper. Through every twist and turn, the author’s words helped bring this theme to life, creating a lasting impression on all who read it."
    ,
    "CaseStudyQuestion": [
      {
        "question": "What is the synonym of "happy"?",
        "options": ["Sad", "Joyful", "Angry", "Tired"],
        "answer": "Joyful"
      },
      {
        "question": "Which word means "to look quickly"?",
        "options": ["Glance", "Stare", "Gaze", "Peek"],
        "answer": "Glance"
      },
      ...
    ]
  }`,
          },

          {
            role: "user",
            content:
              "Generate questions and answers related to this case study.",
          },
        ],
        max_tokens: 4000,
        temperature: 0.1,
        frequency_penalty: 0,
        presence_penalty: -1.5,
        stop: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": "7327443ae85e44419192e4fb0544d702",
        },
      }
    );

    // setGenrateQA(response.data.choices[0].message)
    // const authUser = useSelector((store) => store.auth.user);
    const word_token = response.data.usage.total_tokens;
    await updateToken(authUser, word_token);
    return response.data.choices[0].message.content;
  };
  
  useEffect(() => {
    setDataLoading(true);
    if (CaseStudy && CaseStudy[0]) {
      const fetchQAData = async () => {
        const response = await GenrateQAStudyCase(CaseStudy);
        setDataLoading(false);
        const ParseQAO = JSON.parse(response);
        setLesson(ParseQAO.LessonContent);
        setQuestions(ParseQAO.CaseStudyQuestion);
        setAllAnswers(ParseQAO.CaseStudyQuestion.map((q) => q.answer)); // Assuming the response is a JSON string
      };

      fetchQAData();
    }
  }, [CaseStudy]);

  const handelSubmit = async () => {};

  const handleSubmitAndExit = async () => {
    // const score = scoreCalculation();
    history.goBack();
  };

  const showExitPopup = () => {
    setCurrentPopShow("exitPopUp");
  };

  const showSubmitPopUp = () => {
    setCurrentPopShow("submitPopUp");
  };

  const saveAnswers = () => {
    if (currentQustionAnswer) {
      const updatedAnswers = [...allAnswers];
      updatedAnswers[currentQuestionIndex] = currentQustionAnswer;
      setAllAnswers(() => updatedAnswers);
    }
  };

  //popup functions:
  const handleSubmitAndGetScore = async () => {
    // setCurrentQustionAnswer("")
    setScoreLoading(true);
    setCurrentPopShow("scoreForm");

    const score = scoreCalculation();

    await saveScoreAPI(
      Number(lessonId),
      score.score,
      score.totalScore,
      "Case Studies"
    );

    setScore(score);

    setScoreLoading(false);
    // history.push(`/course-creator/score/${lessonId}/Case Studies`, { score });
  };

  useEffect(() => {
    setCurrentQustionAnswer("");
  }, [currentQuestionIndex]);

  const scoreCalculation = () => {
    let score = 0;
    for (let index = 0; index < allAnswers.length; index++) {
      questions[index].answer === allAnswers[index] && score++;
    }
    return { score: score, totalScore: allAnswers.length };
  };
  useEffect(() => {
    // setQuestions(quizData);
    setAllAnswers(Array(questions.length).fill(""));
  }, [questions]);

  return (
    <div className="case_main">
      {dataLoading ? (
        <div className="spinner_outer">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className={classes.quizContainer}>
          <div className="page_outer">
            <div className="inner_box">
              <div className="top_heading">
                <h2>
                  <span> {title}</span>
                </h2>
              </div>
              {lesson && (
                <div className="para_case">
                  <p>{lesson}</p>
                </div>
              )}
              <div className="page_inner">
                <div className="question_list" item xs={12}>
                  <h3>Question {currentQuestionIndex + 1}</h3>
                  <h6>{questions[currentQuestionIndex]?.question}</h6>
                </div>
                <div className="question_option">
                  {questions[currentQuestionIndex]?.options?.map(
                    (option, index) => (
                      <div className="question_mark" key={index}>
                        <input
                          type="radio"
                          id={`option-${index}`}
                          name="options"
                          onChange={() => {
                            setCurrentQustionAnswer(option);
                          }}
                          checked={
                            currentQustionAnswer
                              ? currentQustionAnswer === option
                              : allAnswers[currentQuestionIndex] === option
                          }
                        />
                        <label
                          htmlFor={`option-${index}`}
                        >{` ${String.fromCharCode(
                          65 + index
                        )}. ${option}`}</label>
                      </div>
                    )
                  )}
                </div>
                <Grid
                  className="question_btn_list"
                  item
                  xs={12}
                  container
                  justifyContent="center"
                >
                  <Button
                    className="custom_btn"
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      currentQuestionIndex > 0
                        ? setCurrentQuestionIndex(currentQuestionIndex - 1)
                        : showExitPopup();
                    }}
                  >
                    {t("Back")}
                  </Button>

                  {/* <Button className='custom_btn'
            color="primary"
            variant="contained"
            style={{ marginLeft: 5 }}
            onClick={() => {
              saveAnswers();
            }}
          >
            {t("Submit")}
          </Button> */}

                  <Button
                    className="custom_btn"
                    color="primary"
                    variant="contained"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      saveAnswers();
                      currentQuestionIndex < questions.length - 1
                        ? setCurrentQuestionIndex(currentQuestionIndex + 1)
                        : showSubmitPopUp();
                    }}
                  >
                    {" "}
                    {t(
                      currentQuestionIndex < questions.length - 1
                        ? "Next"
                        : "Submit"
                    )}
                  </Button>

                  {/* <Button className='custom_btn'
            color="primary"
            variant="contained"
            style={{ marginLeft: 5 }}
            onClick={() => {
              saveAnswers()
              showExitPopup()
            }}
          >
            {t("Save & Exit")}
          </Button> */}
                </Grid>
              </div>
              <div
                className={`after_save_outer ${currentPopShow === "scoreForm" &&
                  "final_score_popup"}`}
              >
                {currentPopShow === "submitPopUp" && (
                  <PopUpWarning
                    heading={"Submit"}
                    subHeading={"After that you can't change your answer"}
                    leftButton={"Cancel"}
                    rightButton={"Submit"}
                    handleLeftButton={() => {
                      setCurrentPopShow(null);
                    }}
                    handleRightButton={async () => {
                      await handleSubmitAndGetScore();
                    }}
                  />
                )}
                {currentPopShow === "exitPopUp" && (
                  <PopUpWarning
                    heading={"Exit"}
                    subHeading={"After that you exit form activity"}
                    leftButton={"Cancel"}
                    rightButton={"Exit"}
                    handleLeftButton={() => {
                      setCurrentPopShow(null);
                    }}
                    handleRightButton={() => {
                      handleSubmitAndGetScore();
                    }}
                  />
                )}
                {currentPopShow === "scoreForm" && (
                  <PopUpNextActivity
                    heading={"Score"}
                    subHeading={`Your Score : ${score.score} out of 3`}
                    questions={questions}
                    leftButton={`${t("Save & Exit")}`}
                    rightButton={`${t("Save & Continue")}`}
                    loading={scoreLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CaseStudyActivity;
