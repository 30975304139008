import React from 'react'
import { useTranslation } from "react-i18next";

const PopUpWarning = ({
  heading,
  subHeading,
  leftButton,
  rightButton,
  handleLeftButton,
  handleRightButton,
  loading = false,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className='pop-up-warning-container'>
      {
        loading
          ? <div className="spinner"></div>
          : <>
            <div>{t(heading)}</div>
            <div>{t(subHeading)}</div>

            <div>
              {
                leftButton &&
                <button
                  className='custom_btn'
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 5 , backgroundColor : "#3f51b5"}}
                  onClick={handleLeftButton}
                >
                  {t(leftButton)}
                </button>
              }
              {
                rightButton &&
                <button
                  className='custom_btn'
                  color="primary"
                  variant="contained"
                  style={{
                    marginLeft: 5,
                    backgroundColor: rightButton === "Delete" ? "#FF0004" : "#3f51b5",
                  }}
                  onClick={handleRightButton}
                >
                  {t(rightButton)}
                </button>
              }
            </div>
          </>
      }

    </div>
  )
}

export default PopUpWarning