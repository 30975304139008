import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import QuizActivity from './QuizActivity';
import Header from "../../../components/header";
import GapFillerActivity from './GapFillerActivity';
import DefinitionMatchingActivity from './DefinitionMatchingActivity';
import GistQuestionsActivity from './GistQuestionsActivity';
import WritingActivity from './WritingActivity';
import DebateActivity from './DebateActivity';
import RolePlayAcitivity from './RolePlayAcitivity';
import CaseStudyActivity from './CaseStudyActivity';
// import Footer from '../../../components/Footer/Footer';
import axios from 'axios';
import Const from '../../../helpers/const';
import ReadingActivities from './ReadingActivities';
import HomeWorkActivities from './HomeWorkActivities';
import ScrambleGameActivities from './ScrambleGameActivities';
import RealWorldProject from './RealWorldproject';

const IntractiveAcitvity = () => {
  const { lessonId, activityType } = useParams();
  const history = useHistory();
   const formattedActivityType = activityType.replace(/-/g, '/');
  

   const [questions, setQuestions]  = useState([]);
   const [readingArticle, setReadingArticle] = useState('');

   const isQuiz =(response) => {
    try {
      const json = JSON.parse(response);
      return json.hasOwnProperty("LessonContent") && json.hasOwnProperty("QuizActivityquestions");
    } catch (error) {
      return false;
    }
  };

   const FetchQuestions = async (activity,lessonId) => {
    try {

      const response = await axios.get(`${Const.BASE_URL}/get/${lessonId}/questions`);
       // Check if the response is successful and contains data
      
      if (response.status === 200 && response.data) {
        const matchingQuestions = response.data.questions
        .filter(question => question.hasOwnProperty(activity))
        .map(question => ({ 
          title: question.title,
          [activity]: question[activity] 
        }));
        setQuestions(matchingQuestions);
        setReadingArticle(response?.data?.reading_article[0]?.reading_article || '');
          
        return response.data; 
      } else {
        console.error("Unexpected response format:", response);
        throw new Error("Failed to fetch questions. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching questions:", error.message || error);
      // Optionally, you can throw the error to be handled by the caller
      throw error; // Re-throw the error for further handling
    }
  };

  useEffect(()=>{
    // console.log("activityinuseeffect>>>",activityType);
    
    if(activityType ==="Role-Plays"){
      FetchQuestions(activityType,lessonId)
    }else{
      FetchQuestions(formattedActivityType,lessonId)
    }

  },[activityType])


  useEffect(()=> {
    switch(activityType){
      case "Quiz":
        const quizQuestion = {
          "questions": [
          {
          "question": "What is the synonym of 'happy'?",
          "options": ["Sad", "Joyful", "Angry", "Tired"],
          "answer": "Joyful"
          },
          {
          "question": "Which word means 'to look quickly'?",
          "options": ["Glance", "Stare", "Gaze", "Peek"],
          "answer": "Glance"
          },
          {
          "question": "What is the antonym of 'hot'?",
          "options": ["Cold", "Warm", "Cool", "Boiling"],
          "answer": "Cold"
          },
          {
          "question": "Which one is a fruit?",
          "options": ["Carrot", "Apple", "Broccoli", "Pepper"],
          "answer": "Apple"
          },
          {
          "question": "What does 'run' mean?",
          "options": ["To walk slowly", "To move fast on foot", "To jump high", "To sit down"],
          "answer": "To move fast on foot"
          },
          {
          "question": "Which animal barks?",
          "options": ["Cat", "Bird", "Dog", "Fish"],
          "answer": "Dog"
          }
          ]
        }
        // setQuestions(quizQuestion);
        break;
      
      case "Gap Fill":
        const gapFill = {
          question: [
            "The cat is hiding ______ the couch.",
            "She always drinks tea ______ breakfast.",
            "I need to buy some milk ______ the store.",
            "He was late ______ work yesterday.",
            "They are going ______ vacation next month.",
            "Can you help me ______ my homework?",
            "We will meet ______ the park at noon.",
            "There is a book ______ the table.",
            "She travels to school ______ bus every day.",
            "He borrowed a pen ______ his friend."
          ],
          answer: ["behind","for","from","to","on","with","at","on","by","from"]
        }
        // setQuestions(gapFill);
        break;

        case "Case Studies":
          const CaseStudy = {
            question: [
              " You are leading a project, and the team misses a critical deadline. What is your first step?",
              " A team member is consistently underperforming, and the project is at risk. How should you handle this situation?",
              " The client requests a feature that contradicts best practices in your industry. What is the most appropriate response?"
            ],
            options: [
              [
                "A. Blame the team for missing the deadline.",
                "B. Analyze the reasons for missing the deadline and create a new action plan.",
                "C. Extend the project timeline without communicating with the client.",
                "D. Inform the client immediately about the missed deadline."
              ],
              [
                "A. Fire the team member immediately.",
                "B. Ignore the issue and hope the performance improves.",
                "C. Have a one-on-one conversation to identify challenges and provide support.",
                "D. Reassign the team member's tasks to others."
              ],
              [
                "A. Implement the feature without question.",
                "B. Explain to the client why the feature contradicts best practices and offer an alternative solution.",
                "C. Refuse to implement the feature without explanation.",
                "D. Ignore the client's request and proceed with the original plan."
              ]
            ],
            answer: ["B", "C", "B"] // Correct answers
          }
          // setQuestions(CaseStudy);
          break;
        

      case "Definition Match":
        const definitionMatching = {
          questions: [
              {
              "question": "A device used to make calls",
              "answer": "Phone"
              },
              {
              "question": "The liquid we drink every day",
              "answer": "Water"
              },
              {
              "question": "A place where you sleep at night",
              "answer": "Bedroom"
              },
              {
              "question": "An object that tells time",
              "answer": "Clock"
              },
              {
              "question": "A vehicle with two wheels",
              "answer": "Bicycle"
              },
              {
              "question": "A piece of furniture for sitting",
              "answer": "Chair"
              },
              {
              "question": "A large body of salt water",
              "answer": "Ocean"
              },
              {
              "question": "A tool used for writing or drawing",
              "answer": "Pen"
              },
              {
              "question": "A building where people learn",
              "answer": "School"
              },
              {
              "question": "A garment worn on the feet",
              "answer": "Socks"
              }
            ]
          }

        // setQuestions(definitionMatching);
        break;
      
      case "Gist Questions":
        const gistQuestion = {
            questions: [
              {
                "question": "What is the main idea of the article?",
                "answer": "The central theme or primary focus presented in the article."
              },
              {
                "question": "When did the events or developments in the article occur?",
                "answer": "The timeline or period during which the events took place."
              },
              {
                "question": "Who are the key individuals or groups mentioned in the article?",
                "answer": "The important people or organizations highlighted within the text."
              },
              {
                "question": "Where did the significant events described in the article take place?",
                "answer": "The specific locations or settings where the events happened."
              },
              {
                "question": "Why are the events or issues discussed in the article important?",
                "answer": "The reasons why these events or topics hold significance."
              },
              {
                "question": "How did the situation or events unfold according to the article?",
                "answer": "The sequence or process by which the events occurred."
              }
            ]
        }

        // setQuestions(gistQuestion);
        break;

      case "Writing": 
        const writingActivity = {
          "activities": [
            {
              activityName: "Describe Your Daily Routine",
              instruction: ["Write a paragraph describing your daily routine.", "Include details about what you do from the moment you wake up until you go to bed."]
            },
            {
              activityName: "Letter to a Friend",
              instruction: ["Write a letter to a friend telling them about a recent trip you took.", "Describe where you went, what you did there, and how you felt during the trip."]
            }
          ]
        }

        // setQuestions(writingActivity)
        break;

      case "Discussion-Debate":
        const debateTopics = {
          topics: [
            "What are the benefits of learning a new language at an older age?",
            "How does technology influence our daily lives positively and negatively?",
            "Should governments invest more in renewable energy sources? Why or why not?",
            "Is it important for adults to continue their education? Why?",
            "How can social media impact mental health, especially for older adults?",
            "What role do hobbies play in maintaining mental well-being as we age?",
            "Can volunteering be beneficial for older adults? How?",
            "What are some challenges that older adults face when adapting to new technologies?"
          ]
        }

        // setQuestions(debateTopics)
        break;
      
      case "Role-Plays":
        const rolePlayTopicRole = {
            topics: [
              {
                topic: "Ordering Food at a Restaurant",
                participants: ["Customer", "Waiter"]
              },
              {
                topic: "Booking a Hotel Room",
                participants: ["Guest", "Receptionist"]
              },
              {
                topic: "Asking for Directions",
                participants: ["Tourist", "Local Resident"]
              },
              {
                topic: "Shopping for Clothes",
                participants: ["Shopper", "Salesperson"]
              }
            ]
          }

          // setQuestions(rolePlayTopicRole)
          break;
    }
    
  }, [])
  return (
    <div>
      <Header history={history} showButtons={false}/>
      {
        formattedActivityType === 'Quiz' ? 
          <QuizActivity quizData={questions[0] ?? []} lessonId={lessonId} readingArticle={readingArticle}/>
          : formattedActivityType === 'Gap Fill' ?
            <GapFillerActivity lessonId= {lessonId} gapFill={questions ?? questions[0] ?? []} readingArticle={readingArticle} />
            : formattedActivityType === 'Definition Match' ?
              <DefinitionMatchingActivity definitionMatching={questions[0] ?? []} lessonId={lessonId} readingArticle={readingArticle}/>
              : formattedActivityType === 'Gist Questions' ?
                <GistQuestionsActivity gistQuestion = {questions[0] ?? []} lessonId={lessonId} formattedActivityType={formattedActivityType} readingArticle={readingArticle}/>
                : formattedActivityType === 'Comprehension questions' ?
                <GistQuestionsActivity gistQuestion = {questions[0] ?? []} lessonId={lessonId} formattedActivityType={formattedActivityType} readingArticle={readingArticle}/>
                : formattedActivityType === 'Writing' ?
                  <WritingActivity writingActivity = {questions[0] ?? [] } lessonId={lessonId}/>
                  : formattedActivityType === 'Discussion/Debate' ?
                    <DebateActivity debateTopics = {questions[0] ?? []} lessonId={lessonId} />
                    : activityType === 'Role-Plays' ?
                      <RolePlayAcitivity rolePlayTopic={questions[0] ?? []} lessonId={lessonId}/>
                      :
                      formattedActivityType === 'Case Studies' ?
                      <CaseStudyActivity CaseStudy={questions ?? []} lessonId={lessonId}/>
                      :
                      formattedActivityType === 'Reading' ?
                        (isQuiz(questions[0]?.Reading) ? (
                          <QuizActivity quizData={questions[0] ?? []} lessonId={lessonId} type="Reading" />
                        ) : (
                          <ReadingActivities ReadingActivity={questions ?? []} lessonId={lessonId} />
                        ))
                      :
                      formattedActivityType === 'HomeWork' ?
                      <HomeWorkActivities HomeActivity={questions[0] ?? []} lessonId={lessonId}/>
                      :
                      formattedActivityType === 'Games' ?
                      <ScrambleGameActivities ScrambleGameData={questions[0] ?? []} lessonId={lessonId}/>
                      :
                      formattedActivityType === 'Real World Projects' ?
                      <RealWorldProject RealActivity={questions[0] ?? []} lessonId={lessonId}/>
                      :
                      <div></div>
      } 
      <div></div>
    </div>
  ) 
}

export default IntractiveAcitvity