import React from 'react'

const MarkdownRender = ({text, classAdd=null}) => {
  const isMarkdownFormat = (text) => {
    return /#|[*_-]|(\*\*|\*).*?(\*\*|\*)/.test(text);
  };

  const renderMarkdown = (text) => {
    const boldText = /(\*\*)(.*?)\1/g;
    const headerRegex = /^(###)(.*?)$/gm;
    const listItemRegex = /^[-*]\s(.*)/gm;

    text = text.replace(boldText, (match, p1, p2) => `<strong>${p2}</strong>`);
    text = text.replace(headerRegex, (match, p1, p2) => `<h3>${p2}</h3>`);
    text = text.replace(listItemRegex, (match, p1) => `<li>${p1}</li>`);
    text = text.replace(/(<li>.*?<\/li>)/g, (match) => `<ul>${match}</ul>`);
    text = text.replace(/\\n/g, '<br>');

    return text;
  };

  let formattedText = isMarkdownFormat(text) ? renderMarkdown(text) : text;
  if(isMarkdownFormat(text)){
    formattedText = renderMarkdown(formattedText);
  }
  
  return (
    <div
      className={`${classAdd ? classAdd : "content"}`}
      dangerouslySetInnerHTML={{ __html: formattedText }}
    />
  )
}

export default MarkdownRender