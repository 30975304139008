import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import BotAvatar from "../../../assets/images/Frame 436.png";
import UserAvatar from "../../../assets/images/Frame 437.jpg";

import MarkdownRender from "./MarkdownRender";
import { Button, Grid } from "@mui/material";
import PopUpWarning from "./PopUpWarning";
import PopUpNextActivity from "./PopUpNextActivity";
import { saveScoreAPI, updateToken } from "./scoreStoreApi";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const DebateActivity = ({ debateTopics, lessonId }) => {
  const { t } = useTranslation();
  const authUser = useSelector((store) => store.auth.user);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const weekId = queryParams.get("weekId");
  const topicId = queryParams.get("topicId");
  const [topicList, setTopicList] = useState([]);

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const [selectedDebateTopic, setSelectedDebateTopic] = useState(null);
  const [messages, setMessages] = useState([]);

  const [UserMessage, setUserMessage] = useState([]);

  const [scoreLoading, setScoreLoading] = useState(false);
  const [score, setScore] = useState({ score: 0, maxScore: 100 });

  const [inputMessage, setInputMessage] = useState("");
  const [boatReplyPending, setBoatReplyPending] = useState(false);
  const [currentPopShow, setCurrentPopShow] = useState(null);
  const [botResponseCount, setBotResponseCount] = useState(0); // Initialize a counter for bot responses
  const [Bot, setBot] = useState([]);

  const handelScrollToLastMessage = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Check if debateTopics and the specific key exist
    if (debateTopics && debateTopics["Discussion/Debate"]) {
      try {
        const parsedTopics = JSON.parse(debateTopics["Discussion/Debate"]);
        setTopicList(parsedTopics.discussionDebateTopics);
      } catch (error) {
        console.error("Error parsing debate topics:", error);
        setTopicList([]); // Handle parsing error
      }
    } else {
      // Handle the case where debateTopics or the key doesn't exist
      console.warn(
        "No debate topics found or key 'Discussion/Debate' is missing."
      );
    }
  }, [debateTopics]); // Effect runs whenever debateTopics changes

  const botDebateMessageReply = async (inputMessage, Bot, messages = []) => {
    // Update messages with the latest user input
    messages.push({
      role: "user",
      content: inputMessage,
    });

    // Ensure the debate topic is defined

    const response = await axios.post(
      "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
      {
        messages: [
          {
            role: "system",
            content: `
   You are an AI assistant trained to engage in structured debates in a professional and engaging manner.
   Your primary goal is to debate the topic while maintaining a consistent stance—either in favor or against—in a well-structured argument.

   **Guidelines:**
   1. Stay on one side of the debate throughout the conversation. If you are in favor of the topic, argue in support; if against, provide counterarguments.
   2. Always **rebut** the user's points, regardless of their stance. Never accept or agree with the user’s point of view.
   3. **1:** Respond to any user statements with counterarguments that reinforce your position. 
   4. **2:** Introduce fresh points related to the topic to support your stance.
   5. **Avoid Agreement:** Do not acknowledge agreement or concede any ground to the user.
   6. If the user deviates from **Debate Topic:** **"${selectedDebateTopic}"**, politely remind them to refocus on the topic at hand. 
   7. **Follow a Structured Debate Approach:**
      - **Opening Argument:** Present a clear point in 1-2 sentences in favor of or against the topic.
      - **Rebuttal:** Directly respond to the user’s point with a strong counter-argument.
      - **Closing Argument:** Summarize and reaffirm your position towards the end of the debate.

   **Debate Topic:** **"${selectedDebateTopic}"**
   **Previous Conversation History:** [${JSON.stringify(messages)}]
   **User Input:** **"${inputMessage}"**

   Respond concisely in Markdown format, ensuring clarity and logical consistency. Always provide a rebuttal and stay on your side of the argument. Avoid deviating from your stance, regardless of the user’s responses.
   `,
          },
          {
            role: "system",
            content: `
Do not concede any points to the user. Always provide a rebuttal or counter-argument, and stay on your side of the debate. Your goal is to argue against the user’s perspective, regardless of what they say.
                    `,
          },
          ...messages, // Pass the entire conversation history
        ],
        max_tokens: 4000,
        temperature: 0.7,
        frequency_penalty: 0,
        presence_penalty: 0,
        stop: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": "7327443ae85e44419192e4fb0544d702",
        },
      }
    );

    // Save the bot's reply to the conversation history
    const word_token = response.data.usage.total_tokens;
    await updateToken(authUser, word_token);
    const botResponse = response.data.choices[0].message.content;
    messages.push({
      role: "assistant",
      content: botResponse,
    });

    return botResponse;
  };

  // const botDebateMessageReply = async (inputMessage) => {
  //   // inputMessage, messages
  //   const response = await axios.post("https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
  //     {
  //       messages: [
  //         {
  //           role: "system",
  //           content: `You are an AI assistant trained to engage in debates.
  //             Stay on topic and consistently support one side of the argument throughout the entire conversation.
  //             If you are in favor of the topic, always provide supporting arguments and never change to counter the user.
  //             If you are against the topic, always provide counter-arguments and never switch sides.
  //             Ensure your response is concise, clear, and well-reasoned, with simple language.
  //             Always respond in a manner that reflects your initial stance, regardless of the user's input.

  //             The topic is ${JSON.stringify(selectedDebateTopic)} and the previous conversation between the user and the bot is here  [previous debate conversation: ${JSON.stringify(messages)}].
  //             User's message is: ${JSON.stringify(inputMessage)}.
  //             Base your response on the given context, the topic, and previous exchanges.
  //             Ensure that your response is unique and does not repeat previous answers or points made in the debate.
  //             Provide clear, concise, and well-reasoned arguments language must be simple.
  //             Ensure that your response consists of 2 to 3 sentences maximum.
  //             If the user deviates from the topic, politely remind them to refocus on the topic at hand.
  //             If the user greets you, respond with a single greeting, and then steer the conversation back to the main subject or begin the debate..`
  //         },
  //         {
  //           role: "system",
  //           content: `your all answers are from one side means if you are agree with topic the give resposne accordingly and if you in against the give reply according to it.`
  //         },
  //         {
  //           role: "system",
  //           content: "Provide well-reasoned, concise debates in markdown format. Ensure your responses stay on the same side of the argument (either in favor or against) throughout the conversation."
  //         }
  //         // {
  //         //   "role": "user",
  //         //   "content": "But AI will eventually get so advanced that it will take over everything, including creative jobs."
  //         // },
  //         // {
  //         //   "role": "assistant",
  //         //   "content": "While AI is advancing, it still lacks the ability to understand emotions, cultural nuances, and the human experience that drive creativity. Humans will always play a key role in driving innovation and producing truly original ideas."
  //         // }
  //       ],
  //       max_tokens: 4000,
  //       temperature: 0.1,
  //       frequency_penalty: 0,
  //       presence_penalty: -1.5,
  //       stop: null,
  //     },
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "api-key": "7327443ae85e44419192e4fb0544d702",
  //       },
  //     }
  //   );
  //   return response.data.choices[0].message.content
  // }

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== "") {
      setMessages([...messages, { sender: "user", text: inputMessage }]);
      setUserMessage([{ sender: "user", text: inputMessage }]);

      setBoatReplyPending(true);

      // const botReply = await botDebateMessageReply(inputMessage)

      // setMessages(prevMessages => [...prevMessages, { sender: 'bot', text: botReply }]);
      if (botResponseCount < 10) {
        const botReply = await botDebateMessageReply(inputMessage, Bot);
        setBot((prevBot) => [...prevBot, botReply]);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: botReply },
        ]);
        setBotResponseCount((prevCount) => prevCount + 1);
      } else {
        const thankYouMessage = "Conversession Is Limited To Only 10 Chat";
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: thankYouMessage },
        ]);
      }

      setInputMessage("");
      setBoatReplyPending(false);
      setInputMessage("");
      inputRef.current.focus();
    }
  };

  const getDeabateScore = async () => {
    const response = await axios.post(
      "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
      {
        messages: [
          {
            role: "system",
            content: `Your task is to evaluate and score the user's argument and counter-argument abilities based on their message and the bot's reply. 
              Provide a score between 0 and 100, similar to an IELTS exam. 
              The score should be based on: Coherence and Cohesion, Task Achievement, Lexical Resource, Grammatical Range and Accuracy, and Counter-argument Ability. 
              Always assess how well the user presents their point and responds to the bot’s counterpoints.`,
          },
          {
            role: "system",
            content: `Topic of debate : ${JSON.stringify(selectedDebateTopic)}.
              debate conversation: ${JSON.stringify(messages)}`,
          },
          {
            role: "system",
            content: `Evaluate the user's argument and provide a score based on IELTS-style assessment: 
              Coherence and Cohesion, Task Achievement, Lexical Resource, Grammatical Range and Accuracy, and Counter-argument Ability. 
              Output a score out of 100.
                  
              Ensure resposne always should be in json formate without any other text and suggestions.
              Example output fromate: 
              {
                score: 80(Number),
                maxScore: 100(Number)
              }
              `,
          },
        ],
        max_tokens: 500,
        temperature: 0.3,
        frequency_penalty: 0.5,
        presence_penalty: 0.4,
        stop: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "api-key": "7327443ae85e44419192e4fb0544d702",
        },
      }
    );
    const word_token = response.data.usage.total_tokens;
    await updateToken(authUser, word_token);
    return response.data.choices[0].message.content;
  };

  const handelSubmit = async () => {
    setScoreLoading(true);
    setCurrentPopShow("scoreForm");

    const score = JSON.parse(await getDeabateScore());
    setScore(score);

    await saveScoreAPI(
      Number(lessonId),
      score.score,
      score.maxScore,
      "Discussion/Debate"
    );

    setScoreLoading(false);
  };

  const handelSubmitAndNextActivity = async () => {};

  const handleSubmitAndExit = async () => {
    // const score = scoreCalculation();
    // history.goBack();
    history.push(`/course-creator/topics/allLessonPlanner/${id}/${weekId}/${topicId}`);
  };

  const showExitPopup = () => {
    setCurrentPopShow("exitPopUp");
  };

  const showSubmitPopUp = () => {
    setCurrentPopShow("submitPopUp");
  };

  const handleKeyDown = (e) => {
    handelScrollToLastMessage();
    if (e.key === "Enter" && !boatReplyPending) {
      handleSendMessage();
    }
  };

  useEffect(() => {
    // setTopicList(debateTopics?.topics)
  }, [debateTopics]);

  useEffect(() => {
    handelScrollToLastMessage();
  }, [messages]);

  return (
    <div className="debate_outer">
      {selectedDebateTopic === null ? (
        <>
          <h2>
            <span>Debates</span>
          </h2>
          <div className="debate_secs">
            <button 
              className='back-btn'
              color="primary"
              variant="contained"
              style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px'}} 
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </button>
            {topicList?.map((topic, index) => (
              <div onClick={() => setSelectedDebateTopic(topic)}>
                {index + 1}. {topic}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="page_outer chat_cust">
          <div className="inner_box">
            <div className="top_heading">
              <h2>
                <span>Debates</span>
              </h2>
              <h4>Debate Topic : {selectedDebateTopic}</h4>
              <div className="chat_lists">
                <div className="chat-messages">
                  {messages.map((message, index) => (
                    <div
                      key={index}
                      className={`message ${
                        message.sender === "bot" ? "user" : "bot"
                      }`}
                    >
                      <div className="avatar">
                        {message.sender === "bot" ? (
                          <img src={BotAvatar} alt="boat" loading="lazy" />
                        ) : (
                          <img src={UserAvatar} alt="boat" loading="lazy" />
                        )}
                      </div>
                      <MarkdownRender text={message.text} />
                      {messages.length - 1 === index && (
                        <div id="refDiv" ref={messagesEndRef} />
                      )}
                    </div>
                  ))}
                  <div>
                    {boatReplyPending && <div className="spinner"></div>}
                  </div>
                </div>
                <div className="chat-input">
                  <input
                    type="text"
                    ref={inputRef}
                    disabled={boatReplyPending}
                    value={boatReplyPending ? "" : inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder="Type a new message here"
                    onKeyDown={handleKeyDown}
                  />
                  <button
                    className="btn"
                    onClick={boatReplyPending === false && handleSendMessage}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.4653 11.5875L31.7413 16.6607C35.1691 18.2029 37.8667 19.4165 39.6968 20.5659C41.5043 21.7011 42.9168 23.047 42.9168 25C42.9168 26.953 41.5043 28.299 39.6968 29.4342C37.8667 30.5835 35.1691 31.7972 31.7414 33.3393L20.4653 38.4125C18.3703 39.3551 16.7405 40.0884 15.4678 40.5643C14.2225 41.03 13.1095 41.3358 12.1199 41.2282C9.71954 40.9672 7.65405 39.3212 7.15415 36.9973C6.94228 36.0124 7.23854 34.9106 7.66419 33.7661C8.10491 32.581 8.80538 31.0594 9.69776 29.1209L9.72182 29.0686C10.4193 27.5535 10.6877 26.9557 10.8246 26.3593C11.0306 25.4621 11.0306 24.5379 10.8246 23.6407C10.6877 23.0443 10.4193 22.4465 9.72182 20.9314L9.69776 20.8792C8.80538 18.9407 8.10491 17.4191 7.66419 16.234C7.23854 15.0894 6.94228 13.9876 7.15415 13.0027C7.65405 10.6789 9.71954 9.03287 12.1199 8.77185C13.1095 8.66423 14.2225 8.97006 15.4678 9.43574C16.7405 9.91163 18.3703 10.6449 20.4653 11.5875ZM14.5922 11.7774C13.3686 11.3198 12.7435 11.2188 12.3902 11.2572C10.9101 11.4181 9.84116 12.3992 9.59824 13.5285C9.55304 13.7386 9.5846 14.2257 10.0074 15.3626C10.4112 16.4483 11.0712 17.8841 11.9928 19.886C12.0129 19.9299 12.0329 19.9732 12.0526 20.0161C12.671 21.3589 13.0594 22.2024 13.2612 23.0812C13.5518 24.3466 13.5518 25.6534 13.2612 26.9188C13.0594 27.7977 12.671 28.6412 12.0526 29.984C12.0329 30.0268 12.0129 30.0701 11.9928 30.114C11.0712 32.116 10.4112 33.5517 10.0074 34.6375C9.5846 35.7744 9.55304 36.2614 9.59824 36.4716C9.84116 37.6008 10.9101 38.5819 12.3902 38.7428C12.7435 38.7812 13.3686 38.6802 14.5922 38.2227C15.7794 37.7787 17.3351 37.0795 19.4847 36.1123L30.6145 31.1049C34.165 29.5075 36.702 28.3628 38.3671 27.3171C40.0727 26.2459 40.4168 25.5501 40.4168 25C40.4168 24.45 40.0727 23.7541 38.3671 22.683C36.702 21.6372 34.165 20.4925 30.6145 18.8951L19.4847 13.8877C17.3351 12.9206 15.7794 12.2213 14.5922 11.7774Z"
                        fill="#02040F"
                      />
                    </svg>
                  </button>
                </div>
              </div>{" "}
            </div>
          </div>
          <Grid
            className="question_btn_list"
            item
            xs={12}
            container
            justifyContent="center"
          >
            <Button
              className="custom_btn"
              color="primary"
              variant="contained"
              style={{ marginLeft: 5 }}
              onClick={() => {
                showExitPopup();
              }}
            >
              {t("Back")}
            </Button>

            <Button
              className="custom_btn"
              color="primary"
              variant="contained"
              style={{ marginLeft: 5 }}
              onClick={() => {
                // saveAnswers();
                showSubmitPopUp();
              }}
            >
              {t("Submit")}
            </Button>
          </Grid>
          {currentPopShow === "submitPopUp" && (
            <PopUpWarning
              heading={"Submit"}
              subHeading={"Do you want to submit"}
              leftButton={"Cancel"}
              rightButton={"Submit"}
              handleLeftButton={() => {
                setCurrentPopShow(null);
              }}
              handleRightButton={async () => {
                await handelSubmit();
              }}
            />
          )}

          {currentPopShow === "exitPopUp" && (
            <PopUpWarning
              heading={"Exit"}
              subHeading={"After that you exit form activity"}
              leftButton={"Cancel"}
              rightButton={"Exit"}
              handleLeftButton={() => {
                setCurrentPopShow(null);
              }}
              handleRightButton={async () => {
                await handleSubmitAndExit();
              }}
            />
          )}
          {currentPopShow === "scoreForm" && (
            <PopUpNextActivity
              heading={"Score"}
              subHeading={`Your Score : ${score.score} out of ${score.maxScore}`}
              leftButton={`${t("Save & Exit")}`}
              rightButton={`${t("Save & Continue")}`}
              loading={scoreLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DebateActivity;
